import styles from '../../index.module.scss';
import React, { useEffect, useState } from "react";

type PropsType = {
  step?: StepType[];
};

type StepType = {
  name: string;
  n: number;
  active: boolean;
};

const initDataMap: StepType[] = [
  {
    name: '填写账号名',
    n: 1,
    active: true,
  },
  {
    name: '验证身份',
    n: 2,
    active: false,
  },
  {
    name: '设置新密码',
    n: 3,
    active: false,
  },
  {
    name: '完成',
    n: 4,
    active: false,
  },
];

export default (props: PropsType) => {
  const { step = initDataMap } = props;

  return (
    <div className={styles.steps}>
      {step.map(e => {
        return (
          <div key={e.n} className={`${styles.steps_box}  ${e.active ? styles.steps_box_active : ''}`}>
            <div className={styles.steps_box_title}>
              <div className={styles.steps_box_title_num}>{e.n}</div>
              <div className={styles.steps_box_title_title}>{e.name}</div>
            </div>
            {e.n !== 4 && <div className={styles.steps_box_line}></div>}
          </div>
        );
      })}
    </div>
  );
};
