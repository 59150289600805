/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-17 11:18:26
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-21 10:15:29
 * @FilePath: /isp_International/src/pages/MaterialCode/components/TableTitle/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Checkbox, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';


interface TableTitleProps{
    checked:boolean; //是否选中
}
const  TableTitle: React.FC = (props) => {
    const {checked} = props;
    const { t, i18n } = useTranslation();
    const titList = [
        {
            span:4,
            title:t('materialCode.materialCode'),
            className: [styles.textCenter].join(' ')
        },
        {
            span:5,
            title:t('materialCode.materialName'),
            className: [styles.textCenter].join(' ')
        },
        {
            span:2,
            title:t('materialCode.jdProductCode'),
            className: [styles.textCenter].join(' ')
        },
        {
            span:6,
            title:t('materialCode.jdProductName'),
            className: [styles.textCenter].join(' ')
        },
        {
            span:3,
            title:t('materialCode.lastModifiedBy'),
            className: [styles.textCenter].join(' ')
        },
        {
            span:4,
            title:t('materialCode.action'),
            className: [styles.textCenter].join(' ')
        },
    ]


    return <Row className={styles.header}>
        {titList.map((ele, idx)=> <Col span={ele.span} className={ele.className} key={idx}>{ele.title} </Col>)}
    </Row>
}
export default TableTitle