import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Pagination, message, Modal, Checkbox, Table } from 'antd';

import UserCenterMenu from '@/components/UserCenterMenu';
import CommonBasicHeader from '@/components/CommonBasicHeader';
import CpAddressList from '@/components/Address/List/index';
import { PAGE_SIZE_OPTIONS } from '@/enum/config';
import { Input, Switch } from 'antd';
import SearchEmpty from '@/assets/images/accountSettings/icon_search_empty.png';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';


const ItemIndex = (props)=>{
    const { t } = useTranslation();
    const {data, onChange} = props;

    return (
        <div className={styles.index}>
            <Col className={styles.accountName}>{data.pin}</Col>
            <Col className={styles.accountType}>{data.accountTypeName}</Col>
            <Col className={styles.addressOpen}><Switch value={data.authorityStatus} checked={data.authorityStatus} onChange={onChange}/></Col>
        </div>
    )
}

export default ItemIndex;