import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

interface IAppProps {
    tit:string;
    contentList:any;
  }
  const ContractInfo: React.FC<IAppProps> = (props: IAppProps) => {
    const {tit, contentList} = props;
    const { t } = useTranslation();


    return <div className={styles.index}>
            <div className={styles.title}>{t('orderDetail.contractInfo')}</div>
            {contentList && contentList.map((item, idx)=>{
                return  <div className={styles.row} key={idx}>
                    <span className={styles.left}>{item.label}</span>
                    <span className={styles.right}>{item.value}</span>
            </div>
            })}
           
        </div>
  }

  export default ContractInfo;