/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-26 09:16:29
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-09 21:35:47
 * @FilePath: /isp_pro/src/pages/SettleConfig/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState, useRef } from 'react';
import { Table, Switch, Card, Modal,Input, Button, message } from 'antd';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import UserCenterMenu from '@/components/UserCenterMenu';
// import FootSlogan from '@/components/FootSlogan';
import { getSettleConfigAll, insertConfigBatch, deleteFunction } from '@/api/newApi/settleConfig';
import moment from 'moment';
import styles from './index.module.scss';
import EnumModal from './components/EnumModal';
import Tooptip from '@/components/common/Tooltip';
import { useTranslation } from 'react-i18next';

export interface IAppProps {}
// const crumbData = [
//   {
//     label: '我的工作台',
//     url: '/userCenter',
//   },
//   {
//     label: '管理中心',
//   },
// ];
const SettleConfig: React.FC<IAppProps> = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState(''); //报错提醒
  const [isCreate, setIsCreate] = useState(true);
  const [showEnumModal, setShowEnumModal] = useState(false);
  const [modalType, setmodalType] = useState('editor');
  const [initDataSource, setInitDataSource] = useState([]); //初始化数据
  const [currentRow, setCurrentRow] = useState([]); //设置当前展示的枚举row
  const { t } = useTranslation();
  const inputRef = useRef();
  const defaultConfig: any = {
    id: '',
    module: '订单',
    category: '',
    contractNum: '',
    targetPin: null,
    createDate: '',
    createPin: '',
    enums: null,
    ext1: '0',
    ext2: '0',
    ext3: '0',
    ext4: '1',
    ext5: null,
    ext6: null,
    ext7: null,
    ext8: null,
    isDeleted: 0,
    updateDate: '',
    updatePin: '',
    isEdit: true,
  };
  const addConfig = () => {
    //判断数组里面是否有category 为null情况
    const isEvery = dataSource.every((item: any) => item.category != '');
    if (isEvery) {
      //判断dataSource是否有id为null
      if (dataSource.some((item: any) => item.id == '')) {
        inputRef?.current?.focus(); //自定义默认定位
        return;
      }
      const _dataSource = [...dataSource];
      _dataSource.push({ ...defaultConfig });
      setDataSource(_dataSource);
    } else {
      setErrorMessage(t('configManageList.inpSelfName'));
      inputRef?.current?.focus(); //自定义默认定位
    }
  };
  const getRecordIndex = (record: any) => {
    return dataSource.findIndex((item: any) => item.id == record.id);
  };
  const formateDataSource = (res: any) => {
    const values = res?.value || [];
    const __dataSource: any = [];
    values &&
      values.map((value: any) => {
        __dataSource.push({
          ...value,
          isEdit: false,
        });
      });
    setDataSource([]);
    setDataSource(JSON.parse(JSON.stringify(__dataSource)));
    setInitDataSource(JSON.parse(JSON.stringify(values)));
  };
  const insertConfigPost = (record: any) => {
    insertConfigBatch(record).then((res: any) => {
      if (res.success) {
        formateDataSource(res);
      }else{
        message.error(res?.msg)
      }
    });
  };
  const onChange = (record: any, key: any, checked: any) => {
    //改变参数
    record[key] = checked ? '1' : '0';
    if (record.category == '物料名称' && Number(record[key]) === 1) {
      //物料名称显示，物料编码必显示，物料名称必填，物料编码必填
      const index = dataSource.findIndex((item: any) => item.category == '物料编码');
      const dataArr = JSON.parse(JSON.stringify(dataSource[index]));
      dataArr[key] = record[key];
      insertConfigPost([record, dataArr]);
    } else if (key == 'ext1' && Number(record[key]) === 0) {
      record['ext2'] = record[key]; //不显示，一定非必填) {
      record['ext3'] = record[key]; //不显示，枚举关闭
      insertConfigPost([record]);
    }else if(key == 'ext2' && Number(record[key]) === 1) {
      record['ext1'] = record[key];
      insertConfigPost([record]);
    } else {
      insertConfigPost([record]);
    }
  };
  const editCategory = (item: any) => {
    item.isEdit = true;
    setDataSource([...dataSource]);
  };
  useEffect(() => {
    //判断自定义有几条，不能超过10条
    const customData = dataSource.filter((item: any) => {
      return Number(item.ext4) === 1;
    });
    setIsCreate(customData.length < 10 ? true : false);
  }, [dataSource]);
  const updateConfig = (record: any) => {
    //添加自定义
    if (!record.category) {
      setErrorMessage(t('configManageList.customNamePrompt'));
      return;
    } else {
      if (errorMessage) {
        setErrorMessage('');
      }
    }
    insertConfigPost([record]);
  };
  const removeItem = async (item: any) => {
    if (item.id) {
      //
      const res: any = await deleteFunction({ id: item.id });
      if (res.success) {
        formateDataSource(res);
      }
    } else {
      const _dataSource = [...dataSource];
      _dataSource.pop();
      setDataSource(_dataSource);
    }
  };
  useEffect(() => {
    inputRef?.current?.focus(); //自定义默认定位
  }, [dataSource]);
  const getExpandedRow = (record: any) => {
    return (
      <>
        <ul
          style={{
            paddingLeft: '16px',
            paddingRight: '72px',
            display: 'flex',
            overflow: 'hidden',
            background: '#F8F9FA',
            color:'#595B66',
            position:'relative',
            top:'-20px',
            marginTop: '8px',
            marginBottom: '-4px',
            width:'100%'
          }}
        >
          <li className={styles.enumeration}>
            {t('configManageList.table.totalOption').replace('%n%',record?.enums?.length) +  record.enums.map(v => v.value).join('、')}
          </li>
          <li
            className={styles.all}
            onClick={() => {
              setmodalType('view');
              setShowEnumModal(true);
              setCurrentRow(record);
            }}
          >
            { t('configManageList.seeAll')}
          </li>
        </ul>
        {/*<CaretUpOutlined*/}
        {/*  style={{ color: '#f0f0f0', position: 'absolute', top: '-15px', right: '49%', fontSize: '20px' }}*/}
        {/*/>*/}
      </>
    );
  };
  const columns: any = [
    // {
    //   key: 'module',
    //   title: '配置模块',
    //   dataIndex: 'module',
    //   width: 128,
    //   align: 'left',
    //   render: (text: any, record: any) => {
    //     return <>
    //       {text}
    //       {Number(record.ext4) === 1 && record?.enums?.length > 0 && <div style={{height:'10px',width:'20px'}}></div>}
    //     </>
    //   }
    // },
    {
      key: 'category',
      title: () => {
        return (
          <div>
            {t('configManageList.table.paramName')}
            <Tooptip
              placement={'top'}
              title={
                <div>
                 {t('configManageList.mandatoryDisplayAndEnumPrompt')}
                </div>
              }
            >
              <QuestionCircleOutlined style={{ marginLeft: '3px', cursor: 'pointer' }} />
            </Tooptip>
          </div>
        );
      },
      dataIndex: 'category',
      width: 200,
      align: 'left',
      render: (text: any, record: any) => {
        return Number(record.ext4) === 1 ? (
          record.isEdit ? (
            <div className={styles.editCategory}>
              <Input
                ref={inputRef}
                className={styles.input}
                value={record.category}
                maxLength={t('configManageList.inpSelfName')}
                size={'small'}
                suffix={
                  <div className={styles.operations}>
                    <div
                      style={{
                        cursor: 'pointer',
                        color:'#8C8D95',
                      }}
                      onClick={() => {
                        updateConfig(record);
                      }}
                    >
                      {/* <i className='iconFont icon-navbar_chosen' style={{color:'#D0D4D8', fontSize:"14px"}}/> */}
                     {t('configManageList.save')}
                    </div>
                    <div style={{margin:'0px 8px',height:'12px',borderLeft:'1px solid #DFE1E4'}}></div>
                    <div
                      style={{ color: '#8C8D95', fontSize: '14px', display: 'inline-block', cursor: 'pointer' }}
                      onClick={() => {
                        if (record.id) {
                          const _dataSource: any = [...dataSource];
                          record.isEdit = false;
                          const index = dataSource.findIndex((item: any) => item.id == record.id);
                          if (index > -1) {
                            record.category = initDataSource[index]?.category;
                            _dataSource[index] = record;
                            setDataSource(_dataSource);
                          }
                        } else {
                          const _dataSource = [...dataSource];
                          _dataSource.pop();
                          setDataSource(_dataSource);
                        }
                      }}
                    >
                     {t('configManageList.cancel')}
                    </div>
                  </div>
                }
                onChange={(e: any) => {
                  const _dataSource = [...dataSource];
                  const index: any = getRecordIndex(record);
                  if (index > -1) {
                    _dataSource[index].category = e.target.value.trim();
                  }
                  setDataSource(_dataSource);
                }}
              />
              {errorMessage ? (
                <div style={{ position: 'absolute', color: '#E40C00', top: '50px',fontSize: '12px' }}>{errorMessage}</div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <span>
              {text}
              <i
                className='iconFont icon-navbar_feedback'
                style={{ color: '#999FA9', marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => editCategory(record)}
              />
            </span>
          )
        ) : (
          text
        );
      },
    },
    {
      key: 'ext1',
      title: t('configManageList.table.isShow'),
      dataIndex: 'ext1',
      align: 'left',
      render: (text: any, record: any) => {
        return (
          <Switch
            size="small"
            checked={Number(text) === 1 ? true : false}
            onChange={(checked: any) => {
              if (record.category) {
                onChange(record, 'ext1', checked);
              } else {
                setErrorMessage(t('configManageList.customNamePrompt'));
              }
            }}
          />
        );
      },
    },
    // {
    //   key: 'ext2',
    //   title: '是否必填',
    //   dataIndex: 'ext2',
    //   align: 'left',
    //   render: (text: any, record: any) => {
    //     return (
    //       <Switch
    //         size="small"
    //         defaultChecked={Number(text) === 1 ? true : false}
    //         onChange={(checked: any) => {
    //           if (record.category) {
    //             onChange(record, 'ext2', checked);
    //           } else {
    //             setErrorMessage('请输入自定义名称');
    //           }
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      key: 'ext3',
      title:  t('configManageList.table.openChooseSetting'),
      dataIndex: 'ext3',
      align: 'left',
      render: (text: any, record: any) => {
        return (
          <div>
            {Number(record.ext4) === 1 ? (
              <>
                <Switch
                  defaultChecked={Number(text) === 1 ? true : false}
                  onChange={(checked: any) => {
                    if (checked) {
                      //配置枚举
                      if (record?.enums?.length > 0) {
                        onChange(record, 'ext3', checked);
                      } else {
                        setmodalType('editor');
                        setShowEnumModal(true);
                        setCurrentRow(record);
                      }
                    } else {
                      onChange(record, 'ext3', checked);
                    }
                  }}
                  size="small"
                  disabled={Number(record.ext4) === 1 ? false : true}
                />
              </>
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
    {
      key: 'updatePin',
      title:  t('configManageList.table.operman'),
      dataIndex: 'updatePin',
      align: 'left',
    },
    {
      key: 'updateDate',
      width: 220,
      title:  t('configManageList.table.opertime'),
      dataIndex: 'updateDate',
      align: 'left',
      render: (text: any) => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>,
    },
    {
      title: t('configManageList.table.operate'),
      width: 137,
      align: 'left',
      render: (item: any, record: any) => {
        console.log(item);
        return Number(item.ext4) === 1 && item.id > 0 ? (
          <>
            <a
              href='#'
              rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setmodalType('editor');
                setShowEnumModal(true);
                setCurrentRow(record);
              }}
            >
              {t('configManageList.table.configOption')}
            </a>
            <a
              href='#'
              rel="noreferrer"
              style={{marginLeft:'28px' }}
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: t('configManageList.deleteFieldInfoPrompt'),
                  icon: <ExclamationCircleOutlined />,
                  content: `${t('configManageList.confirmDeletePrompt')}${item.category}${t('configManageList.confirmDeleteQuestionPrompt')}`,
                  okText: t('configManageList.confirmDeletePrompt2'),
                  onOk() {
                    removeItem(item);
                  },
                  cancelText: t('configManageList.thinkAgainPrompt'),
                });
              }}
            >
              {t('configManageList.table.delete')}
            </a>
          </>
        ) : (
          '-'
        );
      },
    },
  ];

  const getSettleConfig = () => {
    getSettleConfigAll({}).then((res: any) => {
      if (res.success) {
        formateDataSource(res);
      }
    });
  };
  const handleClose = (item: any) => {
    setShowEnumModal(false);
    getSettleConfig();
    if (item?.length <= 0) {
      onChange(currentRow, 'ext3', false);
    }
  };
  useEffect(() => {
    getSettleConfig();
  }, []);
  return (
    <div style={{position: 'relative',width: '100%', marginTop: '10px'}}>
      {/* <BreadCrumb data={crumbData} /> */}
      <div className="ispContainer">
        <UserCenterMenu />
        <div className={styles.settleConfig}>
          <Card style={{ padding: '0px 20px 20px 20px',borderRadius: '4px' }}>
            <div className={styles.head}>
              <span className={styles.title}>{t('configManageList.configManage')}</span>
              <Button type="primary" onClick={addConfig} style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}} disabled={!isCreate}>{t('configManageList.addParam')}</Button>
            </div>
            <Table
              columns={columns}
              dataSource={dataSource}
              bordered={false}
              rowKey={(columns: any) => columns.id}
              defaultExpandAllRows={true}
              pagination={false}
              className={styles.table}
              expandedRowKeys={dataSource.map((item: any) => {
                if (item?.enums?.length > 0) {
                  return item.id;
                }
              })}
              expandable={{
                expandedRowRender: (record: any) => {
                  return getExpandedRow(record);
                },
                rowExpandable: (record: any) => {
                  return Number(record.ext4) === 1 && record?.enums?.length > 0;
                },
              }}
            />
          </Card>
        </div>
      </div>
      {showEnumModal && (
        <EnumModal modalType={modalType} visible={showEnumModal} handleCancel={handleClose} data={currentRow} />
      )}
      {/* <FootSlogan/> */}
    </div>
  );
};
export default SettleConfig;
