import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { message } from 'antd';

import Header from '@/components/Header2.0';
import NewFooter from '@/components/NewFooter';
import Steps from './components/Step';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';

import { initStepModel, nextStepModel } from '@/api/updatePassword/api';

import { StepType, VaildListMap } from './types';

const initDataMap: StepType[] = [
  {
    name: '填写账号名',
    n: 1,
    active: true,
  },
  {
    name: '验证身份',
    n: 2,
    active: false,
  },
  {
    name: '设置新密码',
    n: 3,
    active: false,
  },
  {
    name: '完成',
    n: 4,
    active: false,
  },
];

export default () => {
  const [step, setStep] = useState<StepType[]>(initDataMap);
  const [current, setCurrent] = useState<number>(0);
  const [stepModel, setStepModel] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [vaildList, setVaildList] = useState<VaildListMap[]>([]);
  const [pin, setPin] = useState<string>('');

  // change current, set next step
  const changeCurrent = () => {
    const newCurrent = current + 1 === 4 ? 4 : current + 1;
    setCurrent(newCurrent);
    const newStep = step.map(e => {
      if (e.n <= newCurrent) {
        return {
          ...e,
          active: true,
        };
      }
      return { ...e };
    });
    setStep(newStep);
  };

  const updateStepModel = async (param: object) => {
    try {
      const res = await nextStepModel(stepModel, param);
      if (res?.success) {
        if (res?.value?.false) {
          message.error(res?.value?.false);
          return;
        } else {
          // step 1 to step 2
          if (current === 1) {
            setPin(res?.value?.true?.pin);
            setToken(res?.value?.true?.token);
            setStepModel(res?.value?.true?.stepModel);
            setVaildList(res?.value?.true?.childRespList);
          }

          // step2 to step 3
          if (current === 2) {
            setPin(res?.value?.true?.pin);
            setToken(res?.value?.true?.token);
            setStepModel(res?.value?.true?.stepModel);
          }
        }
        changeCurrent();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const nextStep = (param: object) => {
    updateStepModel(param);
  };

  const StepComs = useMemo(() => {
    return (
      <div className={styles.updatePW_content_input}>
        {current === 1 ? <Step1 token={token} onChange={nextStep}></Step1> : ''}
        {current === 2 ? <Step2 vaildList={vaildList} token={token} pin={pin} onChange={nextStep}></Step2> : ''}
        {current === 3 ? <Step3 token={token} onChange={nextStep} pin={pin}></Step3> : ''}
        {current === 4 ? <Step4></Step4> : ''}
      </div>
    );
  }, [token, current, vaildList, pin]);

  // get initStepModel & token
  const getinitStepModel = async () => {
    try {
      const res = await initStepModel();
      if (res?.success) {
        setStepModel(res?.value?.stepModel || '');
        setToken(res?.value?.token || '');
        setCurrent(1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getinitStepModel();
  }, []);

  return (
    <div className={styles.updatePW}>
      <Header></Header>

      <div className={styles.updatePW_content}>
        <Steps step={step}></Steps>
        {StepComs}
      </div>

      <NewFooter></NewFooter>
    </div>
  );
};
