/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-05 11:46:31
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-16 14:09:15
 * @FilePath: /isp_International/src/pages/MaterialCode/components/TableList/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import TableTitle from '../TableTitle';
import TableItem from '../TableItem';

interface TableListProps {
    data:any [];
    selectedIds:string []; //选中的ids
    onChange:(isChecked:boolean, id:string)=>void;
    checkAllHandler:(isChecked:boolean)=>void;
}
const TableList: React.FC<TableListProps> = (props: any) => {
    const { data = [], selectedIds = [], onChange, checkAllHandler, onEdit, onDel } = props;
    return <Card bordered={false}>
        <TableTitle checked={selectedIds.length == data.length}/>
        {!!data.length && data.map((ele, idx)=> 
        <TableItem 
            data = {ele} 
            key ={idx} 
            onEdit={onEdit}
            onDel={onDel}
            checked={selectedIds.includes(ele.id)}
            onChange={(isChecked, id)=>{
                onChange(isChecked, id)
            }}
        />)}
    </Card>
}

export default TableList