import { atom } from 'recoil';

import Main from '../types/main';

const main = atom<Main | undefined>({
  key: 'main',
  default: undefined,
});

export default main;
