import { atom } from 'recoil';

import ProductAttr from '../types/productAttr';

const productAttr = atom<ProductAttr | undefined>({
  key: 'productAttr',
  default: undefined,
});

export default productAttr;
