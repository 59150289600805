/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-10-10 19:13:52
 * @LastEditors: weiyanna weiyanna@jd.com
 * @LastEditTime: 2022-10-11 17:21:20
 * @FilePath: /isp_pro/src/pages/SkuDetailV2/components/Graphic/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';

import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';

import styles from './index.module.scss';
// import PropertyList from '@/components/PropertyList';
import { useTranslation } from 'react-i18next';

const Index = () => {
  // const others = useRecoilValue('others');

  //
  const bigField = useRecoilValue('bigField');
  const { t } = useTranslation();
  //

  // if (!others?.graphic) {
  //   return null;
  // }
  // const oldBaseFlag = others.oldBaseFlag;
  // const productExpandInfos = others.productExpandInfos;
  return (
    <div className={styles.m} id="graphic">
      <div className={styles.m_introduce} style={{ marginBottom: '16px' }}>
        {/*产品介绍*/}
        {t('SkuDetailV2.productDescription')}
      </div>
      {/*{oldBaseFlag && productExpandInfos && productExpandInfos?.length > 0 && (*/}
      {/*  <PropertyList List={productExpandInfos} paddingLeft="0" />*/}
      {/*)}*/}
      {/*<div*/}
      {/*  className={styles.mc}*/}
      {/*  dangerouslySetInnerHTML={{*/}
      {/*    __html: others.graphic,*/}
      {/*  }}*/}
      {/*></div>*/}

      {/**/}
      {/*<div className={styles.dc}>*/}
      {/*  If you'd like a copy of the manufacturer's warranty for a product found on Amazon.com, you can contact the*/}
      {/*  manufacturer directly or visit their website for more information. Manufacturer's warranties may not apply in*/}
      {/*  all cases, depending on factors like the use of the product, where the product was purchased, or who you*/}
      {/*  purchased the product from. Please review the warranty carefully, and contact the manufacturer if you have any*/}
      {/*  questions.*/}
      {/*</div>*/}
      <div
        className={styles.mc}
        dangerouslySetInnerHTML={{
          __html: `${bigField?.pcWdis}`,
        }}
      ></div>
      {/**/}
    </div>
  );
};

export default Index;
