import http from './axios';

export function settleIn(params: any) {
  return new Promise((resolve, reject) => {
    http('post', 'https://api-gw-isp.jd.com/api/babel/saveAnswer', params).then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      },
    );
  });
}
