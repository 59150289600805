/*
 * @Author: duchongchong1
 * @Date: 2022-11-14 19:53:49
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-16 11:30:14
 * @FilePath: /isp_International/src/pages/OrderDetailNew/components/PayInfo/index.tsx
 * @Description:  
 */
import React, { useState } from 'react';

import { formatMoney, cnyMoney } from '@/util';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';

import styles from './index.module.scss';
import { Card } from 'antd';

const ShopRemark: React.FC<{ orderDetails: any }> = ({ orderDetails }) => {
  const orderRes = orderDetails?.orderRes;
  const remarks = orderRes?.orderExtInfo ? JSON.parse(orderRes?.orderExtInfo) : {};
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土 0 本土 1跨境
  const { t } = useTranslation();
  if(isLocalPro){
    remarks?.settleInfo && remarks?.settleInfo.unshift({
      id:-1,
      category: '客户采购单号',
      value:orderRes?.thirdOrderId || '',
    })
  }
  return (
        <div className={styles.shopRemark}>
        <div className={styles.comDetail}>
            <div className={styles.box}>
            <span className={styles.title}>{t('OrderInfo.remarks')} </span>
            {remarks?.settleInfo && remarks?.settleInfo.map((item, idx)=>{
            return <div key={idx} className={styles.item}>
                    <span className={styles.left}>{item.category}</span>
                    <span className={styles.right}>{item.value}</span>
                </div>
            })}
            </div>
            </div>
        </div>

  );
};
export default ShopRemark;
