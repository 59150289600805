/*
 * @Author: weiyanna
 * @Date: 2021-11-29 09:42:35
 * @LastEditTime: 2022-07-08 19:53:27
 * @LastEditors: duchongchong1
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/components/Tabs/index.tsx
 */
import React from 'react';
import { getClstag, getCookie } from '@/util/index';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import { findPublucPromise } from '@/util/index';
import { useSelector } from 'react-redux';

export interface IAppProps {
  purchName?: string;
}
const Tabs: React.FC<IAppProps> = (props: any) => {
  const { pathname } = window.location;
  const { purchName = '默认采购清单' } = props;
  const history = useHistory();
  // 新权限列表
  const promiseMap: any = useSelector(state => {
    return state?.User?.publicPromise;
  });
  const goNewPage = (path: any) => {
    history.push(path);
  };
  return (
    <div className={styles.tab}>
      <span
        onClick={() => goNewPage('/cart/carts')}
        className={pathname.indexOf('/cart/carts') > -1 ? styles.active : ''}
        clstag={purchName == '默认采购清单' ? getClstag('PurchasingList_Default', 'gc') : getClstag(1, 'othercart')}
      >
        {purchName}
      </span>
      {promiseMap && findPublucPromise('PURCHASE_OTHER') && (
        <span
          onClick={() => {
            log('gc', 'PurchasingList_Others', getCookie('pin'));
            goNewPage('/cart/otherCart');
          }}
          className={pathname.indexOf('/cart/otherCart') > -1 ? styles.active : ''}
        >
          其他采购清单
        </span>
      )}
      {promiseMap && findPublucPromise('PURCHASE_BULK_ORDER') && (
        <span
          onClick={() => {
            log('gc', 'PurchasingList_BulkOrder', getCookie('pin'));
            goNewPage('/batchOrder');
          }}
          className={pathname.indexOf('/batchOrder') > -1 ? styles.active : ''}
        >
          批量下单
        </span>
      )}
      {/* <Link to="/cart/carts" className={pathname.indexOf('/cart/carts') > -1 ? styles.active : ''}>
        默认采购清单
      </Link>
      <Link
        to="/cart/otherCart"
        clstag={getClstag(18)}
        className={pathname.indexOf('/cart/otherCart') > -1 ? styles.active : ''}
      >
        其他采购清单
      </Link>
      <Link to="/batchOrder" className={pathname.indexOf('/batchOrder') > -1 ? styles.active : ''}>
        批量下单
      </Link> */}
    </div>
  );
};

export default Tabs;
