import React from 'react';
import styles from '../../index.module.scss';
import { Button } from 'antd';
import SccessPic from '@/assets/images/login/success.png';

export default () => {
  const goLogin = () => {
    window.open('/passport/login', '_self');
  };

  return (
    <div className={styles.step4}>
      <div className={styles.step4_box}>
        <img src={SccessPic} />
        <p>密码重置成功</p>
        <Button className={styles.step4_box_button} onClick={goLogin}>立即登录</Button>
      </div>
    </div>
  );
};
