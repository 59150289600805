import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';

import {
  Player,
  ControlBar,
  PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
  ReplayControl, // 后退按钮
  ForwardControl, // 前进按钮
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton, // 倍速播放选项
  VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css'; // import css

import styles from './index.module.scss';

interface IProps {
  open?: boolean;
  videoUrl?: string;
  cancelCallBack?: () => void;
  videoPicture?: any;
}

const VideoPlayerModal: React.FC<IProps> = (props: any) => {
  const { open, videoUrl, cancelCallBack, videoPicture } = props;
  const [path, setPath] = useState('');
  useEffect(() => {
    if (open) {
      setPath(videoUrl);
    }
  }, [open]);
  return (
    <div id="videoPlayerModal">
      <Modal
        centered
        visible={open}
        width={600}
        destroyOnClose={true}
        className={styles.videoPlayerModal}
        closeIcon={<div className={styles.iconClose}></div>}
        onCancel={() => {
          cancelCallBack && cancelCallBack();
        }}
        footer={null}
        getContainer={() => document.getElementById('videoPlayerModal') as HTMLDivElement}
      >
        <div className={styles.modifyContent}>
          <Player poster={ videoPicture ? videoPicture : 'https://storage.jd.com/dragonsns/100216/20230105/10d29c28f0d642ba860dab0142503b7b.png'}>
            <source src={path} type="video/mp4" />
            <ControlBar autoHide={false} disableDefaultControls={false}>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={30} order={1.2} />
              <PlayToggle />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
              <VolumeMenuButton />
            </ControlBar>
          </Player>
        </div>
      </Modal>
    </div>
  );
};

export default VideoPlayerModal;
