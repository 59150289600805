import { atom } from 'recoil';

import Shipment from '../types/shipment';

const shipment = atom<Shipment | undefined>({
  key: 'shipment',
  default: undefined,
});

export default shipment;
