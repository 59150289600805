import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dispatchApprovalConditionDrawer,
  dispatchApprovalDeleteModel,
  dispatchApprovalModifyNameModel,
} from '@/redux/action';

import { NODE_TYPE, PriorityList, CONDITION_DRAWER_TYPE, CONDITION_LIMIT, PAGE_TYPE } from './../../../constants/index';
import AddNodeBtn from '../AddNodeBtn';

import { CopyNode } from '@/pages/Approval/Create/components/drawFlow/utils/NodeFactory';
import { getUrlsQuery } from '@/util';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  realColNodeLength?: number;
  nodeConfig?: any;
  deleteCallBack?: (node: any) => void;
}
const FlowColNode: React.FC<IProps> = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { nodeConfig, realColNodeLength } = props;
   //翻译审批级别数组字符串
   PriorityList.forEach((item, idx) => {
    item.content = t(`process.add.priorityList.${idx}`);
  })
  const priority = PriorityList.filter(priority => priority.value === nodeConfig.priority);
  const priorityContent = priority[0]?.content;
  const [isCanOperate, setIsCanOperate] = useState(true); // 页面操作类型
  const [isErrorNodeId, setIsErrorNodeId] = useState(false);
  // 操作类型 1新增 2修改 3详情
  const operateType = getUrlsQuery('type') || 1;
  useEffect(() => {
    if (operateType == PAGE_TYPE.detailPage) {
      setIsCanOperate(false);
    } else {
      setIsCanOperate(true);
    }
  }, [operateType]);

  const errorNodeId: any = useSelector((state: any) => {
    return state?.Approval?.errorNodeId;
  });

  useEffect(() => {
    if (errorNodeId !== '' && errorNodeId === nodeConfig.id && nodeConfig?.childNode == undefined) {
      setIsErrorNodeId(true);
    } else {
      setIsErrorNodeId(false);
    }
  }, [errorNodeId, nodeConfig]);

  // 获取添加条件事件
  const handleOpenCondition = (type: number) => {
    const node = type === CONDITION_DRAWER_TYPE.copyConditionNode ? new CopyNode(nodeConfig) : nodeConfig;
    const params = {
      conditionDrawerType: type,
      isShowManageConditionDrawer: true,
      operateNode: node,
    };
    dispatch(dispatchApprovalConditionDrawer(params));
  };
  // 修改名称
  const clickModifyName = () => {
    const params = {
      isShowModifyNameModal: true,
      operateNode: nodeConfig,
    };
    dispatch(dispatchApprovalModifyNameModel(params));
  };
  // 删除节点
  const clickDeleteNode = (node: any) => {
    const params = {
      isShowDeleteModal: true,
      operateNode: node,
    };
    dispatch(dispatchApprovalDeleteModel(params));
  };
  
  return (
    <div
      className={`${styles.conditionNode} ${
        operateType == PAGE_TYPE.detailPage && realColNodeLength === 1 ? styles.smallHeight : styles.heightHeight
      }`}
      id={nodeConfig.id}
    >
      {nodeConfig.nodeType === NODE_TYPE.nodeCondition ? (
        <div className={styles.conditionNodeBox}>
          {isCanOperate && (
            <div
              className={styles.nodeDelete}
              onClick={event => {
                event.stopPropagation();
                clickDeleteNode(nodeConfig);
              }}
            ></div>
          )}

          <div className={`${styles.autoJudge} ${isErrorNodeId ? styles.error : ''}`}>
            <div className={styles.sortLeft}></div>
            <div className={styles.titleWrapper}>
              <span className={styles.editTitle}>
                {nodeConfig.nodeName}
                {isCanOperate && <i className={`iconFont icon-feedback`} onClick={clickModifyName}></i>}
              </span>
              {isCanOperate && (
                <span
                  className={styles.manageTitle}
                  onClick={() => {
                    handleOpenCondition(CONDITION_DRAWER_TYPE.modifyCondition);
                  }}
                >
                  {t("process.add.manageCondition")}
                </span>
              )}
            </div>
            <div className={styles.contentPanel}>
              <div className={styles.priority}>{priorityContent}</div>
              <div className={styles.content}>{nodeConfig.content}</div>
            </div>
          </div>
          <AddNodeBtn nodeConfig={nodeConfig}></AddNodeBtn>
        </div>
      ) : (
        <div className={styles.conditionNodeBox}>
          <div
            className={styles.addConditionContent}
            onClick={() => {
              handleOpenCondition(CONDITION_DRAWER_TYPE.copyConditionNode);
            }}
          >
            <i className={`iconFont icon-add`}></i>
            <span>
            {t("process.add.addCondition")}({realColNodeLength}/{CONDITION_LIMIT})
            </span>
          </div>
          <div className={styles.tempAdd}></div>
        </div>
      )}
    </div>
  );
};

export default FlowColNode;
