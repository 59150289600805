/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2023-12-04 15:09:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-19 13:43:05
 * @FilePath: /isp_International/src/redux/reducer/user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 更新数据
const INITIAL_USER = {
  data: [],
  customerUrl: '',
  carNum: 0,
  initMenus: [], //我的工作台菜单
  location: {},
  publicPromise: [], // 当前权限map
  promiseWhite: false, // 当前登录用户默认使用权限
};

const updateUser = (state: any, action: any) => {
  return {
    ...state,
    data: [{ ...action.data }],
  };
};
const updateLocation = (state: any, action: any) => {
  return {
    ...state,
    location: { ...state.location, ...action.data },
  };
};
const updateCustomer = (state: any, action: any) => {
  return {
    ...state,
    customerUrl: action.url,
  };
};
const updateShopCar = (state: any, action: any) => {
  return {
    ...state,
    carNum: action.num,
  };
};
const updateInitMenus = (state: any, action: any) => {
  return {
    ...state,
    initMenus: action.data,
  };
};
const updatePublicPromise = (state: any, action: any) => {
  return {
    ...state,
    publicPromise: action.fpp,
  };
};
const updatePromiseWhite = (state: any, action: any) => {
  return {
    ...state,
    promiseWhite: action.data,
  };
};
export default function user(state = INITIAL_USER, action: any) {
  switch (action.type) {
    case 'UPDATE_USER_DATA':
      return updateUser(state, action);
    case 'UPDATE_LOCATION_DATA':
      return updateLocation(state, action);
    case 'UPDATE_CUSTOMER':
      return updateCustomer(state, action);
    case 'UPDATE_SHOP_CAR':
      return updateShopCar(state, action);
    case 'UPDATE_USER_INIT_MENUS':
      return updateInitMenus(state, action);
    case 'UPDATE_USER_PUBLIC_PROMISE':
      return updatePublicPromise(state, action);
    case 'UPDATE_USER_PROMISE_WHITE':
      return updatePromiseWhite(state, action);
    default:
      return state;
  }
}
