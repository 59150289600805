/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-07-24 20:26:37
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-21 18:50:57
 * @FilePath: /isp_International/src/util/init.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

export const setVnStyle = function(){
  console.log(localStorage.getItem('_language'))
  if (localStorage.getItem('_language') == 'vi') {
   
    document.body.classList.add('vi-container')
  }
}

//小于1300 开始逐渐缩放页面
export const littleWindow = function () { 
  var documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  if(documentWidth < 1200){
    document.body.style.zoom = 1200/1360;
  }
  else if(documentWidth <= 1360 && documentWidth >= 1200){
    document.body.style.zoom = documentWidth/1360;
  }
  else{
    document.body.style.zoom = 1;
  }
}