/*
 * @Author: duchongchong1
 * @Date: 2022-10-26 09:17:45
 * @LastEditors: duchongchong1
 * @LastEditTime: 2023-08-28 17:14:53
 * @FilePath: /isp_International/src/pages/AccountSettings/components/ChangePasswordModal/index.tsx
 * @Description:
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Button, Modal, Row, Form, Input, message } from 'antd';
import { modifyPwd } from '@/api/newApi/personalCenter/accountSettings';
import { useTranslation } from 'react-i18next';

const ChangePasswordModal: React.FC<{
  visible: boolean;
  onCancel?: () => void;
}> = ({ visible, onCancel }) => {
  // 表单
  const [form] = Form.useForm();
  // 按钮加载
  const [loading, setLoading] = useState(false);
  // 中英文切换
  const { t: modifyPassword } = useTranslation('', { keyPrefix: 'accountSettings.modifyPassword' });
  const { t: common } = useTranslation('', { keyPrefix: 'common' });
  /**
   * 保存的回调函数
   * @param values 保存的form表单信息
   */
  const onFinish = (values: any) => {
    setLoading(true);
    modifyPwd(values)
      .then((res: any) => {
        setLoading(false);
        if (res?.success) {
          message.success(modifyPassword('status.success'));
          onCancel && onCancel();
        } else {
          message.error(res?.msg || modifyPassword('status.fail'));
        }
      })
      .then(() => {
        setLoading(false);
      });
  };
  // 监听visible，实现初始化弹窗清空保留信息
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);
  return (
    <Modal
      title={modifyPassword('title')}
      className="detailModel"
      open={visible}
      onCancel={onCancel}
      centered={true}
      width={500}
      footer={null}
    >
      <Form
        form={form}
        colon={false}
        labelCol={{ span: 6 }}
        name="control-hooks"
        onFinish={onFinish}
        className={styles.searchForm}
      >
        {/* 旧密码 */}
        <Form.Item
          key="formItem1"
          name="oldPwd"
          label={modifyPassword('oldPassword.label')}
          rules={[{ required: true, message: modifyPassword('oldPassword.placeholder') }]}
          getValueFromEvent={e => e.target.value}
        >
          <Input.Password placeholder={modifyPassword('oldPassword.placeholder')} />
        </Form.Item>
        {/* 新密码 */}
        <Form.Item
          extra={<div className={styles.tips}>{modifyPassword('newPassword.extra')}</div>}
          key="formItem2"
          name="newPwd"
          label={modifyPassword('newPassword.label')}
          rules={[{ required: true, message: modifyPassword('newPassword.placeholder') }]}
        >
          <Input.Password placeholder={modifyPassword('newPassword.placeholder')} />
        </Form.Item>
        {/* 确认新密码 */}
        <Form.Item
          key="formItem3"
          name="confirmNewPwd"
          label={modifyPassword('enterAgain.label')}
          validateFirst={true}
          dependencies={['newPassword']}
          rules={[
            { required: true, message: modifyPassword('enterAgain.placeholder') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(modifyPassword('enterAgain.error1')));
              },
            }),
          ]}
          getValueFromEvent={e => e.target.value}
        >
          <Input.Password placeholder={modifyPassword('enterAgain.placeholder')} autoComplete="off" />
        </Form.Item>
        <Row justify="end">
          {/* 保存 */}
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ fontSize: '14px', width: '100px', height: '32px', marginBottom: '10px', marginRight: '16px' }}
          >
            {common('confirmButton')}
          </Button>
          {/* 取消 */}
          <Button onClick={onCancel} style={{ fontSize: '14px', width: '100px', height: '32px', marginBottom: '10px' }}>
            {common('cancelButton')}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
