/*
 * @Author: your name
 * @Date: 2021-11-01 16:02:56
 * @LastEditTime: 2021-11-01 16:08:59
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /pc-iop/src/pages/OtherCarts/utils.ts
 */
const getImageDomain = (name: string, id: number | string, size?: string) => {
  return `//img1${Number(id) % 5}.360buyimg.com/${size ? size : 'n3'}/${name}`;
};

const getPrice = (price: any) => {
  if (!price) {
    return {
      int: '0',
      float: '00',
    };
  }
  const arr = typeof price === 'number' ? String(price.toFixed(2)).split('.') : price.split('.');
  const int = arr[0];
  const float = arr[1];
  return {
    int,
    float,
  };
};

const mapToArray = (data: any) => {
  const pool = [];
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];
      pool.push({
        key,
        value,
      });
    }
  }
  return pool;
};

const objectToParams = (obj: any) => {
  let string = '';
  for (const key in obj) {
    if (string != '') {
      string += '&';
    }
    string += key + '=' + encodeURIComponent(obj[key]);
  }
  return string;
};

const cutString = (string: any, long = 10) => {
  if (!string || typeof string !== 'string') {
    return '';
  }
  return string.length > long ? `${string.slice(0, long)}...` : string;
};

const getClstag = (id: any) => `h|keycount|othercart|${id}`;

export { cutString, getClstag, getImageDomain, getPrice, mapToArray, objectToParams };
