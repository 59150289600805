/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-03-06 11:21:11
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-06 11:21:22
 * @FilePath: /isp_International/src/pages/OrderInfo/atoms/conditionList.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { atom } from 'recoil';


const conditionList = atom({
  key: 'conditionList',
  default: undefined,
});

export default conditionList;