/* eslint-disable no-unused-vars */
/**
 * @author 李毅
 * @time 2022-12
 * @dec 各种节点类
 */
import { HashCode } from './index.js';
import { NODE_TYPE } from '../../../constants/index';
import {t} from 'i18next';

export class NextNode {
  constructor({ id, prevId, nodeType, isRow }) {
    this.id = id;
    this.prevId = prevId;
    this.nodeType = nodeType;
    this.isRow = isRow;
    this.isRoot = 0;
    this.nodeName = nodeType === 2 ? t("process.add.approvalPerson") : t("process.add.approvalCondition");
    this.priority = 1; // 默认条件都是一
  }
}
// 新增审批员节点
export function newAccountNode(nextNode) {
  return {
    groupId: nextNode.id,
    id: HashCode(),
    nodeName: nextNode.nodeName,
    nodeType: nextNode.nodeType,
    content: nextNode.content,
    isRow: nextNode.isRow,
    isRoot: 0,
    priority: 1,
  };
}
// 新增条件节点
export function newConditionNode({ groupId, nodeName, nodeType, id, isRow, priority, conditionList, content }) {
  return {
    id: HashCode(),
    groupId: groupId,
    nodeName: nodeName,
    nodeType: nodeType,
    groupPid: id,
    isRow: isRow,
    isRoot: 0,
    content: content,
    priority: priority,
    conditionList: conditionList,
    hasChild: false,
  };
}
// 新增添加条件节点
export function newAddConditionNode({ groupId, groupPid, isRow }, priority) {
  return {
    id: HashCode(),
    groupId: groupId,
    nodeType: NODE_TYPE.nodeAddCondition,
    groupPid: groupPid,
    isRow: isRow,
    isRoot: 0,
    nodeName: t("process.add.approvalCondition"),
    priority: priority,
    hasChild: false,
  };
}

export class CopyNode {
  id = HashCode();
  nodeType = NODE_TYPE.nodeCondition;
  constructor({ id, nodeType, childNode, ...node }) {
    return Object.assign(node, this);
  }
}
