/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2023-03-09 11:02:45
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-12 10:31:59
 * @FilePath: /isp_pro/src/pages/Approval/Create/floor/set/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';

import DrawFlow from '../../components/drawFlow';

import { getOperatorVideo } from '@api/newApi/approval';

import VideoPlayerModal from '@/pages/Approval/Create/components/videoPlayerModal';
import styles from '../../index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  operateType?: number;
  stateApproval?: any;
}
const FloorSetApproval: React.FC<IProps> = (props: any) => {
  const { stateApproval, operateType } = props;
  const [isShowVideoPlayer, setIsShowVideoPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const { t } = useTranslation();

  const getOperatorVideoFn = () => {
    getOperatorVideo().then(res => {
      // console.log('视频查看', res);
      if (res.success) {
        setVideoUrl(res.value.videoUrl);
        setIsShowVideoPlayer(true);
      } else {
        setIsShowVideoPlayer(false);
      }
    });
  };

  return (
    <div className={styles.set}>
      <div className={styles.setTitle}>
        <div className={styles.titleTop}>
          <span className={styles.topLeft}>{t('process.add.setting')}</span>
          {/* <span className={styles.topRight} onClick={getOperatorVideoFn}>
            查看视频教程
          </span> */}
        </div>
        {/* <div className={styles.titleTips}>
          {stateApproval?.processType === 1
            ? t('process.add.tips.0')
            : t('process.add.tips.1')}
        </div> */}
      </div>
      <div className={styles.setContent}>
        <DrawFlow
          operateType={operateType}
          processType={stateApproval?.processType}
          flowConfig={
            stateApproval?.processType === 1 ? stateApproval.noConditionNodeList : stateApproval.haveConditionNodeList
          }
        ></DrawFlow>
      </div>

      {isShowVideoPlayer && (
        <VideoPlayerModal
          open={isShowVideoPlayer}
          videoUrl={videoUrl}
          cancelCallBack={() => {
            setIsShowVideoPlayer(false);
          }}
        ></VideoPlayerModal>
      )}
    </div>
  );
};

export default FloorSetApproval;
