/*
 * @Author: your name
 * @Date: 2021-11-30 17:16:40
 * @LastEditTime: 2021-11-30 18:11:35
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/Carts/atoms/skuList.ts
 */
import { atom } from 'recoil';

import { Sku } from '../interface';

const skuList = atom<Array<Sku> | undefined>({
  key: 'skuList',
  default: [],
});

export default skuList;
