import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NextNode } from '../utils/NodeFactory';
import { dispatchApprovalAccountModel, dispatchApprovalConditionDrawer } from '@/redux/action';
import { ACCOUNT_DRAWER_TYPE, CONDITION_DRAWER_TYPE, NODE_TYPE, PAGE_TYPE } from '../../../constants/index';

import { getUrlsQuery } from '@/util';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  nodeConfig: any;
}
const AddNodeBtn: React.FC<IProps> = (props: any) => {
  const { nodeConfig } = props;
  const dispatch = useDispatch();
  const [isCanOperate, setIsCanOperate] = useState(true); // 页面操作类型
  const { t } = useTranslation();

  const processType: any = useSelector((state: any) => {
    return state?.Approval?.processType;
  });

  // 如果已经挂载审批条件的节点，不能再次创建审批条件节点
  const [isCanCreateConditionNode, setIsCanCreateConditionNode] = useState(false); // 页面操作类型

  // 操作类型 1新增 2修改 3详情
  const operateType = getUrlsQuery('type') || 1;
  useEffect(() => {
    if (operateType == PAGE_TYPE.detailPage) {
      setIsCanOperate(false);
    } else {
      setIsCanOperate(true);
    }
    //判断别动 以后业务扩展有用
    // 1: 已经添加过审批条件的节点以及审批条件本身 不能添加子条件节点
    // 2: isRootHaveConditionNode 跟节点添加过条件节点不能 再次添加节点
    // 无条件操作不能添加
    // if (
    //   processType === 1 ||
    //   (nodeConfig?.childNode && nodeConfig?.childNode?.conditionNodes) ||
    //   nodeConfig?.nodeType === 3 ||
    //   nodeConfig?.isRootHaveConditionNode
    // ) {
    //   setIsCanCreateConditionNode(false);
    // } else {
    //   setIsCanCreateConditionNode(true);
    // }
  }, [operateType, nodeConfig]);
  // 审批节点类型
  const itemAccount = {
    nodeType: 2,
    isRow: 1,
  };
  // 审批条件类型
  const itemCondition = {
    nodeType: 3,
    isRow: 0,
  };
  const clickSelectBox = (item: any) => {
    const { id, prevId, nodeType, isRow } = Object.assign(nodeConfig, item);
    const nextNode = new NextNode({ id, prevId, nodeType, isRow });

    if (item.nodeType === 2) {
      const params = {
        accountDrawerType: ACCOUNT_DRAWER_TYPE.addAccountNode,
        isShowAddAccountDrawer: true,
        operateNode: nextNode,
      };
      dispatch(dispatchApprovalAccountModel(params));
    } else {
      // 请选择新增类型 审批条件
      const params = {
        conditionDrawerType: CONDITION_DRAWER_TYPE.addConditionNode,
        isShowManageConditionDrawer: true,
        operateNode: nextNode,
      };
      dispatch(dispatchApprovalConditionDrawer(params));
    }
  };
  return (
    <div className={styles.addNodeBtnBox}>
      <div className={`${styles.addNodeBtn} ${operateType == PAGE_TYPE.detailPage ? styles.smallNodeBtn : ''}`}>
        {isCanOperate && (nodeConfig.nodeType !== 1 || processType !== 1) && (
          <div
            className={styles.btn}
            onClick={event => {
              if (!isCanCreateConditionNode) {
                event.stopPropagation();
                if(nodeConfig.nodeType === 1){
                  clickSelectBox(itemCondition);
                  return;
                }
                clickSelectBox(itemAccount);
              }
            }}
          >
            <i className={`iconFont icon-round_add`}></i>
            {isCanCreateConditionNode && (
              <div className={styles.addPanel}>
                <div className={styles.addContent}>
                  <div className={styles.panelTitle}>{t('process.add.AddNodeBtn.PleaseSelectAddType')}</div>
                  <div className={styles.panelItems}>
                    <div
                      className={`${styles.panelItem} ${styles.node}`}
                      onClick={event => {
                        event.stopPropagation();
                        clickSelectBox(itemAccount);
                      }}
                    >
                      <div>
                        <i className={`iconFont icon-sptj`}></i>
                      </div>
                      <span>{t('process.add.AddNodeBtn.ApprovalNode')}</span>
                    </div>

                    {nodeConfig?.nodeType !== 3 && isCanCreateConditionNode && (
                      <div
                        className={`${styles.panelItem} ${styles.condition}`}
                        onClick={event => {
                          event.stopPropagation();
                          clickSelectBox(itemCondition);
                        }}
                      >
                        <div>
                          <i className={`iconFont icon-spjd`}></i>
                        </div>
                        <span>{t('process.add.AddNodeBtn.ApprovalConditions')}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNodeBtn;
