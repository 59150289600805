import React, { useState } from 'react';
import { Button, Card, Form, Input, Row, Col, FormInstance } from 'antd';
import Empty from '@/components/common/Empty';
import styles from './index.module.scss';
import SelectAccountModal from '../SelectAccountModal';
import { findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';

const Audit: React.FC<{
  auditType: number;
  form: FormInstance<any>;
  nodeList: any[];
  addNode: () => void;
  removeNode: () => void;
  onChangeNode: (node: any, index: number) => void;
  editing: boolean;
}> = ({ auditType, editing, form, nodeList, addNode, removeNode, onChangeNode }) => {
  const { t } = useTranslation();
  const [currentNode, setCurrentNode] = useState<any>({});

  const [buyAccountModalVisible, setBuyAccountModalVisible] = useState<boolean>(false);

  const nodeBatchActions = () => {
    return (
      <div style={{ paddingBottom: '20px' }}>
        <Row
          style={{
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: '15px 0',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '12px', color: 'rgba(51, 51, 51, 0.85)' }}>
            {t('process.detail.card_info3.title')}
          </span>
        </Row>
        <div>
          <Button disabled={nodeList.length > 9} style={{ marginRight: '20px' }} onClick={addNode}>
            {t('process.detail.card_info3.button.0')}
          </Button>
          <Button disabled={nodeList.length <= 0} onClick={removeNode}>
            {t('process.detail.card_info3.button.1')}
          </Button>
        </div>
      </div>
    );
  };

  const nodeListTitle = (
    <Row
      style={{
        backgroundColor: 'rgba(0, 3, 20, 0.08)',
        padding: '10px 0',
        color: '#fff',
      }}
    >
      <Col className={[styles.title1, styles.center].join(' ')}>{t('process.detail.card_info3.table_title.0')}</Col>
      <Col className={[styles.title2, styles.center].join(' ')}>{t('process.detail.card_info3.table_title.1')}</Col>
      <Col className={[styles.title3, styles.center].join(' ')}>{t('process.detail.card_info3.table_title.2')}</Col>
      <Col className={[styles.title4, styles.center].join(' ')}>{t('process.detail.card_info3.table_title.3')}</Col>
    </Row>
  );

  const checkNumber = (limit: string) => {
    return limit && /^\d*(\.\d*)?$/.test(limit) && parseFloat(limit) > 0;
  };

  const checkCondition = (limit: string, index: number) => {
    if (index < nodeList.length - 1) {
      form.validateFields(['condition' + (index + 1)]);
    }
    return index === 0 || index === nodeList.length - 1 ? true : parseFloat(limit) > nodeList[index - 1].condition;
  };

  const auditList =
    nodeList.length > 0 ? (
      <div>
        <Form form={form}>
          {nodeList.map((o: any, index: number) => (
            <Row key={index}>
              <Col className={[styles.title1, styles.goodsCell, styles.borderBottom, styles.borderLeft].join(' ')}>
                {o.name}
              </Col>
              <Col
                className={[styles.title2, styles.goodsCell, styles.borderBottom].join(' ')}
                style={{ minHeight: '73px' }}
              >
                {auditType === 2 ? (
                  <Row style={{ paddingTop: '20px' }}>
                    <span style={{ marginRight: '10px', marginTop: '6px' }}>&gt;=</span>
                    <Form.Item
                      name={'condition' + index}
                      validateFirst={true}
                      rules={[
                        { required: true, message: t('process.detail.card_info3.tabel_cell1.message.0') },
                        {
                          validator: (rule, value, fn) => {
                            if (!checkNumber(value)) {
                              fn(t('process.detail.card_info3.tabel_cell1.message.0'));
                            } else if (!checkCondition(value, index)) {
                              fn(t('process.detail.card_info3.tabel_cell1.message.1'));
                            } else {
                              fn();
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder={t('process.detail.card_info3.tabel_cell1.placeholder')}
                        addonAfter="元"
                        style={{ width: '220px' }}
                        onChange={e => {
                          if (checkNumber(e.target.value)) {
                            onChangeNode({ ...o, condition: parseFloat(e.target.value) }, index);
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                ) : (
                  <span>{t('process.detail.card_info1.option1.1')}</span>
                )}
              </Col>
              <Col
                className={[styles.title3, styles.goodsCell, styles.borderBottom].join(' ')}
                style={{ textAlign: 'center' }}
              >
                <Form.Item
                  name={'pins' + index}
                  validateFirst={true}
                  rules={[{ required: true, message: t('process.detail.card_info3.table_cell2.message.0') }]}
                >
                  <Row align="middle" justify="center" style={{ height: '73px', marginBottom: '-22px' }}>
                    <span className={styles.pins}>{(o.pins || []).join('; ')}</span>
                  </Row>
                </Form.Item>
              </Col>
              <Col
                className={[styles.title4, styles.goodsCell, styles.borderBottom, styles.borderRight].join(' ')}
                style={{ flexDirection: 'row' }}
              >
                <Button
                  type="link"
                  onClick={() => {
                    setCurrentNode(nodeList[index]);
                    setBuyAccountModalVisible(true);
                    if (editing) {
                      log('jdisp', 'EditApprove_AddBuyer');
                    } else {
                      log('jdisp', 'CreateApprove_AddBuyer');
                    }
                  }}
                >
                  {t('process.detail.card_info3.table_op.0')}
                </Button>
              </Col>
            </Row>
          ))}
        </Form>
      </div>
    ) : (
      <Empty height="190px" />
    );

  return (
    <Card id="audit" className={styles.nodeList} style={{ marginTop: '10px', paddingBottom: '20px' }}>
      <div style={{ padding: '0 20px' }}>
        {nodeBatchActions()}
        {nodeListTitle}
        {auditList}
        {buyAccountModalVisible && (
          <SelectAccountModal
            visible={buyAccountModalVisible}
            pins={currentNode.pins || []}
            onOK={(pins: string[]) => {
              currentNode.pins = pins;
              const index = findIndex(nodeList, ['name', currentNode.name]);
              onChangeNode(currentNode, index);
              form.setFieldsValue({ ['pins' + index]: pins.join('; ') });
              form.validateFields(['pins' + index]);
              setBuyAccountModalVisible(false);
            }}
            onClose={() => setBuyAccountModalVisible(false)}
          />
        )}
      </div>
    </Card>
  );
};

export default Audit;
