/*
 * @Author: weiyanna
 * @Date: 2022-03-09 16:56:04
 * @LastEditTime: 2022-11-11 00:38:01
 * @LastEditors: duchongchong1
 * @Description: 审批弹窗(查看审批流 & 上传文件)
 * @FilePath: /isp_International/src/components/ApproveProcess/index.tsx
 */
import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import ApproveList from './list';
import ApproveFile from './file';
import { orderTime } from '@/components/Order';
import styles from './index.module.scss';
import { getFlow } from '@/api/newApi/approvalList';
import { useTranslation } from 'react-i18next';
interface IAppProps {
  order: object;
  visible: boolean;
  type: string;
  setAuditModalVisible: Function;
  isShowApprove: boolean; //是否有审批进度
}
const ApproveProcess: React.FC<IAppProps> = (props: any) => {
  const { t } = useTranslation();
  const { order, visible, setAuditModalVisible, type, isShowApprove = true } = props;
  return (
    <Drawer
      title={t('approvalList.approvalDetail.title')}
      placement="right"
      open={visible}
      size="large"
      closable={false}
      onClose={() => {
        setAuditModalVisible(false);
      }}
      className={styles.drawer_style}
      extra={
        <span
          onClick={() => {
            setAuditModalVisible(false);
          }}
          className={styles.drawer_close}
        ></span>
      }
      width={590}
    >
      <ApproveFile
        orderData={{
          id: order.jdOrderId || order.orderNo,
          createOrderTime: orderTime(order),
          orderPin: order.pin,
          orderNeedMoney: order.orderNeedMoney || order.needPayMoney || order.totalPrice,
        }}
        type={type}
        isShowTip={isShowApprove}
      ></ApproveFile>
      {isShowApprove && (
        <ApproveList
          isModel={true}
          source="orderList"
          jdOrderId={order.jdOrderId || order.orderNo}
          orderPin={order.pin}
        />
      )}
    </Drawer>
    // <Modal
    //   className={styles.approveProcess}
    //   title="审批详情"
    //   visible={visible}
    //   destroyOnClose
    //   onCancel={() => {
    //     setAuditModalVisible(false);
    //   }}
    //   footer={[
    //     <Button
    //       type="primary"
    //       key="cancelOrder"
    //       onClick={() => {
    //         setAuditModalVisible(false);
    //       }}
    //       style={{ fontSize: '16px', width: '245px', height: '46px' }}
    //     >
    //       我知道了
    //     </Button>,
    //   ]}
    //   width={620}
    // >
    //   <ApproveFile
    //     orderData={{
    //       id: order.jdOrderId,
    //       createOrderTime: orderTime(order),
    //       orderPin: order.pin,
    //       orderNeedMoney: order.orderNeedMoney || order.needPayMoney,
    //     }}
    //     type={type}
    //     isShowTip={isShowApprove}
    //   ></ApproveFile>
    //   {isShowApprove && (
    //     <ApproveList isModel={true} source="orderList" jdOrderId={order.jdOrderId} orderPin={order.pin} />
    //   )}
    // </Modal>
  );
};
export default ApproveProcess;
