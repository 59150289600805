/**
 *
 * @param data
 * @returns {*[]}
 */
export function formatOrderList(data) {
  const newArray = [];
  data?.forEach(item => {
    //每个订单
    const newOderItem = JSON.parse(JSON.stringify(item)); //复制一行订单
    const { waresReqs } = item;
    const emptyWaresReqs = [];
    waresReqs?.forEach(item1 => {
      //sku集合
      if (item1.skuType === 0) {
        //主商品
        const newItemSku = JSON.parse(JSON.stringify(item1)); //复制一个主商品
        const { sku } = item1;
        let temp1 = waresReqs.filter(item2 => item2.parentSku === sku);
        if (temp1.length > 0) {
          temp1 = temp1.map(item3 => {
            return {
              skuName: item3.skuName,
              skuNum: item3.skuNum,
              skuType: item3.skuType,
              skuImg: item3.skuImg,
            };
          });
          newItemSku.gift = temp1;
        }
        emptyWaresReqs.push(newItemSku);
      }
    });
    newOderItem.waresReqs = emptyWaresReqs;
    newArray.push(newOderItem);
  });
  return newArray;
}

/**
 *
 * @param orderSkus
 */
export function formatApprovalList(data) {
  const newArray = {
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    pageTotal: data.pageTotal,
    total: data.total,
    items: [],
  };
  data?.items?.forEach(item => {
    //每个订单
    const newOderItem = JSON.parse(JSON.stringify(item)); //复制一行订单
    const { orderSkus } = item;
    const emptyWaresReqs = [];
    orderSkus?.forEach((item1, index) => {
      //sku集合
      if (item1.skuType === 0) {
        //主商品
        const newItemSku = JSON.parse(JSON.stringify(item1)); //复制一个主商品
        const { skuId } = item1;
        let temp1 = orderSkus.filter(item2 => item2.parentSku === skuId);
        if (temp1.length > 0) {
          temp1 = temp1.map(item3 => {
            return {
              skuName: item3.name,
              skuNum: item3.num,
              skuType: item3.skuType,
              skuImg: item3.imgUrl,
            };
          });
          newItemSku.gift = temp1;
        }
        emptyWaresReqs.push(newItemSku);
      }
    });
    newOderItem.orderSkus = emptyWaresReqs;
    newArray.items.push(newOderItem);
  });

  return newArray;
}

export function formatOrderDetail(data) {
  // 每个订单
  const emptyWaresReqs = [];
  data?.forEach(item1 => {
    //sku集合
    if (item1.skuType === 0) {
      //主商品
      const newItemSku = JSON.parse(JSON.stringify(item1)); //复制一个主商品
      const { sku } = item1;
      let temp1 = data.filter(item2 => item2.parentSku === sku);
      if (temp1.length > 0) {
        temp1 = temp1.map(item3 => {
          return {
            skuName: item3.skuName,
            skuNum: item3.skuNum,
            skuType: item3.skuType,
            skuImg: item3.skuImg,
          };
        });
        newItemSku.gift = temp1;
      }
      emptyWaresReqs.push(newItemSku);
    }
  });
  return emptyWaresReqs;
}

export function formatCartInfo(data) {
  const emptyWaresReqs = [];
  data?.map(item => {
    const gift = {
      skuName: item?.info?.product_name,
      skuNum: item?.info?.num,
      skuType: item?.info?.giftType,
      skuImg: item?.info?.img_dfs_url,
    };
    emptyWaresReqs.push(gift);
  });
  return emptyWaresReqs;
}
