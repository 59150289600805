/*
 * @Author: your name
 * @Date: 2021-09-16 11:05:14
 * @LastEditTime: 2024-05-20 20:26:19
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_pro/src/components/common/SimplePagination/index.tsx
 */
import React from 'react';
import { Row } from 'antd';
import Icon from '@/components/common/Icon';

import iconPageLeft from '@/assets/images/common/icon_page_left.svg';
import iconPageLeftDis from '@/assets/images/common/icon_page_left_dis.svg';
import iconPageRight from '@/assets/images/common/icon_page_right.svg';
import iconPageRightDis from '@/assets/images/common/icon_page_right_dis.svg';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const SimplePagination: React.FC<{
  total: number;
  page: number;
  totalPage: number;
  onPrev?: React.MouseEventHandler<HTMLDivElement>;
  onNext?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ total, page, totalPage, onPrev, onNext }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
      <span style={{ color: '#999', marginRight: '19px', fontSize: '12px' }}>
        {t('pageTotal.pre')} <span style={{ color: '#666' }}>{total}</span> {t('pageTotal.order')}
      </span>
      <Icon
        icon={page <= 1 ? iconPageLeftDis : iconPageLeft}
        width="20px"
        height="20px"
        style={{ marginRight: '15px' }}
        onClick={onPrev}
      />
      <span style={{ fontSize: '14px', color: '#666' }}>
        <span className={[styles.blue, styles.bold].join(' ')}>{page > totalPage ? totalPage : page}</span>/{totalPage}
      </span>
      <Icon
        icon={page >= totalPage ? iconPageRightDis : iconPageRight}
        width="20px"
        height="20px"
        style={{ marginLeft: '15px' }}
        onClick={onNext}
      />
    </Row>
  );
};

export default SimplePagination;
