/*
 * @Author: duchongchong1
 * @Date: 2022-10-27 14:09:42
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-10-27 14:11:52
 * @FilePath: /isp_International/src/api/AES.ts
 * @Description:
 *
 */
import * as CryptoJS from 'crypto-js';

const AuthTokenKey = '3lc58fg8EFN9fntG'; //AES密钥
const AuthTokenIv = 'clKu3cM0uynlvU7M'; //AES向量

/*AES加密*/
export function Encrypt(data: any = '') {
  const dataStr = typeof data === 'string' ? data : JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(dataStr, CryptoJS.enc.Latin1.parse(AuthTokenKey), {
    iv: CryptoJS.enc.Latin1.parse(AuthTokenIv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

/*AES解密*/
export function Decrypt(data: any = '') {
  const data2 = data.replace(/\n/gm, '');
  const decrypted = CryptoJS.AES.decrypt(data2, CryptoJS.enc.Latin1.parse(AuthTokenKey), {
    iv: CryptoJS.enc.Latin1.parse(AuthTokenIv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
