import React from 'react';

import AddNodeBtn from '../AddNodeBtn';
import FlowColNode from '../DrawCol/FlowColNode';
import { CONDITION_LIMIT, PAGE_TYPE } from '../../../constants/index';
import drawFlow from '../utils/FlowFactory';

import styles from '../DrawCol/index.module.scss';
import { newAddConditionNode } from '@/pages/Approval/Create/components/drawFlow/utils/NodeFactory';

function conditionNodeRender(h, node, realColNodeLength, processType, operateType) {
  const judgeNode = { ...node };
  const tep = [];
  tep.push(
    // eslint-disable-next-line react/react-in-jsx-scope
    <FlowColNode key={judgeNode.id} nodeConfig={judgeNode} realColNodeLength={realColNodeLength}></FlowColNode>,
  );
  if (node.childNode) {
    const el = drawFlow.getFactory(h, node.childNode, processType, operateType);
    tep.push(el);
  }
  return tep;
}

function colBoxRender(h, colNodeArr, processType, operateType) {
  // 审批条件真实节点长度
  const tempColNodeArr = colNodeArr.filter(colNode => colNode.nodeType !== 4);
  const realColNodeLength = tempColNodeArr.length;
  // console.log('realColNode', realColNodeLength);
  if (realColNodeLength >= CONDITION_LIMIT) {
    colNodeArr = tempColNodeArr;
  }
  // 如果是详情页面，且条件长度为1
  if (realColNodeLength === 1 && operateType === PAGE_TYPE.detailPage) {
    return colNodeArr.map(item => {
      // eslint-disable-next-line react/react-in-jsx-scope
      return (
        <div className={styles.colBox1} key={item.id}>
          <div className={styles.topLeftCoverLine}></div>
          <div className={styles.bottomLeftCoverLine}></div>
          <div className={styles.topRightCoverLine}></div>
          <div className={styles.bottomRightCoverLine}></div>
          {conditionNodeRender(h, item, realColNodeLength, processType, operateType)}
        </div>
      );
    });
  } else {
    return colNodeArr.map((item, idx) => {
      switch (idx) {
        case 0:
          return (
            <div className={styles.colBox1} key={item.id}>
              <div className={styles.topLeftCoverLine}></div>
              <div className={styles.bottomLeftCoverLine}></div>
              {conditionNodeRender(h, item, realColNodeLength, processType, operateType)}
            </div>
          );
        case colNodeArr.length - 1:
          return (
            <div className={styles.colBox1} key={item.id}>
              {conditionNodeRender(h, item, realColNodeLength, processType, operateType)}
              <div className={styles.topRightCoverLine}></div>
              <div className={styles.bottomRightCoverLine}></div>
            </div>
          );
        default:
          // eslint-disable-next-line react/react-in-jsx-scope
          return (
            <div className={styles.colBox1} key={item.id}>
              {conditionNodeRender(h, item, realColNodeLength, processType, operateType)}
            </div>
          );
      }
    });
  }
}

function branchBoxRender(h, nodeArr, processType, operateType) {
  // console.log('数据上下功夫------>2', nodeArr);

  const colNodeArr = nodeArr.conditionNodes;
  const colNodeLength = colNodeArr.length;
  // 新建和编辑的时候需要补一个添加条件节点
  if (colNodeLength < CONDITION_LIMIT && operateType !== PAGE_TYPE.detailPage) {
    const priorityList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    colNodeArr.forEach(colNode => {
      const temp = priorityList.indexOf(colNode.priority);
      if (temp > -1) {
        priorityList.splice(temp, 1);
      }
    });
    // console.log('priorityList-1', priorityList);
    const lastPriority = priorityList[0];
    // console.log('priorityList-2', lastPriority);
    const lastColNode = colNodeArr[colNodeArr.length - 1];
    colNodeArr.push(newAddConditionNode({ ...lastColNode }, lastPriority));
    // console.log('priorityList-3', colNodeArr);
  }
  return (
    <div className={styles.branchWrap} key={colNodeArr.length}>
      <div className={styles.branchBoxWrap}>
        <div className={styles.branchBox}>{colBoxRender(h, colNodeArr, processType, operateType)}</div>
        {!(operateType === PAGE_TYPE.detailPage && colNodeLength === 1) && (
          <AddNodeBtn nodeConfig={nodeArr} processType={processType}></AddNodeBtn>
        )}
      </div>
    </div>
  );
}

export default {
  branchBoxRender,
};
