import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

const Tooltip: React.FC<{
  title: React.ReactNode | string;
  placement?: TooltipPlacement;
  size?: 'long' | 'short';
  width?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onVisibleChange?: (visible: boolean) => void;
}> = ({ title, placement = 'bottomLeft', size = 'long', width, style, children, onVisibleChange, ...otherProps }) => (
  <AntdTooltip
    overlayInnerStyle={{
      fontSize: '12px',
      color: '#333',
      padding: '15px',
      maxWidth: width ? width : size === 'long' ? '413px' : '240px',
      ...style,
    }}
    color="#fff"
    placement={placement}
    arrowPointAtCenter
    title={title}
    onOpenChange={onVisibleChange}
    // visible={true}
    {...otherProps}
  >
    {children}
  </AntdTooltip>
);

export default Tooltip;
