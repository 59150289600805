/*
 * @Author: duchongchong1
 * @Date: 2022-10-31 19:15:24
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-02 16:23:41
 * @FilePath: /isp_International/src/api/newApi/workbench.ts
 * @Description:
 */
/* eslint-disable no-undef */
import http from '../axios';

//菜单管理控制层
export function getMenu(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/menu/get', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
