import showModal from './showModal';
import operStatus from './operStatus';
import selectedId from './selectedId';

const atoms = {
    showModal,
    operStatus,
    selectedId
};

export default atoms;