import React from 'react';
import { Card, Form, Input, Row, Space, Radio, FormInstance } from 'antd';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const BaseInfo: React.FC<{
  form: FormInstance<any>;
  editing: boolean;
  auditType: number;
  onTypeChange: (type: number) => void;
}> = ({ form, auditType, onTypeChange, editing }) => {
  const { t } = useTranslation();
  const submit = (values: any) => {
    console.log(values);
  };

  const searchForm = () => {
    return (
      <Form
        form={form}
        colon={false}
        name="control-hooks"
        onFinish={submit}
        className={styles.searchForm}
        style={{ padding: '20px' }}
      >
        <Row style={{ alignItems: 'center' }}>
          <span style={{ fontWeight: 'bold', fontSize: '12px', color: 'rgba(51,51,51,0.85)' }}>
            {t('process.detail.card_info1.title')}
          </span>
        </Row>
        <Space wrap size={[50, 0]} style={{ marginTop: '20px' }}>
          <Form.Item required key="formItem1" label={t('process.detail.card_info1.label.0')}>
            <Row style={{ alignItems: 'center' }}>
              <Radio.Group
                buttonStyle="solid"
                value={auditType}
                onChange={e => {
                  onTypeChange(e.target.value);
                }}
              >
                <Radio.Button
                  value={2}
                  key={2}
                  onClick={() => {
                    if (editing) {
                      log('jdisp', 'EditApprove_Conditional');
                    } else {
                      log('jdisp', 'CreateApprove_Conditional');
                    }
                  }}
                >
                  {t('process.detail.card_info1.option1.0')}
                </Radio.Button>
                <Radio.Button
                  value={1}
                  key={1}
                  onClick={() => {
                    if (editing) {
                      log('jdisp', 'EditApprove_Standard');
                    } else {
                      log('jdisp', 'CreateApprove_Standard');
                    }
                  }}
                >
                  {t('process.detail.card_info1.option1.1')}
                </Radio.Button>
              </Radio.Group>
            </Row>
          </Form.Item>
          <Form.Item
            key="formItem2"
            name="name"
            label={t('process.detail.card_info1.label.1')}
            rules={[{ required: true, message: `${t('process.detail.card_info1.message')}` }]}
          >
            <Input placeholder={t('process.detail.card_info1.placeholder')} maxLength={40} style={{ width: '390px' }} />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  return <Card id="baseInfo">{searchForm()}</Card>;
};

export default BaseInfo;
