import { atom } from 'recoil';

import Model from '../types/model';

const model = atom<Model | undefined>({
  key: 'model',
  default: undefined,
});

export default model;
