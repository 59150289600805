import { Button, Col, Drawer, Form, Input, Row, Select, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Rule } from 'antd/lib/form';
import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import areaCode from '@/assets/json/areaCode.json';
import ProvinceCityCascader from '../../ProvinceCityCascader'
import { useTranslation } from 'react-i18next';
const { Item } = Form;
interface Props {
  address: any;
  visible: boolean;
  onOk: (address: any) => void;
  onCancel: () => void;
}
const AddArea = ({ visible, onOk, onCancel, ...rest }: Props) => {
  // 表单
  const [form] = useForm();
  // 中英文翻译
  const { t } = useTranslation();
  /**
   * 保存逻辑
   */
  const onSave = () => {
    form.validateFields().then(values => {
      console.log('values', values);
      values.receivePhone = `${values.areaCode} ${values.receivePhone}`;
      onOk({ ...rest.address, ...values });
    });
  };
  // 初始化地址弹窗逻辑
  useEffect(() => {
    if (visible) {
      form.resetFields();
      let { receivePhone = '' } = rest.address;
      let areaCode = '+86';
      if (receivePhone.includes(' ')) {
        areaCode = receivePhone.split(' ')[0];
        receivePhone = receivePhone.split(' ')[1];
      }
      
      form.setFieldsValue({
        ...rest.address,
        areaCode,
        receivePhone,
        defaultStatus: rest.address.defaultStatus === 0 ? false : true,
        countryArea:rest?.address?.countryId||''
        });
    }
  }, [visible]);
  // form校验规则
  const rules: { [k: string]: Rule[] } = {
    // 收货人
    receiveName: [{ required: true, message: t('myAddress.addModal.form.receiver.placeholder') }],
    // 电话，
    // 依赖区号校验电话号码，目前只有中国手机号码校验
    receivePhone: [
      {
        required: true,
        message: t('myAddress.addModal.form.phoneNumber.placeholder'),
      },
      ({ getFieldValue }) => ({
        validator: (_, value) => {
          const areaCode = getFieldValue('areaCode');
          switch (areaCode) {
            case '+86':
              return /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)
                ? Promise.resolve()
                : Promise.reject(t('myAddress.addModal.form.phoneNumber.errorTip'));
            default:
              return Promise.resolve();
          }
        },
      }),
    ],
    // 国家
    country: [{ required: true, message: t('myAddress.addModal.form.country.placeholder') }],
    // 详细地址
    detailAddress: [{ required: true, message: t('myAddress.addModal.form.detailedAddress.placeholder') }],
  };
  return (
    <Drawer
      width={590}
      title={`${rest.address.contractId ? t('common.edit') : t('common.add2')} ${t('myAddress.addModal.title')}`}
      visible={visible}
      className={styles.drawer}
      onClose={onCancel}
      destroyOnClose={true}
      footer={
        <>
          {/* 保存 */}
          <Button type="primary" onClick={onSave} style={{ marginRight: '16px' }}>
            {t('common.save')}
          </Button>
          {/* 取消 */}
          <Button onClick={onCancel}>{t('common.cancelButton')}</Button>
        </>
      }
    >
      <Form form={form} layout="vertical">
        {/* 收货人 */}
        <Item label={t('myAddress.addModal.form.receiver.label')} name="receiveName" rules={rules.receiveName}>
          <Input placeholder={t('myAddress.addModal.form.receiver.placeholder')} maxLength={100}></Input>
        </Item>
        {/* 区号 + 电话 */}
        <Item label={t('myAddress.addModal.form.phoneNumber.label')} required>
          <Row gutter={[8, 0]}>
            <Col span={6}>
              <Item noStyle name="areaCode">
                <Select placeholder="区号">
                  {areaCode.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={18}>
              {/* 电话 */}
              <Item dependencies={['areaCode']} noStyle name="receivePhone" rules={rules.receivePhone}>
                <Input maxLength={40} placeholder={t('myAddress.addModal.form.phoneNumber.placeholder')}></Input>
              </Item>
            </Col>
          </Row>
        </Item>
        {/* 国家 */}
        {/* <Item label={t('myAddress.addModal.form.country.label')} name="country" rules={rules.country}>
          <Input placeholder={t('myAddress.addModal.form.country.placeholder')} maxLength={100}></Input>
        </Item> */}
        <ProvinceCityCascader detailData={rest} formRef={form}/>
        {/* 详细地址 */}
        <Item
          label={t('myAddress.addModal.form.detailedAddress.label')}
          name="detailAddress"
          rules={rules.detailAddress}
        >
          <Input.TextArea
            placeholder={t('myAddress.addModal.form.detailedAddress.placeholder')}
            maxLength={500}
          ></Input.TextArea>
        </Item>
        {/* 邮政编码 */}
        <Item label={t('myAddress.addModal.form.zipCode.label')} name="postalCode">
          <Input placeholder={t('myAddress.addModal.form.zipCode.placeholder')} maxLength={100}></Input>
        </Item>
        {/* 是否默认 */}
        <Item>
          <span style={{ marginRight: '8px' }}>{t('myAddress.addModal.form.setDefault.label')}</span>
          <Item noStyle name="defaultStatus" valuePropName="checked">
            <Switch
              onChange={() => {
                log('jdisp', 'Settlement_default');
              }}
            ></Switch>
          </Item>
        </Item>
      </Form>
    </Drawer>
  );
};

export default AddArea;
