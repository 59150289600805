import http from '../../axios';




/**
 * 获取用户信息
 * @returns
 */
export const getPersonInfo = async () => {
  return http('get', '/jdig/account/getPersonInfo', {}, false, true);
};

/**
 * 获取账号详情
 * @returns
 */
export const getAccountInfo = async () => {
  return http('get', '/jdig/account/getInfo', {}, false, true);
};

/**
 * 发送验证邮件
 */
// 127.0.0.1/account/sendVerifyMail?env=CN&pin=ISP研发账号测试2&contractNum=ISP-1234567890&logoUrl=http://www.jd.com&userType=5&mail=ext.hanhongwei5@jd.com
export const sendVerifyMail = (mail: string) => {
  return http('jsonpost', '/jdig/account/sendVerifyMail', { mail }, false, true);
};

/**
 * 绑定邮箱
 */
// 127.0.0.1/account/bind?env=CN&pin=ISP研发账号测试2&contractNum=ISP-1234567890&logoUrl=http://www.jd.com&userType=5
export const bindingEmail = (data: any) => {
  return http('jsonpost', '/jdig/account/confirmBind', data, false, true);
};

/**
 * 修改密码
 */
type ModifyPWDType = {
  // 旧密码
  oldPwd: string;
  // 新密码
  newPwd: string;
  // 新密码确认
  confirmNewPwd: string;
};
export const modifyPwd = (data: ModifyPWDType) => http('jsonpost', '/jdig/account/modifyPwd', data, false, true);
