/*
 * @Author: your name
 * @Date: 2021-11-30 17:16:40
 * @LastEditTime: 2021-11-30 18:18:04
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/OtherCarts/components/Filter/index.tsx
 */
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useSetRecoilState, useRecoilValue } from '../../hooks';
import { getClstag } from '@/util/index';

import styles from './index.module.scss';

const Filter = () => {
  const setFilter = useSetRecoilState('filter');

  const orderList = useRecoilValue('orderList');

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = useCallback(data => {
    setFilter(data);
  }, []);

  const onReset = useCallback(() => {
    reset();
    setFilter(undefined);
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.m}>
        <div className={styles.mt}>
          <div className={styles.item}>
            <div className={styles.label}>清单名称</div>
            <div className={styles.content}>
              <input type="text" {...register('purchName')} placeholder="输入名称关键词检索" />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>项目名称</div>
            <div className={styles.content}>
              <input type="text" {...register('projectName')} placeholder="输入名称关键词检索" />
            </div>
          </div>
          <div className={styles.item} clstag={getClstag(2, 'Cart')}>
            <div className={styles.label}>单据状态</div>
            <div className={styles.select}>
              <select {...register('status')}>
                <option value={''}>全部</option>
                <option value={'1'}>有效</option>
                <option value={'0'}>失效</option>
              </select>
            </div>
          </div>
        </div>
        <div className={styles.mc}>
          <div className={styles.total}>{`共${orderList?.length}个采购清单`}</div>
          <div className={styles.reset} onClick={onReset} clstag={getClstag(3, 'Cart')}>
            重置
          </div>
          <button type="submit" className={styles.submit} clstag={getClstag(4, 'Cart')}>
            查询
          </button>
        </div>
      </div>
    </form>
  );
};

export default Filter;
