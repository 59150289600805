import React, { useEffect, useState } from 'react';
import { Button, Card, Row } from 'antd';
import icon_bind_email_success from '@/assets/images/accountSettings/icon_bind_email_success.png';
import icon_bind_email_failed from '@/assets/images/accountSettings/icon_bind_email_failed.png';

import styles from './index.module.scss';

import { GetRequest } from '@/util/index';
import { bindingEmail } from '@/api/newApi/personalCenter/accountSettings';
import { useTranslation } from 'react-i18next';

// 地址search信息
const urlParams: any = GetRequest();
const BindingEmail: React.FC = () => {
  // 中英文切换
  const { t: i18_bindingEmail } = useTranslation('', { keyPrefix: 'accountSettings.bindingEmail' });

  const [countDown, setCountDown] = useState<number>(0);
  const [response, setResponse] = useState<any>();

  const getData = () => {
    // const req = GetRequest()
    const { u, m: email, t, k: code } = urlParams;
    bindingEmail({
      code,
      email,
      u,
      t,
    }).then((res: any) => {
      if (res?.success) {
        setResponse({ success: true });
      } else {
        setResponse({ success: false });
      }
      setCountDown(3);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (response) {
      if (countDown <= 0) {
        window.location.href = '/accountSettings';
        return;
      }
      const intervalId = setInterval(() => {
        setCountDown(prevCount => prevCount - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [countDown]);
  const [prefix, suffix] = i18_bindingEmail('description').split('%{timer}%');
  return (
    <div>
      {/* <BreadCrumb data={crumbData} /> */}
      <div className="ispContainer">
        <div className={styles.content}>
          <Card style={{ padding: '96px 0', height: '465px' }}>
            {response && (
              <Row align="middle" justify="center" style={{ flexDirection: 'column' }}>
                <img src={response && response.success ? icon_bind_email_success : icon_bind_email_failed} />
                <div className={styles.title}>
                  {response && response.success ? i18_bindingEmail('success') : i18_bindingEmail('fail')}
                </div>
                <div className={styles.tips}>
                  {prefix}
                  <span>{countDown}</span>
                  {suffix}
                </div>
                <a href="/accountSettings">
                  <Button type="primary" style={{ width: '156px', height: '48px', marginTop: '24px' }}>
                    {i18_bindingEmail('buttonText')}
                  </Button>
                </a>
              </Row>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
export default BindingEmail;
