import React, { useRef, useState } from 'react';
import styles from '../../index.module.scss';
import { Button, Checkbox, Form, Input, message, Select } from 'antd';
import { Step3PropsType } from '../../types';

export default (props: Step3PropsType) => {
  const { onChange, pin, token } = props;
  const [inputerror, setInputError] = useState<boolean>(false);
  const input1 = useRef();
  const input2 = useRef();

  const nextStep = () => {
    try {
      const newPwd = input1?.current?.input?.value;
      const repeatPwd = input2?.current?.input?.value;
      if (!newPwd || !repeatPwd) {
        message.error('请输入修改密码！');
        return;
      }
      if (newPwd !== repeatPwd) {
        setInputError(true);
        return;
      } else {
        setInputError(false);
      }

      const params = window.btoa(window.encodeURIComponent(JSON.stringify({ pin, token, newPwd, repeatPwd })));
      onChange && onChange({ params: params });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={`${styles.step2} ${styles.step_common}`}>
      <div className={`${styles.step_common_form} ${styles.step2_form}`}>
        <div className={styles.step_common_form_box}>
          <div className={`${styles.step_common_form_box_title} ${styles.step3_box_title}`}>请填写新密码:</div>
          <Input.Password ref={input1} placeholder="请填写新密码" className={styles.step_common_form_box_input} />
        </div>
        <div className={styles.step_common_form_box} style={{ marginBottom: '8px' }}>
          <div className={`${styles.step_common_form_box_title} ${styles.step3_box_title}`}>请确认新密码:</div>
          <Input.Password
            ref={input2}
            placeholder="请确认新密码"
            className={`${styles.step_common_form_box_input} ${
              inputerror ? styles.step_common_form_box_input_error : ''
            }`}
          />
        </div>
        {inputerror ? (
          <div className={styles.step_common_form_box}>
            <div className={`${styles.step_common_form_box_title} ${styles.step3_box_title}`}></div>
            <div className={styles.step_common_form_box_input_errortext}>两次输入密码不一致</div>
          </div>
        ) : (
          <div className={styles.step_common_form_box} style={{ height: '14px' }} />
        )}
        <div className={styles.step_common_form_box}>
          <div className={styles.step2_box_title} style={{ marginRight: '8px' }}></div>
          <Button className={styles.step_common_form_box_button} onClick={nextStep}>
            下一步
          </Button>
        </div>
      </div>
    </div>
  );
};
