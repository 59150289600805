import React, { useEffect, useRef, useState } from 'react';
import styles from '../../index.module.scss';
import { Button, Select, Input, message, Statistic } from 'antd';
const { Countdown } = Statistic;
const { Option } = Select;
import { getEmailPhoneverifyCode } from '@/api/updatePassword/api';

import { Step2PropsType, VaildListMap } from '../../types';

export default (props: Step2PropsType) => {
  const { onChange, vaildList, pin, token } = props;
  const [defaultValue, setDefaultValue] = useState<number | undefined>(1);
  const [cansendVC, setCanSendVC] = useState<boolean>(true);
  const select = useRef();
  const input = useRef();

  const nextStep = () => {
    try {
      const selecttype = defaultValue;
      const verifyCode = input?.current?.input?.value;
      if (!selecttype || !verifyCode) {
        message.error('请选择验证方式并填写验证码！');
        return;
      }
      const params = window.btoa(
        window.encodeURIComponent(JSON.stringify({ pin, verifyMode: defaultValue, token, verifyCode })),
      );

      onChange && onChange({ params: params });
    } catch (e) {
      console.log(e);
    }
  };

  // send verification code
  const sendVCApi = async () => {
    try {
      const res = await getEmailPhoneverifyCode({ pin, verifyType: defaultValue, token });
      if (res?.success) {
        message.success('发送成功');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (v: number) => {
    console.log(v);
    setDefaultValue(v);
  };

  const sendVC = () => {
    setCanSendVC(false);
    sendVCApi();
  };

  const countdownChange = (val: number) => {
    if (val < 50) {
      setCanSendVC(true);
    };
  };

  useEffect(() => {
    const dv = vaildList ? vaildList[0]?.vaildType : undefined;
    setDefaultValue(dv);
  }, [vaildList]);

  return (
    <div className={`${styles.step2} ${styles.step_common}`}>
      <div className={`${styles.step_common_form} ${styles.step2_form}`}>
        <div className={styles.step_common_form_box}>
          <div className={`${styles.step_common_form_box_title} ${styles.step2_box_title}`}>请选择验证方式:</div>
          {defaultValue && (
            <Select
              ref={select}
              value={defaultValue}
              placeholder="请选择验证方式"
              optionLabelProp="label"
              onChange={handleChange}
              className={styles.step_common_form_box_input}
            >
              {vaildList &&
                vaildList.map((e: VaildListMap) => {
                  return (
                    <Option
                      key={e.vaildType}
                      value={e.vaildType}
                      label={`已验证${e.vaildName}${e.vaildTypeValue}`}
                    >{`${e.vaildName}验证`}</Option>
                  );
                })}
            </Select>
          )}
        </div>
        <div className={styles.step_common_form_box}>
          <div className={`${styles.step_common_form_box_title} ${styles.step2_box_title}`}>
            {vaildList?.find(e => e.vaildType === defaultValue)
              ? `请填写${vaildList?.find(e => e.vaildType === defaultValue)['vaildName']}验证码:`
              : '请填写验证码:'}
          </div>
          <Input
            ref={input}
            placeholder="请输入验证码"
            className={`${styles.step_common_form_box_input} ${styles.step2_box_input} `}
          />
          <div className={styles.step2_box_change}>
            {cansendVC ? (
              <span onClick={sendVC}>发送验证码</span>
            ) : (
              <div className={styles.step2_box_change_resend}>
                重新发送&nbsp;
                <Countdown title="Day Level" value={Date.now() + 1000 * 60} format="s 秒" onChange={countdownChange} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.step_common_form_box}>
          <div className={styles.step2_box_title} style={{ marginRight: '8px' }}></div>
          <Button className={styles.step_common_form_box_button} onClick={nextStep}>
            下一步
          </Button>
        </div>
      </div>
    </div>
  );
};
