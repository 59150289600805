import RowFactory from './FactoryRow';
import CalFactory from './FactoryCol';
function getFactory(h, item, processType, operateType) {
  const tep = [];
  if (item?.type === 'route') {
    tep.push(CalFactory.branchBoxRender(h, item, processType, operateType));
    if (item?.childNode) {
      if (item?.conditionNodes?.length > 2) {
        item.childNode.needLine = true;
      }
      tep.push(getFactory(h, item.childNode, processType, operateType));
    }
  }
  if (item?.type !== 'route') {
    tep.push(RowFactory.nodeWrapRender(h, item, processType, operateType));
  }
  return tep;
}
export default {
  getFactory,
};
