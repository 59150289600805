import { selector } from 'recoil';

import filter from './filter';
import model from './model';

const products = selector({
  key: 'products',
  get: ({ get }) => {
    const filterState = get(filter);
    const modelData = get(model);

    return (
      modelData?.productList?.filter(item => {
        if (filterState) {
          let result = true;
          for (const key in filterState) {
            if (Object.prototype.hasOwnProperty.call(filterState, key)) {
              const value = filterState[key];
              if (key === 'keyword') {
                // 关键字搜索，需要过滤 mkuName model mkuId 3种信息，这三种信息在初始化阶段已经合并为字段 combineText 方便过滤
                if (item?.combineText?.indexOf(value) < 0) {
                  result = false;
                }
              } else {
                // item?[key] 先确保商品里带有该属性，再去判断是否命中。商品里压根没这个属性就不过滤，否则过滤结果一定是空
                if (item?.[key] && value?.indexOf(item?.[key]) < 0) {
                  result = false;
                }
              }
            }
          }
          return result;
        }
        return true;
      }) || []
    );
  },
});

export default products;
