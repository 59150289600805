// 图片对应资源
import banner_title_telephone from '@/assets/images/login/banner_title_telephone.gif';
const banner_background =
  'https://img10.360buyimg.com/imagetools/jfs/t1/178933/2/26553/294684/62bd938fEa9450e07/e256feb34ee14b8d.jpg';
import banner_q from '@/assets/images/login/banner_q.png';
import banner_q_blue from '@/assets/images/login/banner_q_blue.png';
const banner_register =
  'https://img13.360buyimg.com/imagetools/jfs/t1/202956/37/24932/39179/62c2a59eEab823185/f823d5941f4501d4.png';
import title_cn from '@/assets/images/login/cn-gcgj.png'
import title_en from '@/assets/images/login/en-gcgj.png'
const banner_big_title = title_cn
const banner_big_title_en = title_en
//import banner_big_title_blue from '@/assets/images/login/banner_big_title_blue.png';
const banner_big_title_blue = 'https://img10.360buyimg.com/imagetools/jfs/t1/114941/28/33950/7167/64212f98F9cd9a90a/e3a4f053fa877815.png';
const ability_top_1 =
  'https://img12.360buyimg.com/imagetools/jfs/t1/176903/1/26923/33297/62c2a53eE44f5b546/1c2879b44988420f.png';
const ability_top_2 =
  'https://img14.360buyimg.com/imagetools/jfs/t1/87009/7/26001/33453/62c2a543E3165ba5e/0191c9cb0842c7a6.png';
const ability_top_3 =
  'https://img14.360buyimg.com/imagetools/jfs/t1/219494/7/18280/24567/62c2a547E61149254/d3e839bb58cf57ad.png';
const abilitys_1 =
  'https://img11.360buyimg.com/imagetools/jfs/t1/63338/6/19296/7112/62c2a515E72686cdf/960f577a5096abc1.png';
const abilitys_2 =
  'https://img14.360buyimg.com/imagetools/jfs/t1/206951/18/21663/5032/62c2a520Eea8ac990/dfc2c2cac81d5f7b.png';
const abilitys_3 =
  'https://img10.360buyimg.com/imagetools/jfs/t1/53465/33/19872/6826/62c2a528E18108985/d3fc7a669e506f39.png';
const abilitys_4 =
  'https://img11.360buyimg.com/imagetools/jfs/t1/127832/28/28812/5499/62c2a52dE2fe86a48/437434e2bc5cf796.png';
const abilitys_5 =
  'https://img11.360buyimg.com/imagetools/jfs/t1/190478/25/26626/3179/62c2a510E37036d41/c924b23d6bffe9ba.png';
const abilitys_6 =
  'https://img14.360buyimg.com/imagetools/jfs/t1/20612/28/17587/2919/62c2a508Ecf39afd3/9b12d9102ec8c978.png';
const ability_background =
  'https://img10.360buyimg.com/imagetools/jfs/t1/182079/7/26295/17141/62bd93a2E0052a5d6/eee69e6530144b8a.png';

const serve_1 =
  'https://img10.360buyimg.com/imagetools/jfs/t1/78128/28/20446/34586/62c2a456E96dbe731/98a3a9e28905e9a9.png';
const serve_2 =
  'https://img14.360buyimg.com/imagetools/jfs/t1/191465/26/26397/32462/62c2a456E5180a806/2472b134e09b35d9.png';
const serve_3 =
  'https://img10.360buyimg.com/imagetools/jfs/t1/97501/22/29004/30066/62c2a456E008381ee/fc5d1abc2cc848c0.png';
const serve_4 =
  'https://img10.360buyimg.com/imagetools/jfs/t1/17943/1/17527/28512/62c2a45dE156b9eec/e29fdc605b1880ac.png';
import serve_num1 from '@/assets/images/login/serve_num1.png';
import serve_num2 from '@/assets/images/login/serve_num2.png';
import serve_num3 from '@/assets/images/login/serve_num3.png';
import serve_num4 from '@/assets/images/login/serve_num4.png';

const carousel_1 =
  'https://img12.360buyimg.com/imagetools/jfs/t1/214311/13/20834/225284/62c2a3feE9ad0d099/336bdeaf549b70dc.png';
const carousel_2 =
  'https://img11.360buyimg.com/imagetools/jfs/t1/189651/40/26571/271343/62c2a3feE76c4e84e/66824d2539f414d3.png';
const carousel_3 =
  'https://img12.360buyimg.com/imagetools/jfs/t1/40391/4/16890/190230/62c2a3ffEc42bb536/a51861b5951fd8af.png';
const carousel_4 =
  'https://img11.360buyimg.com/imagetools/jfs/t1/102276/3/24102/197945/62c2a3ffEf11a5625/4e870669d71f0c37.png';

import case_top_1 from '@/assets/images/login/case_top_1.png';
import case_top_2 from '@/assets/images/login/case_top_2.png';
import case_top_3 from '@/assets/images/login/case_top_3.png';
import case_1 from '@/assets/images/login/case_1.png';
import case_2 from '@/assets/images/login/case_2.png';
import case_3 from '@/assets/images/login/case_3.png';
import case_4 from '@/assets/images/login/case_4.png';
import case_5 from '@/assets/images/login/case_5.png';
import case_6 from '@/assets/images/login/case_6.png';
import case_7 from '@/assets/images/login/case_7.png';
import case_8 from '@/assets/images/login/case_8.png';
import case_9 from '@/assets/images/login/case_9.png';
import case_10 from '@/assets/images/login/case_10.png';
import case_icon from '@/assets/images/login/case_icon.png';
const quick_register =
  'https://img12.360buyimg.com/imagetools/jfs/t1/210166/37/24256/499151/62c3d355E0fbc28f8/76142d1c4c922e74.png';

const bottom_background =
  'https://img11.360buyimg.com/imagetools/jfs/t1/59131/5/20732/44072/62bd93b0Eb54eb109/adeca5e188a2920c.png';

const common_background =
  'https://img12.360buyimg.com/imagetools/jfs/t1/62045/40/19763/255382/62c2aeaeE0d9410d8/0b908b8616b31cda.png';

const QRcode = 'https://img10.360buyimg.com/imagetools/jfs/t1/112422/6/36946/16247/64212d12F1934c091/ae5fe4ac157c9b3a.png'
import getImg from '@/util/getImg';

const PIC_ENMU = {
  banner: {
    banner_big_title: banner_big_title,
    banner_big_title_en: banner_big_title_en,
    banner_big_title_blue: banner_big_title_blue,
    banner_title_telephone: banner_title_telephone,
    banner_q: banner_q,
    banner_q_blue: banner_q_blue,
    banner_background: {
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      backgroundImage: `url(${getImg(banner_background, 3840, 1080)})`,
    },
    banner_register: {
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      backgroundImage: `url(${getImg(banner_register, 1020, 200)})`,
    },
  },
  ability: {
    ability_top_1: getImg(ability_top_1, 208, 208),
    ability_top_2: getImg(ability_top_2, 210, 208),
    ability_top_3: getImg(ability_top_3, 210, 208),
    abilitys_1: getImg(abilitys_1, 96, 96),
    abilitys_2: getImg(abilitys_2, 96, 96),
    abilitys_3: getImg(abilitys_3, 96, 96),
    abilitys_4: getImg(abilitys_4, 96, 96),
    abilitys_5: getImg(abilitys_5, 96, 96),
    abilitys_6: getImg(abilitys_6, 96, 96),
    ability_background: {
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      backgroundImage: `url(${getImg(ability_background, 778, 464)})`,
    },
  },
  serve: {
    serve_1: getImg(serve_1, 220, 220),
    serve_2: getImg(serve_2, 220, 220),
    serve_3: getImg(serve_3, 220, 220),
    serve_4: getImg(serve_4, 220, 220),
    serve_num1: serve_num1,
    serve_num2: serve_num2,
    serve_num3: serve_num3,
    serve_num4: serve_num4,
  },
  carousel: {
    carousel_1: getImg(carousel_1, 850, 548),
    carousel_2: getImg(carousel_2, 850, 548),
    carousel_3: getImg(carousel_3, 850, 548),
    carousel_4: getImg(carousel_4, 850, 548),
  },
  case: {
    case_top_1: case_top_1,
    case_top_2: case_top_2,
    case_top_3: case_top_3,
    case_1: case_1,
    case_2: case_2,
    case_3: case_3,
    case_4: case_4,
    case_5: case_5,
    case_6: case_6,
    case_7: case_7,
    case_8: case_8,
    case_9: case_9,
    case_10: case_10,
    case_icon: {
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      backgroundImage: `url(${case_icon})`,
    },
  },
  quick_register: getImg(quick_register, 840, 840),
  bottomBackground: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    backgroundImage: `url(${getImg(bottom_background, 3840, 694)})`,
  },
  common: {
    QRcode: QRcode,
  },
  commonBackground: {
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    backgroundImage: `url(${getImg(common_background, 3840, 1166)})`,
  },
};

export default PIC_ENMU;
