import React, { useEffect, useState } from 'react';
// import { pathOr } from 'ramda';
// import { message } from 'antd';
import { RecoilRoot } from 'recoil';
import { useParams } from 'react-router-dom';
import cookies from 'react-cookies';
// import { useDispatch } from 'react-redux';
// import _ from 'lodash';

// 旧的API
// import {
//   // 获取others和main
//   getInitBigField,
//   getInitSkuDetail,
//   // getMoreMkuInfo,
//   // getProductBindInfo,
//   // getInitSkuSimilar,
// } from '@/api/SkuDetail';

// 新的API
import {
  getInitSimilarProduct2,
  getInitProductAttr2,
  getInitBasicInfo2,
  getInitBigField2,
  getInitProductCategory2,
  breadCrumbDataFormat,
  getProDetail,
  getProGroup
} from '@/api/newApi/skudetail';
// import { getCompareBarData } from '@/api/search/api';
// import { dispatchAllCheckContrast } from '@/redux/action';

import Graphic from './components/Graphic';
import Main from './components/Main';
// import Models from './components/Models';
import Nav from './components/Nav';
import Parameter from './components/Parameter';

import { useSetRecoilState, useRecoilValue } from './hooks';
// import { mainDataFormat, otherDataFormat, modelDataFormat, logger } from './utils/index';

import styles from './index.module.scss';

const Index = () => {
  const { skuId } = useParams<Record<string, any>>();
  const [stationtype] = useState(cookies.load('stationType'));

  // const dispatch = useDispatch();
  // const setCompare = useSetRecoilState('compare');
  // const setSpecifications = useSetRecoilState('specifications');
  // const setModel = useSetRecoilState('model');
  // const setService = useSetRecoilState('service');

  //
  // const setMain = useSetRecoilState('main');
  // const setOthers = useSetRecoilState('others');
  const setBigField = useSetRecoilState('bigField');
  const setBasicInfo = useSetRecoilState('basicInfo');
  const setProductAttr = useSetRecoilState('productAttr');
  const setSimilarProduct = useSetRecoilState('similarProduct');
  const setProductCategory = useSetRecoilState('productCategory');
  const setMaterialInfo = useSetRecoilState('materialInfo');

  // const amount = useRecoilValue('amount');

  useEffect(() => {
    // getInitBigField({
    //   skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     // logger('initBigField:', res?.value);
    //     setOthers(otherDataFormat(res?.value));
    //   } else {
    //     message.error(res?.msg);
    //   }
    // });
    // getMoreMkuInfo({
    //   mku: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     logger('moreMkuInfo:', res?.value);
    //     setModel(modelDataFormat(res?.value));
    //     getCompareBarData().then(res => {
    //       const goods = pathOr([], ['value', 'mkuList'], res);
    //       setCompare(compare => ({
    //         ...compare,
    //         goods,
    //       }));
    //       dispatch(dispatchAllCheckContrast(goods));
    //     });
    //   } else {
    //     message.error(res?.msg);
    //   }
    // });

    // getInitSkuSimilar({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setSpecifications(res.value);
    //   }
    // });
      getProDetail({
        mkuId:+skuId
      }).then((res:any)=>{
        if(res?.success){
          const value = res.value
          setBigField(value.bigField);
          setBasicInfo(value.baseInfo);
          setProductAttr(value.productAttr);
          setProductCategory(breadCrumbDataFormat(value.productCategory))
          console.log(value?.materialInfo, 'value?.materialInfo')
          setMaterialInfo(value?.mkuMaterial);
        }
      })
      getProGroup({
        mkuId:+skuId
      })
      .then((res:any) => {
        if (res?.success) {
          setSimilarProduct(res.value);
        }
      })

    // getInitBigField2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setBigField(res.value);
    //   }
    // });
    // getInitBasicInfo2({
    //   skuId: skuId,
    //   num: 1,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setBasicInfo(res.value);
    //   }
    // });
    // getInitProductAttr2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setProductAttr(res.value);
    //   }
    // });
    // getInitSimilarProduct2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setSimilarProduct(res.value);
    //   }
    // });
    // getInitProductCategory2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setProductCategory(breadCrumbDataFormat(res.value));
    //   }
    // });
  }, [stationtype]);

  // const getInitSkuDetailFunc = _.debounce((amount: any) => {
  //   if (!amount) return;
  //   getInitSkuDetail({
  //     skuId,
  //     buyNum: amount,
  //   }).then((res: any) => {
  //     if (res?.success) {
  //       // logger('initSkuDetail:', res?.value);
  //       setMain(mainDataFormat(res?.value));
  //     } else {
  //       message.error(res?.msg);
  //     }
  //   });
  //   // getProductBindInfo({
  //   //   skuId,
  //   //   num: amount,
  //   // }).then((res: any) => {
  //   //   if (res?.success) {
  //   //     logger('serviceList:', res?.value);
  //   //     setService(res?.value);
  //   //   } else {
  //   //     // message.error(res?.msg);
  //   //   }
  //   // });
  // }, 300);

  // useEffect(() => {
  //   getInitSkuDetailFunc(amount);
  // }, [amount]);

  return (
    <div className={styles.m}>
      <Main />
      {/*<Models />*/}
      <div className={styles.mc}>
        <Nav />
        <Parameter />
        <Graphic />
      </div>
    </div>
  );
};

const Container: React.FC = () => {
  return (
    <RecoilRoot>
      <Index />
    </RecoilRoot>
  );
};

export default Container;
