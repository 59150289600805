import React, { useEffect, useState } from 'react';
import { Row, Button, message, Col, Pagination, Modal, Tooltip, Table } from 'antd';

import UserCenterMenu from '@/components/UserCenterMenu';
import Tab from '@/components/Tab';

import styles from './index.module.scss';
import moment from 'moment';
import { PAGE_SIZE_OPTIONS } from '@/enum/config';
import { goTop } from '@/util';
import { findIndex } from 'lodash';
import { useHistory } from 'react-router-dom';
import CommonBasicHeader from '@/components/CommonBasicHeader';

import SearchEmpty from '@/assets/images/accountSettings/icon_search_empty.png';
import { getDownloadFileList, removeDownloadFile } from '@/api/newApi/personalCenter/downloadCenter';
import { useTranslation } from 'react-i18next';
import PaginationJD from '@/components/PaginationJD';
import { getTaskList } from '@/api/newApi/taskCenter';
export interface IAppProps {}
// (1排队中2执行中3已完成4失败）
enum TaskStateEnum {
  待执行 = 1,
  执行中,
  执行完成,
  执行失败,
}
enum TypeEnum {
  订单导入 = 'getOrderPrintList',
  订单导出 = 'getOrderExportList',
}
const DownloadCenter: React.FC<IAppProps> = () => {
  const queryParams = new URLSearchParams(window.location.search);
  // 页面刷新flag
  const [refresh, setRefresh] = useState(true);
  // 页码
  const [page, setPage] = useState<number>(1);
  // 中英文切换
  const { t } = useTranslation();
  // 页面数据展示条数
  const [pageSize, setPageSize] = useState<number>(30);
  // 页面数据总数
  const [total, setTotal] = useState<number>(0);
  // 数据集合
  const [dataSource, setDataSource] = useState([]);
  // 页面加载状态
  const [pageLoad, setPageLoad] = useState(false);

  const tabs = [
    {
      name: t('downloadCenter.tab.0'),
      type: 'getOrderPrintList',
      count: 0,
    },
    // {
    //   name: t('downloadCenter.tab.1'),
    //   type: 'getOrderExportList',
    //   count: 0,
    // },
  ];
  // 页面展示tab，默认为 订单打印
  const [type, setType] = useState<TypeEnum>((queryParams.get('type') as TypeEnum) || 'getOrderPrintList');
  // 路由历史记录操作
  const history = useHistory();
  // 获取列表
  const getList = ({ page = 1, pageSize }) => {
    setPageLoad(true);
    setDataSource([]);
    getTaskList({
      index: 1,
      size: pageSize,
    }).then((res: any) => {
      setPageLoad(false);
      const value = res?.value ?? {};
      const taskList = value?.records ?? [];
      setDataSource(taskList);
      setTotal(value?.total ?? 0);
    });
  };
  // 重置页面及刷新列表
  const reset = () => {
    setPage(1);
    setDataSource([]);
    setRefresh(true);
    log('jdisp', 'Downloads_Refresh');
  };
  // 当前分页重新获取数据
  useEffect(() => {
    if (refresh) {
      getList({
        page:1,
        pageSize
      });
      setRefresh(false);
    }
  }, [refresh]);
  // 刷新按钮
  const refreshButton = (
    <>
      <span style={{ marginRight: '15px', color: 'rgba(0,0,0,0.45)' }}>{t('downloadCenter.pageTip')}</span>
      <span
        style={{
          color: 'rgba(0,3,20,0.85)',
          fontSize: '14px',
          padding: '6px 19px',
          cursor: 'pointer',
          border: '1px solid rgba(0,3,20,0.15)',
          borderRadius: '2px',
        }}
        onClick={reset}
      >
        {t('common.refresh')}
      </span>
    </>
  );

  const defaultTab = findIndex(tabs, ['type', type]);
  const removeItem = (id: string) => {
    Modal.confirm({
      title: t('downloadCenter.delete.title'),
      content: t('downloadCenter.delete.content'),
      cancelText: t('common.confirmButton'),
      okText: t('common.cancelButton'),
      onCancel: () => {
        // 因为按钮排序问题 取消即确定
        removeDownloadFile(type === TypeEnum.订单导出 ? 'export' : 'print', id).then(() => {
          message.success(t('downloadCenter.delete.success'));
          reset();
        });
      },
      onOk: () => {
        console.log('确定');
      },
    });
  };

  console.log(dataSource, 'dataSource')
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <BreadCrumb data={crumbData} /> */}
      <div className="ispContainer">
        <UserCenterMenu />
        <div className={styles.dlCenter}>
          <CommonBasicHeader title={t('downloadCenter.pageTitle')}></CommonBasicHeader>
          <div className={styles.list} style={{ background: '#fff', padding: '24px' }}>
            {/* <Tab
              style={{ padding: '0' }}
              defaultIndex={defaultTab}
              tabs={tabs}
              rightContent={refreshButton}
              onChange={index => {
                const _type = tabs[index].type as TypeEnum;
                history.replace(`/downloadCenter?type=${_type}`);
                setType(_type);
                reset();
              }}
            /> */}
            <div className={styles.table}>
              {!dataSource.length ? (
                !pageLoad ? (
                  <div className={styles.empty}>
                    <img src={SearchEmpty} alt="" />
                    <div className={styles.title}>{t('downloadCenter.empty')}</div>
                  </div>
                ) : null
              ) : (
                <>
                  <Table
                    dataSource={dataSource}
                    pagination={false}
                    bordered={false}
                    columns={[
                      {
                        title: t('downloadCenter.table.taskId'),
                        dataIndex: 'id',
                        key: 'id',
                        width: 90,
                      },
                      {
                        title: t('downloadCenter.table.documentName'),
                        dataIndex: 'reqFileName',
                        key: 'reqFileName',
                      },
                      {
                        title: t('downloadCenter.table.startTime'),
                        dataIndex: 'createDate',
                        key: 'createDate',
                        render: createDate => {
                          return <div className={styles.time}>{moment(createDate).format('YYYY-MM-DD HH:mm:ss')}</div>;
                        },
                      },
                      {
                        title: '任务名称',
                        dataIndex: 'bizTypeName',
                        key: 'bizTypeName'
                      },
                      {
                        title: t('downloadCenter.table.status'),
                        dataIndex: 'statusName',
                        key: 'statusName',
                        width: 100,
                      },
                      {
                        title: t('common.operation'),
                        dataIndex: 'operation',
                        key: 'operation',
                        width: 160,
                        render: (_, row: any) => {
                          return (
                            <>
                              {(row.status !== 'PROCESSING' && row.status !== "PENDING")  && row.reqFileUrl && (
                                <Button
                                  type="link"
                                  style={{ marginRight: '16px' }}
                                  onClick={() => {
                                    window.open(row.resFileUrl, '_self');
                                    log('jdisp', 'Downloads_download');
                                  }}
                                >
                                  {t('common.download')}
                                </Button>
                              )}
                              {/* {(row.status === 3 || row.status === 4) && (
                                <Button
                                  type="link"
                                  onClick={() => {
                                    removeItem(row.id);
                                    log('jdisp', 'Downloads_delete');
                                  }}
                                >
                                  {t('common.delete')}
                                </Button>
                              )} */}
                            </>
                          );
                        },
                      },
                    ]}
                  ></Table>
               
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                      <PaginationJD
                        current={page}
                        total={total}
                        pageSize={pageSize}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        onChange={page => {
                          setPage(page);
                          setRefresh(true);
                          goTop(false);
                        }}
                        onShowSizeChange={(current, pageSize) => {
                          setPageSize(pageSize);
                          reset();
                        }}
                        showSizeChanger={false}
                      />
                    </div>
                </>
              )}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadCenter;
