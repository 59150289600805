import React, { useState } from 'react';
import cookies from 'react-cookies';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const [isLocation] = useState(cookies.load('stationType')); //本土跨境 0本土
  // const main = useRecoilValue('main');
  const { t } = useTranslation();
  // if (!main?.payments) {
  //   return null;
  // }
  const basicInfo = useRecoilValue('basicInfo');
  return (
    <div className={styles.m}>
      {(basicInfo?.lowestBuy == 1) ? null : (
        <div
          className={styles.m_title}
          style={{ position: 'absolute', width: '100px', textAlign: 'center', color: '#d64242', paddingLeft: '9px' }}
        >
            {t('SkuDetailV2.lowestBuy').replace('%n%',basicInfo?.lowestBuy)}
        {/*    起购这个一定大于1*/}
        </div>
      )}
         {/*提醒: 采购申请将由您的专属客户经理审核*/}
      {/* <div className={styles.m_title} style={{ position: 'absolute', marginLeft: '110px' }}>
        {t('SkuDetailV2.remind')}
      </div> */}
    </div>
  );
};

export default Index;
