import { atom } from 'recoil';

import Others from '../types/others';

const others = atom<Others | undefined>({
  key: 'othersCable',
  default: undefined,
});

export default others;
