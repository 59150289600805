/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-07-16 16:05:57
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-20 20:51:09
 * @FilePath: /isp_International/src/pages/OrderDetailNew/components/Goods/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';
import { message, Button, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { dispatchShopCarNum } from '@/redux/action/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { formatMoney, cnyMoney } from '@/util/index';
import styles from './index.module.scss';
import { addProducts } from '@api/newApi/orderdetail';
import { formatOrderDetail } from '@/util/formatServiceStuff';
import ServiceStuff from '@/components/ServiceStuff';
import cookies from 'react-cookies';


interface IAppProps {
  goodsList: any;
  hasSplitFreight: boolean;
  serviceSkus: any;
}
const Goods: React.FC<IAppProps> = (props: any) => {
  const { t } = useTranslation();
  const { goodsList } = props;
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土

  const store: any = useSelector(state => {
    return state;
  });

   // 获取用户操作权限
   const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });
  const pin = store?.User?.data[0]?.pin ?? '';
  const dispatch = useDispatch();

  const formatGoodsList = formatOrderDetail(goodsList);

  //加入购物
  const addCarBe = async (num: any, item: any) => {
    if (num === 1) {
      const result: any = await addProducts([
        {
          sku: item.sku,
          num: 1,
          price: item.price,
        },
      ]);
      if (result.success == true) {
        message.success(t('orderDetail.addedSuccessfully'));
        dispatch(dispatchShopCarNum(result.value.totalKindCount));
      } else {
        message.error(result.msg);
      }
    } else {
      message.info(t('carts.notForSale'));
    }
  };



  return (
    <div className={styles.goodsList}>
      <div className={styles.title}>{t('orderDetail.itemList')}</div>
      <div className={styles.boxs}>
        <ul className={`${styles.gList} ${styles.top}`}>
          <li className={`${styles.a} ${styles.item}`}>{t('orderDetail.itemDetails')}</li>
          {/* <li className={`${styles.b} ${styles.item}`}>{t('orderDetail.specification')}</li> */}
          <li className={`${styles.c} ${styles.item}`}>{t('orderDetail.price')}</li>
          {isLocalPro && <li className={`${styles.c} ${styles.item}`}>{t('orderList.table_title.6')}</li>}
          <li className={`${styles.g} ${styles.item}`}>{t('orderDetail.quantity')}</li>
         {userLimit?.limitData?.enableMaterial == 1 && <li className={`${styles.e} ${styles.item}`}>{t('materialCode.materialCode')}</li>}
          <li className={`${styles.d} ${styles.item}`}>{t('orderDetail.unit')}</li>
          <li className={`${styles.e} ${styles.item}`}>{t('orderDetail.subtotal')}</li>
          <li className={`${styles.f} ${styles.item}`}>{t('orderDetail.operation')}</li>
        </ul>
        <div className={styles.goodsInfo} style={{ borderBottom: ' 1px solid #e1e1e1' }}>
          {formatGoodsList?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <ul className={`${styles.gList} ${styles.bottom}`}>
                  <li className={`${styles.a} ${styles.item}`}>
                    <div className={styles.top}>
                      <img src={item.skuImg} className={styles.img} />
                      <div className={styles.right}>
                        <a
                          className={styles.name}
                          href={`/sku/${item.sku}`}
                          title={item.name}
                          onClick={e => {
                            if (!item.sku) {
                              e.preventDefault();
                              message.error(`${t('orderDetail.good')}${item.thirdSkuId}${t('orderDetail.notInPool')}`);
                            }
                          }}
                        >
                          {`${item?.sourceCountryCode == 'CN' ? t('SkuDetailV2.crossTip') : ''}${item.skuName}`}
                        </a>
                        <p className={styles.goodsNum}>
                          <em className={styles.text}>{t('orderDetail.productId')}</em>
                          <em className={styles.b} title={item.sku} style={{width:'unset'}}>
                            {item.sku}
                          </em>
                          <CopyToClipboard
                            text={item.sku}
                            onCopy={() => {
                              message.success(t('orderDetail.copiedSuccessfully'));
                            }}
                          >
                            <em className={styles.copy}></em>
                          </CopyToClipboard>
                        </p>
                      </div>
                    </div>
                    <div className={styles.bottom}>
                      <ServiceStuff data={item?.gift} />
                    </div>
                  </li>
                  {/* <li className={`${styles.b} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s}`} title={item.specifications}>
                      {item.specifications}
                    </p>
                  </li> */}
                  <li className={`${styles.c} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s} ${styles.b}`}>
                      <div> {isLocalPro && t('OrderInfo.untaxedPrice')} {item.symbol + cnyMoney(item.sellingPrice, false)}</div>
                      {isLocalPro && <div>{t('OrderInfo.includeTaxedPrice')} {item?.symbol + formatMoney(item?.taxSalePrice, false)}</div>}
                      {isLocalPro &&  <div>{t('OrderInfo.taxrate')} {item?.valueAddedTaxRate ? (item?.valueAddedTaxRate*100 + '%')  : 0}</div>}
                    </p>
                  </li>
                  {isLocalPro && <li className={`${styles.c} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s} ${styles.c}`}>{isLocalPro && item?.customsClearance == 1 ? t('orderList.yes') : item?.customsClearance == 0 ? t('orderList.no') : '-'}</p>
                  </li>}
                  <li className={`${styles.g} ${styles.item}`}>
                    <p className={styles.price}>
                      {item.skuNum}
                    </p>
                  </li>
                  
                 {userLimit?.limitData?.enableMaterial == 1 && <li className={`${styles.e} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s} ${styles.b}`}>
                      {item?.mkuMaterial?.materialId || '-'}
                    </p>
                  </li>}
                  <li className={`${styles.d} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s} ${styles.b}`}>{item?.saleUnit}</p>
                  </li>
                  <li className={`${styles.e} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s} ${styles.b}`}>
                      <div style={{
                        "whiteSpace": "pre-wrap"
                      }}> {isLocalPro && t('OrderInfo.untaxedPrice')}{item.symbol + cnyMoney(item.untaxedTotalPrice, false)}</div>
                      {isLocalPro && <div style={{
                        "whiteSpace": "pre-wrap"
                      }}> {t('OrderInfo.includeTaxedPrice')}:{item.symbol + cnyMoney(item.totalPrice, false)}</div>}
                    </p>
                  </li>
                  <li className={`${styles.f} ${styles.item}`}>
                    <Button
                      type="primary"
                      onClick={() => {
                        addCarBe(1, item);
                      }}
                    >
                      {t('orderDetail.addToCart')}
                    </Button>
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Goods;
