/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-13 16:28:16
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-02-01 21:23:46
 * @FilePath: /isp_International/src/pages/SearchList/components/Main/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';
import cookies from 'react-cookies';
import Empty from '@/components/common/Empty';
import Major from '../Major';

import styled from './index.module.scss';
import { getQueryVariable } from '@/util';
import GoodsList from '../GoodsList';
import { Affix, Space, Spin } from 'antd';
import { useSelector } from 'react-redux';

export interface IAppProps {
  changeGoodData: any;
  chooseGoodIndex: any;
  chooseGoodItem: any;
  isEmpty: boolean;
  extAttrList: any;
  pageInfo: any;
  chooseGood: Function;
  updataData: Function;
  addCarBe: Function;
  loading?: boolean;
}

const Main: React.FC<IAppProps> = (props: any) => {
  const {
    changeGoodData,
    chooseGoodIndex,
    chooseGoodItem,
    isEmpty,
    extAttrList,
    pageInfo,
    chooseGood,
    updataData,
    addCarBe,
    loading,
  } = props;
  const [isLocalStation] = useState(cookies.load('stationType'));// 是否本地化站点
  const red_goods_data: any = useSelector((state: any) => {
    return state.SearchList;
  });
  const { brandRole, params, brandList, selectParams } = red_goods_data;
  // 滚动吸顶
  const showFixTitle = (affixed: any) => {
    // doing
  };
  return (
    <div className={`${styled.larger_img}`}>
      {!loading && (
        <>
          {changeGoodData.length <= 0 &&
            (params?.extAttrList ? params?.extAttrList?.length <= 0 : true) &&
            (params?.brandRole ? params?.brandRole?.length <= 0 : true) && (
              <Empty content="暂无相关信息" type="noResult" />
            )}
          {changeGoodData.length > 0 && (
            <>
              {/* <Affix offsetTop={60} onChange={affixed => showFixTitle(affixed)}>
                <Major attrList={extAttrList} changeGoodData={changeGoodData} updataData={updataData} />
              </Affix> */}
              
              <GoodsList
                changeGoodData={changeGoodData}
                pageInfo={pageInfo}
                updataData={updataData}
                chooseGood={chooseGood}
                chooseGoodItem={chooseGoodItem}
                chooseGoodIndex={chooseGoodIndex}
                addCarBe={addCarBe}
                isWideScreen={Object.keys(extAttrList).length > 0 ? false : true}
                searchedWord={params?.key ? params?.key.trim() : ''}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Main;
