import { atom } from 'recoil';

import SimilarProduct from '../types/similarProduct';

const similarProduct = atom<SimilarProduct | undefined>({
  key: 'similarProduct',
  default: undefined,
});

export default similarProduct;
