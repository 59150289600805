/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2023-12-04 15:09:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-02-22 17:34:58
 * @FilePath: /isp_International/src/redux/reducer/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable prefer-rest-params */
import { combineReducers } from 'redux';
import Application from './application';
import User from './user';
import SearchList from './searchList';
import SkuDetail from './SkuDetail';
import Approval from './approval';
import UserLimit from './userLimit';

const reducer = combineReducers({
  Application: Application,
  User: User,
  SearchList,
  SkuDetail,
  Approval,
  UserLimit
});

export default reducer;
