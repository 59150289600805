/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-02-05 11:44:33
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-12 10:17:03
 * @FilePath: /isp_International/src/pages/OrderInfo/atoms/transfee.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { atom } from 'recoil';

const transfee = atom({
  key: 'transfee',
  default: '0'
});

export default transfee;
