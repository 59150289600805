/*
 * @Author: duchongchong1
 * @Date: 2022-11-01 21:42:34
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-18 18:09:42
 * @FilePath: /isp_International/src/layout/BaseLayout.tsx
 * @Description:
 */
import React, { useEffect } from 'react';
import { ConfigProvider, message } from 'antd';
import locale from 'antd/lib/locale/zh_CN';
import NewFooter from '@/components/NewFooter';
import Menu from '@/components/NavBar';

const _top = (document.body.clientHeight - 38) / 2;
message.config({
  duration: 2,
  maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
  top: _top, // 到页面顶部距离
});
const BaseLayout = (props: any) => {
  const { children } = props;
  return (
    <ConfigProvider locale={locale}>
      <div className="ispWrapper">
        <Menu {...props}></Menu>
        <div style={{ width: '100%', position: 'relative', overflowX: 'hidden', flex:'auto' }}>{children}</div>
        <NewFooter />
      </div>
    </ConfigProvider>
  );
};

export default BaseLayout;
