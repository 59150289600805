/*
 * @Author: your name
 * @Date: 2021-11-01 16:02:56
 * @LastEditTime: 2021-11-01 16:04:12
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /pc-iop/src/pages/OtherCarts/api.ts
 */
/* eslint-disable prettier/prettier */
import { objectToParams } from "./utils";
import { Toast } from "rc-toast";
import { apiLocationUrl } from '@/enum/config';

const domain = `https://${apiLocationUrl}`;

async function api(name: any, params: any, method = "GET") {
  let api = `${domain}${name}`;
  if (params && method !== "POST") {
    api = `${api}?${objectToParams(params)}`;
  }
  const res = await fetch(
    api,
    method === "GET"
      ? { credentials: "include" }
      : {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      },
  );
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  const json = await res.json();
  const { success, value, msg } = json;
  if (success) {
    return value;
  } else {
    Toast.show({ msg: msg });
  }
}

export default api;
