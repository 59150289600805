import style from './index.module.scss';
import PIC_ENMU from '@/pages/Login/enum';
import React from 'react';

export default () => {
  return (
    <div className={`${style.header_addixed_title}`} style={{ height: '70px' }}>
      <div className={`${style.title_box}`}>
        <div className={`${style.title_box_name}`}>
          <img src={PIC_ENMU.banner.banner_big_title_blue} />
        </div>
        <div className={`${style.title_box_more}`}>
          <div className={`${style.title_box_more_scancode}`}>
            <img src={PIC_ENMU.banner.banner_title_black} />
            京东工采APP
            <div className={`${style.scancode_QRcode}`}>
              <img src={PIC_ENMU.common.QRcode} />
            </div>
          </div>
          <div className={`${style.title_box_more_telephone}`}>
            <img src={PIC_ENMU.banner.banner_title_telephone} />
            400-618-8998
          </div>
        </div>
      </div>
    </div>
  )
}