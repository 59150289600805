import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import moment from 'moment';
import { viewFile } from '@/api/newApi/approvalList';
import { formatMoney, cnyMoney } from '@/util/index';
import { apiJdigUrl } from '@/enum/config';
import { orderTime } from '@/components/Order';
import FileHtml from './FileHtml';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
interface IAppProps {
  orderData: any;
  type: string;
  isShowTip?: any;
}
const ApproveFile: React.FC<IAppProps> = (props: any) => {
  const { t } = useTranslation();
  const { orderData, type } = props;
  const [fileList, setFileList] = useState<Array<object>>([]); //  上传审批文件弹窗
  const approve = (
    <div className={styles.top}>
      <div className={styles.row}>
        <p className={styles.text}>{t('approvalList.approvalDetail.info.0')}</p>
        <p className={styles.val}>{orderData.orderPin}</p>
      </div>
      <div className={styles.row}>
        <h3 className={styles.text}>{t('approvalList.approvalDetail.info.1')}</h3>
        <p className={styles.val}>{orderData.id}</p>
      </div>
      <div className={styles.row} style={{ width: '30%' }}>
        <p className={styles.text}>{t('approvalList.approvalDetail.info.2')}</p>
        <p className={styles.val}>{moment(orderTime(orderData)).format('YYYY/MM/DD HH:mm:ss')}</p>
      </div>
      <div className={styles.row} style={{ width: '20%' }}>
        <p className={styles.text}>{t('approvalList.approvalDetail.info.3')}</p>
        <p className={styles.val}>{cnyMoney(orderData.orderNeedMoney)}</p>
      </div>
    </div>
  );

  const viewFileFn = () => {
    const getData = async () => {
      const result: any = (await viewFile({ orderId: orderData.id })) as any;
      if (result.success) {
        setFileList(result.value || []);
      } else {
        message.error(result.msg);
      }
    };
    getData();
  };
  useEffect(() => {
    viewFileFn();
  }, [orderData.id]);
  //上传文件
  const beforeUpload = (file: any) => {
    const isLt5M = file.size / 1024 / 1024 > 5;
    if (isLt5M) {
      message.error(t('approvalList.approvalDetail.tips1'));
      return Upload.LIST_IGNORE;
    }
    if (fileList.length >= 6) {
      message.error(t('approvalList.approvalDetail.tips2'));
      return Upload.LIST_IGNORE;
    }
  };
  const prop = {
    name: 'file',
    action: 'https://' + apiJdigUrl + '/jdig/orderApproval/addApprovalFile',
    headers: {
      authorization: 'authorization-text',
      encrypt: 0,
    },

    onChange(info: any) {
      if (info.file.status === 'done') {
        info.fileList.map((item: any, index: number) => {
          if (info.fileList.length == index + 1) {
            if (item.response.success) {
              message.success(t('approvalList.approvalDetail.tips3'));
              const _fileList = [...fileList];
              const val = item.response.value;
              _fileList.unshift(val);
              setFileList(_fileList);
            } else {
              message.error(item.response.msg || t('approvalList.approvalDetail.tips4'));
            }
          }
        });
      }
    },
  };
  const _data = {
    jdOrderId: orderData.id,
  };
  return (
    <div>
      <div className={styles.fileModel}>
        {approve}
        {/* <div className={styles.bottom}>
          <p className={styles.left}>{t('approvalList.approvalDetail.approveFiles')}</p>
          <div className={styles.right}>
            {type == 'approve' && (
              <div className={styles.content}>
                <Upload
                  {...prop}
                  accept="docx、xlsx、pdf、jpg、png、bmp、gif"
                  maxCount={6}
                  withCredentials={true}
                  beforeUpload={beforeUpload}
                  data={_data}
                  showUploadList={false}
                >
                  <button className={styles.uploadBtn}>{t('approvalList.approvalDetail.uploadFiles')}({fileList.length}/6)</button>
                </Upload>
                <div className={styles.warnBox}>
                  <p>
                    {t('approvalList.approvalDetail.uploaDes1')}
                  </p>
                  <p>
                    {t('approvalList.approvalDetail.uploaDes2')}
                  </p>
                </div>
              </div>
            )}

            <FileHtml
              isShowDelete={type == 'seeFile' ? false : true}
              fileList={fileList}
              orderId={orderData.id}
              setFileList={setFileList}
              isShow={true}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ApproveFile;
