import React, { useEffect, useState } from 'react';
import { Button, Row, Form, message } from 'antd';
import BreadCrumb from '@/components/BreadCrumb';

import styles from './index.module.scss';

import BaseInfo from './components/BaseInfo';
import BuyAccount from './components/BuyAccount';
import Audit from './components/Audit';
import { omit } from 'lodash';
// import { createProcess, updateProcess, getProcessDetail } from '@/api/processList';
import { createProcess, updateProcess, getProcessDetail } from '@/api/newApi/personalCenter/processList';
import { useHistory } from 'react-router-dom';
import { goTop } from '@/util';
import { useTranslation } from 'react-i18next';

function chineseNumber(num: number) {
  return ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'][num - 1];
}
function EnglishNumber(num: number) {
  return ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Fixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth'][num - 1];
}

const EditProcess: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [auditType, setAuditType] = useState<number>(1);
  const [selectedBuyAccounts, setSelectedBuyAccounts] = useState<any>([]);
  const [selectedInvestigateAccounts, setSelectedInvestigateAccounts] = useState<any>([]);
  const [nodeList, setNodeList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [baseForm] = Form.useForm();
  const [auditForm] = Form.useForm();

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const editing = !!id;

  const getData = () => {
    getProcessDetail({ processKey: id }).then((res: any) => {
      const processDetail = res.value;
      setAuditType(processDetail.processType);
      baseForm.setFieldsValue({ name: processDetail.processName });
      setSelectedBuyAccounts(processDetail.purchasePins);
      setSelectedInvestigateAccounts(processDetail.examPins);

      const nList = processDetail.approveNodeList.map((n: any) => ({
        name: n.nodeName,
        condition: n.amount,
        pins: n.approvePins,
      }));
      setNodeList(nList);
      nList.forEach((n: any, index: number) => {
        auditForm.setFieldsValue({
          ['condition' + index]: n.condition,
          ['pins' + index]: n.pins.join('; '),
        });
      });
    });
  };

  useEffect(() => {
    if (editing) {
      getData();
    }
  }, []);

  const submit = (name: string) => {
    goTop(true);
    setLoading(true);
    if (editing) {
      updateProcess({
        operatorType: 2,
        process: JSON.stringify({
          processKey: id,
          processName: name,
          processType: auditType,
          purchaseAccount: selectedBuyAccounts,
          apvNodeAllList: nodeList.map((n: any, index: number) => ({
            nodeName: n.name,
            amount: n.condition,
            approvePins: n.pins,
            sort: index + 1,
          })),
          examPins: selectedInvestigateAccounts,
        }),
      }).then(
        () => {
          history.push('/processList');
        },
        () => {
          setLoading(false);
        },
      );
    } else {
      createProcess({
        operatorType: 1,
        process: JSON.stringify({
          processName: name,
          processType: auditType,
          purchasePins: selectedBuyAccounts,
          approveNodeList: nodeList.map((n: any, index: number) => ({
            nodeName: n.name,
            amount: n.condition,
            approvePins: n.pins,
            sort: index + 1,
          })),
          examPins: selectedInvestigateAccounts,
        }),
      }).then(
        () => {
          history.push('/processList');
        },
        () => {
          setLoading(false);
        },
      );
    }
  };

  const action = (
    <Row className={styles.action}>
      <Button
        type="primary"
        style={{ height: '46px', fontSize: '18px' }}
        loading={loading}
        onClick={() => {
          baseForm
            .validateFields()
            .then(values => {
              if (selectedBuyAccounts.length === 0) {
                message.error(t('process.detail.message.0'));
                document.getElementById('buyAccount')?.scrollIntoView({
                  behavior: 'smooth',
                });
              } else {
                if (nodeList.length === 0) {
                  message.error(t('process.detail.message.1'));
                  document.getElementById('audit')?.scrollIntoView({
                    behavior: 'smooth',
                  });
                  return;
                }
                auditForm
                  .validateFields()
                  .then(() => {
                    submit(values.name);
                  })
                  .catch(e => {
                    if (e.errorFields.length === 0) {
                      submit(values.name);
                    } else {
                      document.getElementById('audit')?.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  });
              }
            })
            .catch(() => {
              document.getElementById('baseInfo')?.scrollIntoView({
                behavior: 'smooth',
              });
            });
          if (editing) {
            log('jdisp', 'EditApprove_Save');
          } else {
            log('jdisp', 'CreateApprove_Save');
          }
        }}
      >
        {t('process.detail.btn')}
      </Button>
    </Row>
  );

  const crumbData = [
    {
      label: t('process.detail.bread.0'),
      url: '/accountSettings',
    },
    {
      label: t('process.detail.bread.1'),
      url: '/processList',
    },
    {
      label: editing ? t('process.detail.bread.2') : t('process.detail.bread.3'),
    },
  ];

  return (
    <div>
      <BreadCrumb data={crumbData} />
      <div className={styles.container}>
        {
          <BaseInfo
            editing={editing}
            form={baseForm}
            auditType={auditType}
            onTypeChange={(type: number) => {
              setAuditType(type);
              if (type === 2) {
                setNodeList((_nodeList: any[]) => _nodeList.map((n: any) => omit(n, ['condition'])));
                nodeList.forEach((_n: any, index: number) => {
                  auditForm.setFieldsValue({ ['condition' + index]: undefined });
                });
              }
            }}
          />
        }
        {
          <BuyAccount
            processKey={id}
            selectedAccounts={selectedBuyAccounts}
            onAccountSelect={(pins: string[]) => {
              setSelectedBuyAccounts(pins);
            }}
          />
        }
        {
          <Audit
            editing={editing}
            auditType={auditType}
            form={auditForm}
            nodeList={nodeList}
            addNode={() => {
              setNodeList((_nodeList: any) => [
                ..._nodeList,
                {
                  name:
                    i18n.language == 'zh'
                      ? `${chineseNumber(_nodeList.length + 1)}级审批`
                      : `${EnglishNumber(_nodeList.length + 1)}-Level`,
                },
              ]);
              auditForm.setFieldsValue({
                ['condition' + nodeList.length]: undefined,
                ['pins' + nodeList.length]: undefined,
              });
              if (editing) {
                log('jdisp', 'EditApprove_NewNode');
              } else {
                log('jdisp', 'CreateApprove_NewNode');
              }
            }}
            removeNode={() => {
              setNodeList((_nodeList: any) => _nodeList.slice(0, _nodeList.length - 1));
              if (editing) {
                log('jdisp', 'EditApprove_DeleteNode');
              } else {
                log('jdisp', 'CreateApprove_DeleteNode');
              }
            }}
            onChangeNode={(node, index) => {
              setNodeList((_nodeList: any) => {
                _nodeList[index] = node;
                return _nodeList;
              });
            }}
          />
        }
        {action}
      </div>
    </div>
  );
};

export default EditProcess;
