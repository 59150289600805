/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-01-02 22:02:39
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-01-03 15:00:09
 * @FilePath: /isp_International/src/pages/SkuDetailV2/components/Main/Summary/Basic/localSimilarPro/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cookies from 'react-cookies';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import styles from './index.module.scss';

const Index = () => {
    const basicInfo = useRecoilValue('basicInfo');
    const similarProducts = useRecoilValue('similarProduct');
    const skuId = basicInfo?.skuId;
    const [products, setProducts] = useState([]);
    useEffect(()=>{
        if(similarProducts){
            setProducts(similarProducts?.members || []);
        }
       
    }, [similarProducts])
    return <div className={styles.container}>
            {
                products?.map((ele, idx)=><div className={`${styles.item} ${skuId == ele?.mkuId && styles.selected}`} key={idx} onClick={()=>{
                    window.location.href = `${window.location.origin}/sku/${ele?.mkuId}`;
                }}>
                    <img src={ele.mainImg} alt="" />
                    <span>{ele.name}</span>
                </div>)
            }
    </div>
}

export default Index;

