/*
 * @Author: weiyanna
 * @Date: 2021-09-17 11:43:33
 * @LastEditTime: 2024-06-27 17:00:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_International/src/components/common/Empty/index.tsx
 */
import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '@/components/common/Icon';
// import EmptyImg from '@/assets/images/empty/empty.png';
import EmptyImg from '@/assets/images/accountSettings/icon_search_empty.png';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

interface IAppProps {
  img?: string;
  content?: string;
  width?: string;
  height?: string;
  buttonLink?: boolean;
  hiddenImg?: boolean;
  type?: string;
}

const Empty: React.FC<IAppProps> = (props: any) => {
  const { t } = useTranslation();
  const { img = EmptyImg, height = '334px', type } = props;

  const global_data: any = useSelector(state => {
    return state;
  });

  return (
    <div
      className={styles.empty}
      style={{ height: height, minHeight: `calc(100vh - ${type !== 'noResult' ? '550px' : '290px'})` }}
    >
      <Icon icon={img} width="120px" height="120px" />

      {type !== 'reset' ? (
        <>
          <p className={styles.txt}>{t('searchList.noContent')}</p>

          <div className={`${styles.no_data}`}>
            <div
              className={`${styles.title}`}
              // onClick={() => {
              //   window.open(global_data.User.customerUrl, '_blank');
              // }}
            >
              {t('searchList.empty')}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`${styles.no_data}`}>
            <div
              className={`${styles.title}`}
              // onClick={() => {
              //   window.open(global_data.User.customerUrl, '_blank');
              // }}
            >
              {t('searchList.empty')}
            </div>
            <p className={`${styles.or}`}>or</p>
            <span className={`${styles.button}`}>Reset Filters</span>
          </div>
        </>
      )}
    </div>
  );
};
export default Empty;
