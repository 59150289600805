import React, { useCallback, useState } from 'react';
import { message, Modal, Tooltip } from 'antd';
// import { CheckCircleTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// import { submitApply } from '@/api/BatchOrder';
// import { addCarMku, addBuyMku, addCarBefore } from '@/api/SkuDetail';
// import { getCookie } from '@/util';
import Replenishment from '@/components/Replenishment/index';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import { isGuest } from '@/util/commonUtils';
import cookies from 'react-cookies';
// import { log } from '@/pages/SkuDetailCable/utils';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { addCarMku2, addBuyMku2, addCarBefore2 } from '@/api/newApi/skudetail';

const Index = () => {
  const [isLocalPro] = useState(cookies.load('stationType') == '0');
  const [isShow, setIsShow] = useState(false); //展示
  const checkedService = useSelector((state: any) => {
    return state?.SkuDetail.checkedService;
  });
  // const userInfo = useSelector((state: any) => {
  //   return state?.User;
  // });
  const amount = useRecoilValue('amount');
  // const main = useRecoilValue('main');

  const basicInfo = useRecoilValue('basicInfo');
  const { t } = useTranslation();
  //加入采购清单
  const addCart = useCallback((skuId: number, num: number) => {
    addCarMku2({
      skuId,
      num,
      purchaseListId: 1,
    }).then((res: any) => {
      if (res.success == true) {
        // message.success('加入采购清单成功');
        message.success(`${t('SkuDetailV2.addSuccessfully')}`);
        // window.setTimeout(function() {
        //   window.location.href = '/cart/carts';
        // }, 100);
      } else {
        message.warning(res.msg);
      }
    });
  }, []);

  //
  //加入采购清单
  const addCart2 = useCallback((sku: number, num: number, skuPrice?: number) => {
    addCarMku2([
      {
        sku,
        num,
        skuPrice,
      },
    ]).then((res: any) => {
      if (res.success == true) {
        // message.success('加入采购清单成功');
        message.success(`${t('SkuDetailV2.addSuccessfully')}`);
        window.setTimeout(function() {
          window.location.href = '/cart/carts';
        }, 100);
      } else {
        message.warning(res.msg);
      }
    });
  }, []);
  //

  //立即购买
  // const addBuy = useCallback(async (skuId: number, num: number, service?: any[]) => {
  //   const params: Record<string, any> = {
  //     skuId,
  //     num,
  //   };
  //   if (service && service.length > 0) {
  //     params.settleParams = JSON.stringify(service);
  //   }
  //   const result: any = await addBuyMku2(params);
  //   if (result.success == true) {
  //     window.setTimeout(function() {
  //       window.location.href = '/order/goOrderInfo?tradeModel=2'; //跳转结算
  //     }, 100);
  //   } else {
  //     message.warning(result.msg);
  //   }
  // }, []);

  //
  //立即购买
  const addBuy2 = useCallback(async (sku: number, num: number, skuPrice?: number) => {
    const params: Record<string, any> = {
      sku,
      num,
      skuPrice,
    };
    const result: any = await addBuyMku2(params);
    if (result.success == true) {
      window.setTimeout(function() {
        window.location.href = '/order/goOrderInfo?tradeModel=2'; //跳转结算
      }, 100);
    } else {
      message.warning(result.msg);
    }
  }, []);
  //
  //加入购物之前需要验证商品是否可售
  // const addCarBe = useCallback(
  //   async (type: number) => {
  //     const mkuIds = main?.mkuId;
  //     if (!mkuIds || !amount) {
  //       return;
  //     }
  //     const result: any = await addCarBefore({
  //       mkuIds,
  //     });
  //     if (result.success == true && result.value && result.value.saleState) {
  //       // const skuId = main?.skuId;
  //       if (type == 1) {
  //         addCart(mkuIds, amount);
  //         // log('isp_info', 'add_cart', skuId, getCookie('pin'), 'skuDetail');
  //       } else {
  //         addBuy(mkuIds, amount, checkedService);
  //         // log('isp_info', 'to_buy', skuId, getCookie('pin'), 'skuDetail');
  //       }
  //     } else if (result.success == true && result.value && !result.value.saleState) {
  //       message.warning(result.value.saleMessage);
  //     } else {
  //       message.warning(result.msg);
  //     }
  //   },
  //   [main?.mkuId, main?.skuId, amount, checkedService],
  // );

  //
  // const addCarBe2 = useCallback(
  //   async (type: number) => {
  //     // const mkuIds = main?.mkuId;
  //     const skuIds = basicInfo?.skuId;
  //     if (!skuIds || !amount) {
  //       return;
  //     }
  //     // 判断能否购买
  //     const result: any = await addCarBefore2({
  //       skuIds,
  //     });
  //     if (result.success == true && result.value && result.value.saleState) {
  //       // const skuId = basicInfo?.skuId;
  //       if (type == 1) {
  //         addCart(skuIds, amount);
  //         // log('isp_info', 'add_cart', skuId, getCookie('pin'), 'skuDetail');
  //       } else {
  //         addBuy(skuIds, amount, checkedService);
  //         // log('isp_info', 'to_buy', skuId, getCookie('pin'), 'skuDetail');
  //       }
  //     } else if (result.success == true && result.value && !result.value.saleState) {
  //       message.warning(result.value.saleMessage);
  //     } else {
  //       message.warning(result.msg);
  //     }
  //   },
  //   [basicInfo?.skuId, amount, checkedService],
  // );
  //
  //
  const addCarBe2 = useCallback(
    async (type: number) => {
      // const mkuIds = main?.mkuId;
      const sku = basicInfo?.skuId;
      const skuPrice = basicInfo?.showCurrency?.salePrice;
      if (!sku || !amount) {
        return;
      }
      // const result = JSON.parse(basicInfo?.saleState);
      if (basicInfo?.skuSaleState?.saleState == 1) {
        if (type == 1) {
          addCart2(sku, amount, skuPrice);
          // log('isp_info', 'add_cart', skuId, getCookie('pin'), 'skuDetail');
        } else {
          addBuy2(sku, amount, skuPrice);
          // log('isp_info', 'to_buy', skuId, getCookie('pin'), 'skuDetail');
        }
      } else {
        message.warning(basicInfo?.skuSaleState?.notAllowAttrLimitList?.message);
      }
      // // 判断能否购买
      // const result: any = await addCarBefore2({
      //   skuIds,
      // });
    },
    [basicInfo?.skuId, amount, checkedService],
  );
  //

  // const PoolConfirm = (applyId: any) => {
  //   Modal.confirm({
  //     title: '入池申请已提交',
  //     icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  //     content: (
  //       <p>
  //         申请单已提交，可进入
  //         <a href={`/rede/entryPool?applyId=${applyId}`} target="_blank" rel="noreferrer">
  //           &quot;入池申请页面&quot;
  //         </a>
  //         查看申请进度
  //       </p>
  //     ),
  //     closable: true,
  //     okText: '去入池申请页面',
  //     cancelText: '继续采购',
  //     onOk: () => {
  //       window.open('/rede/entryPool?applyId=' + applyId, '_blank');
  //     },
  //   });
  // };
  // const applyPoolFun = async (skuDetail: any, amountNum: number) => {
  //   const detailReqList = [
  //     {
  //       productCode: skuDetail.skuId,
  //       orderNum: amountNum,
  //     },
  //   ];
  //   const res: any = await submitApply(detailReqList);
  //   if (res.success) {
  //     PoolConfirm(res.value);
  //   } else {
  //     message.error(res.msg);
  //   }
  // };
  return (
    <div className={styles.m}>
      <div>
        {/*{!(*/}
        {/*  main?.disableAddToCar ||*/}
        {/*  (checkedService && checkedService?.length > 0) ||*/}
        {/*  (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) && userInfo?.data[0]?.hadJicai)*/}
        {/*) && (*/}
        {/*  <button*/}
        {/*    className={`${styles.add} ${(main?.disableAddToCar ||*/}
        {/*      main?.stockState?.state === 34 ||*/}
        {/*      (checkedService && checkedService?.length > 0) ||*/}
        {/*      (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) &&*/}
        {/*        userInfo?.data[0]?.hadJicai)) &&*/}
        {/*      styles.button_disabled}`}*/}
        {/*    disabled={*/}
        {/*      main?.disableAddToCar ||*/}
        {/*      (checkedService && checkedService?.length > 0) ||*/}
        {/*      main?.stockState?.state === 34 ||*/}
        {/*      (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) && userInfo?.data[0]?.hadJicai)*/}
        {/*    }*/}
        {/*    onClick={() => addCarBe(1)}*/}
        {/*  >*/}
        {/*    /!*加入采购清单*!/*/}
        {/*    {t('SkuDetailV2.addCart')}*/}
        {/*  </button>*/}
        {/*)}*/}

        {/*跨境库存为0 不展示加入购物车*/}
        
        {
          (isLocalPro || (basicInfo?.remainNum !== 0)) &&
          // <Tooltip placement="top" title={t('SkuDetailV2.addCart')}>
            <button
              className={`${styles.add} ${checkedService && checkedService?.length > 0 && styles.button_disabled}`}
              disabled={
                isGuest() ||
                ((basicInfo?.productPriceVo
                  && basicInfo.productPriceVo.price <= 0) || checkedService && checkedService?.length > 0) ||
                (basicInfo?.skuSaleState && basicInfo?.skuSaleState?.saleState == 0) || (basicInfo?.remainNum===0)
              }
              onClick={() => {
                addCarBe2(1);
                log('jdisp', 'Product_AddtoCart');
              }}
            >
              {/*加入采购清单*/}
              {t('SkuDetailV2.addCart')}
            </button>
          // </Tooltip>
        }
        {/**/}

        {/*{!(Number(main?.basicInfo?.isSpecial) === 1000 && userInfo?.data[0]?.isHaveApplyToPool) && (*/}
        {/*  <button*/}
        {/*    className={`${styles.now} ${(main?.disableBuyNow ||*/}
        {/*      (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) &&*/}
        {/*        userInfo?.data[0]?.hadJicai)) &&*/}
        {/*      styles.button_disabled_red}`}*/}
        {/*    disabled={*/}
        {/*      main?.disableBuyNow ||*/}
        {/*      (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) && userInfo?.data[0]?.hadJicai)*/}
        {/*    }*/}
        {/*    onClick={() => addCarBe(2)}*/}
        {/*  >*/}
        {/*    /!*立即购买*!/*/}
        {/*    {t('SkuDetailV2.orderNow')}*/}
        {/*  </button>*/}
        {/*)}*/}

        {/**/}
        {
          <button
            className={`${styles.now}`}
            disabled={isGuest() || (basicInfo?.productPriceVo
              && basicInfo.productPriceVo.price <= 0) || basicInfo?.skuSaleState && basicInfo?.skuSaleState?.saleState == 0 || basicInfo?.remainNum===0}
            onClick={() => {
              addCarBe2(2);
              log('jdisp', 'Product_Placeorder');
            }}
          >
            {/*立即购买*/}
            {t('SkuDetailV2.orderNow')}
          </button>
        }
        {!isLocalPro && basicInfo?.remainNum === 0 && <button className={`${styles.Replenishment}`} onClick={()=>{
          setIsShow(true);
        }}>{t('component.Replenishment.request_restock')}</button>}
        {/**/}

        {/**/}
        {/*{!(Number(main?.basicInfo?.isSpecial) === 1000 && userInfo?.data[0]?.isHaveApplyToPool) && (*/}
        {/*  <button*/}
        {/*    className={`${styles.now} ${(main?.disableBuyNow ||*/}
        {/*      (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) &&*/}
        {/*        userInfo?.data[0]?.hadJicai)) &&*/}
        {/*      styles.button_disabled_red}`}*/}
        {/*    disabled={*/}
        {/*      main?.disableBuyNow ||*/}
        {/*      (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) && userInfo?.data[0]?.hadJicai)*/}
        {/*    }*/}
        {/*    onClick={() => addCarBe2(2)}*/}
        {/*  >*/}
        {/*    /!*立即购买*!/*/}
        {/*    {t('SkuDetailV2.orderNow')}*/}
        {/*  </button>*/}
        {/*)}*/}
        {/* */}

        {/*{Number(main?.basicInfo?.isSpecial) === 1000 &&*/}
        {/*  !main?.applyToPoolState &&*/}
        {/*  userInfo?.data[0]?.isHaveApplyToPool && (*/}
        {/*    <button*/}
        {/*      className={`${styles.add}`}*/}
        {/*      disabled={*/}
        {/*        !!(*/}
        {/*          main?.applyToPoolState ||*/}
        {/*          (Number(main?.skuFinalPrice?.price) > Number(main?.skuFinalPrice?.jdPrice) &&*/}
        {/*            userInfo?.data[0]?.hadJicai)*/}
        {/*        )*/}
        {/*      }*/}
        {/*      onClick={() => applyPoolFun(main, amount)}*/}
        {/*    >*/}
        {/*      申请入池*/}
        {/*    </button>*/}
        {/*  )}*/}
        {/*{main?.applyToPoolState && Number(main?.basicInfo?.isSpecial) === 1000 && userInfo?.data[0]?.isHaveApplyToPool && (*/}
        {/*  <div className={styles.button_box}>*/}
        {/*    <button className={`${styles.add} ${styles.greyBtn} ${styles.button_disabled}`}>入池申请中</button>*/}
        {/*    <a href={`/rede/entryPool?applyId=${main?.applyToPoolState}`} target="_blank" rel="noreferrer">*/}
        {/*      查看审批进度*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      {/*<div className={styles.m_promise}>*/}
      {/*  */}
      {/*</div>*/}
      <Replenishment isVisible={isShow} setIsVisible={(isVisble)=>setIsShow(isVisble)} shopInfo={{
          mkuId: basicInfo?.skuId,
          img: basicInfo?.imagePath,
          productName:basicInfo?.productName,
      }}/>
    </div>
  );
};

export default Index;
