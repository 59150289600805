/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-19 11:29:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-19 13:34:40
 * @FilePath: /isp_International/src/redux/reducer/userLimit.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

const INITIAL_LIMIT_STATE = {
   limitData:null
  };

//更新用户权限
const updateUserOperLimit = (state: any, action: any)=>{
    return {
        ...state,
        limitData: action.data,
      };
}

export default function userLimit(state = INITIAL_LIMIT_STATE, action: any) {
    switch (action.type) {
      case 'UPDATE_USER_LIMIT':
        return updateUserOperLimit(state, action);
      default:
        return state;
    }
  }