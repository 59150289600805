/*
 * @Author: duchongchong1
 * @Date: 2022-11-16 15:03:29
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-02-02 10:46:56
 * @FilePath: /isp_International/src/components/UserCenterMenu/index.tsx
 * @Description:
 */
import React, { useEffect, useState, memo } from 'react';
import { dispatchInitMenus } from '@/redux/action/index';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { getMenu } from '@/api/newApi/workbench';
import { useTranslation } from 'react-i18next';

const UserCenterMenu: React.FC = () => {
  const userInitMenu: any = useSelector((state: any) => {
    return state?.User?.initMenus ?? {};
  });
  const { i18n } = useTranslation();
  const [userMenu, setUserMenu] = useState(userInitMenu.value ?? []);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const getUserMenus = async () => {
    // getMenu
    const res: any = await getMenu({ userType: 4 });
    // const res1: any = await getUserInitMenus({});
    if (res.success) {
      const { value = [] } = res;
      console.log('菜单列表:');
      console.log(res)
      setUserMenu(value);
      dispatch(dispatchInitMenus({ value, en: i18n.language }));
    }
  };
  useEffect(() => {
    if (userInitMenu.value?.length > 0 && i18n.language === userInitMenu.en) {
      setUserMenu(userInitMenu.value);
    } else {
      getUserMenus();
    }
  }, [i18n.language]);
  const formatHref = (url: string) => {
    // if (url && url.indexOf('rede') > -1) {
    //   return url.replace('https://isp.jd.com/rede', '');
    // } else {
    return url;
    // }
  };
  const jumpPage = (item: any) => {
    //url为空特殊处理
    if (!item.href) {
      return;
    }
    switch (item.href) {
      case '/accountSettings':
        log('jdisp', 'Account Setting');
        break;
      case '/personalInformation':
        log('jdisp', 'Personal Information');
        break;
      case '/addressList':
        log('jdisp', 'My Address');
        break;
      case '/downloadCenter':
        log('jdisp', 'Downloads');
        break;
      case '/orders':
        log('jdisp', 'Orders');
        break;
      case '/orderList':
        log('jdisp', 'My Orders');
        break;
      case '/approvalList':
        log('jdisp', 'Approval List');
        break;
      case '/orderDetails':
        log('jdisp', 'Order Details');
        break;
      case '/approvalManagement':
        log('jdisp', 'Approval Management');
        break;
      case '/processList':
        log('jdisp', 'Approval Settings');
        break;
      case '/approvalDetails':
        log('jdisp', 'Approval Details');
        break;
    }
    history.replace(formatHref(item.href));
    // if (item?.href?.indexOf('rede') > -1) {
    // } else {
    //   window.open(item?.href, '_self');
    // }
  };
  return (
    <div className={styles.menu}>
      {userMenu &&
        userMenu.map((m: any, index: any) => (
          <div key={m.name + index} className={styles.subMenu}>
            <div className={styles.firstMenu}>{m.name}</div>
            {m.menus &&
              m.menus.map((c: any) => (
                <div
                  key={c.name}
                  className={[styles.secondMenu, c.href == location.pathname ? styles.active : ''].join(' ')}
                  onClick={() => jumpPage(c)}
                >
                  <span clstag={c.clstagName}>
                    {c.name}
                    {c?.href && c.href.indexOf('billReport') > -1 && <em className={styles.new}>NEW</em>}
                  </span>
                </div>
              ))}
          </div>
        ))}
    </div>
  );
};
export default memo(UserCenterMenu);
