/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-05 11:37:39
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-21 09:57:13
 * @FilePath: /isp_International/src/pages/MaterialCode/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';


import SearchForm from './components/SearchForm'
import TableList from './components/TableList'
import styles from './index.module.scss';
import UserCenterMenu from '@/components/UserCenterMenu';
import { Affix, Button, Card, Form, Input, message, Modal, Row, Upload } from 'antd';
import BreadCrumb from '@/components/BreadCrumb';
import { useTranslation } from 'react-i18next';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useRecoilState, useSetRecoilState } from './hooks';

import TableHeader from './components/TableHeader';
import { batchDelMaterialCode, getMaterialCodeList, getMaterialDetail, saveMaterialCode } from '@/api/newApi/materialcode';
import { RecoilRoot } from 'recoil';
import { size } from 'lodash';
import PaginationJD from '@/components/PaginationJD';
import { goTop } from '@/util';
import { PAGE_SIZE_OPTIONS } from '@/enum/config';
import { taskImport } from '@/api/newApi/taskCenter';
import { getJdiOSFile } from '@/util/index'

interface MaterialCodeProps {}
const MaterialCode: React.FC<MaterialCodeProps> = () => {
    const { t, i18n } = useTranslation();
    const [selectedIds, setSelectedIds] = useState([]); //选中的ids
    const [loading, setIsLoading] = useState(false);
    const [isBatchVisible, setIsBatchVisible] = useState(false);//批量弹框新增
    const [showModal, setIsShowModal] = useRecoilState('showModal');
    const [operStatus, setOperStatus] = useRecoilState('operStatus');
    const [selectedId, setSelectedId] = useRecoilState('selectedId');
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_OPTIONS[0]);
    const [page, setPage] = useState<number>(1);
    const [pageLoad, setPageLoad] = useState(false);
    const [refresh, setRefresh] = useState(true);

    // 页面数据总数
    const [total, setTotal] = useState<number>(0);
    const [form] = Form.useForm();
    const [dataList, setDataList] = useState([]);

    const fileTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'application/vnd.ms-excel',
      ]; //接受文件类型
    const maxSize = 10; //文件大小
    const accept = '.xlsx,.csv,.xls'; //接受文件类型

    /**
     * 页面面包屑
    */
    const crumbData = [
        {
          label: t('materialCode.materialCodeManagement'),
        },
    ];
   
    useEffect(()=>{
        getDataList({
            index:1,
            size:pageSize
        });
    }, [])

    useEffect(()=>{
        if(selectedId && showModal){
            getDetail({
                id:selectedId
            })
        }
    },[selectedId, showModal])

    /**
     * 初始化获取物料编码列表
    */
    const getDataList = async(param: any)=>{
      const resData = await getMaterialCodeList({...param,
        index:1,
        size:pageSize
      });
      if(resData?.success){
        setDataList(resData?.value?.records);
        setTotal(resData?.value?.total)
      }
    }

    const reset = () => {
        setPage(1);
        setDataList([]);
        setRefresh(true);
      };


    /**
     * 单个选中
     * param {boolean} isChecked 是否选中
     * param {string} id 选中的id
    */
   const checkSingleHandler = (isChecked:boolean, id:string)=>{
        const newSelectedIds = [...selectedIds];
        if(isChecked){
            newSelectedIds.push(id);
            setSelectedIds(newSelectedIds)
        }else{
            setSelectedIds(newSelectedIds.filter((ele)=>ele != id))
        }
   }  

    /**
     * 全部选中
     * param {boolean} isChecked 是否选中
     * 
    */
   const checkAllHandler = (isChecked:boolean)=>{
    if(isChecked){
        const ids = dataList.map((ele)=>ele.id);
        setSelectedIds(ids);
    }else{
        setSelectedIds([])
    }
   } 
   
//上传文件前置校验
  const beforeUpload = async(file: any) => {
    const isJpgOrPng = fileTypes.indexOf(file.type) > -1;
    if (!isJpgOrPng) {
      message.error(`${t('configManageList.modal.fileFormatError')}${accept}${t('configManageList.modal.file')}`);
      return false;
    }
    const isLt10M = file.size / 1024 / 1024 < maxSize;
    if (!isLt10M) {
      message.error(`${t('configManageList.modal.fileSizeLimit')}${maxSize}MB!`);
      return false;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('importType', '300');

    if (loading) return;
    setIsLoading(true);
    const resData = await taskImport(formData);
    setIsLoading(false);
    if (resData?.success) {
      message.success('上传成功请到任务中心查看下载结果');
      setIsBatchVisible(false);
      return false;
    }
    message.error(resData?.msg);

    return false;
  };

  //上传文件前置校验
  const handleUpload = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setIsLoading(false);
      const response = info.file.response;
      if (response.success) {
        setIsBatchVisible(false)
        // message.success(res);
      } else {
        message.error(response.msg || t('configManageList.modal.uploadFailed'));
      }
    }
  };


const getDetail = async(params: any)=>{
    const resData = await getMaterialDetail({
        id:params?.id,
    });
    if(resData?.success){
        const data = resData?.value;
        form?.setFieldsValue({
            id:data?.id,
            materialId: data?.materialId,
            materialName: data?.materialName,
            mkuId: data?.mkuId
        })
    }else{
        message.error(resData?.msg)
    }

}




  /**
   * 单个新增
  */
  const onFinish = async(values)=>{
    const resData = await saveMaterialCode(values);
    if(resData?.success){
        setIsShowModal(false);
        getDataList({
            index:1,
            size:20
        })
    }else{
        message.error(resData?.msg)
    }
  }
  
    return <div className={styles.materialcode}>
            <div className={'ispContainer'}>
                <div className={styles.materialcode_menu}>
                    <Affix offsetTop={0}>
                    <UserCenterMenu />
                    </Affix>
                </div>
                <div className={styles.materialcode_content}>
                    <BreadCrumb data={crumbData} />
                    <div className={styles.content}>
                        <Card style={{ marginBottom: '10px' }}>
                            <SearchForm getDataList={getDataList}/>
                        </Card>
                        <Card style={{ marginBottom: '10px',padding:'15px 20px' }}>
                            <TableHeader 
                                btnClickHander={async(type:string)=>{
                                    if(type == '1'){
                                        setIsShowModal(true);
                                        setOperStatus('add');
                                    }else if(type == '2'){
                                        setIsBatchVisible(true)
                                    }else if(type == '3'){
                                        const resData = await batchDelMaterialCode({
                                                ids:selectedIds
                                        })
                                        if(resData?.success){
                                            message.success(resData?.msg);
                                            getDataList({
                                                index:1,
                                                size:pageSize
                                            })
                                        }else{
                                            message.error(resData?.msg);
                                        }
                                       
                                    }
                                }}
                                checked={dataList && dataList.length && dataList.length == selectedIds.length}
                                checkAllHandler={(isChecked)=>checkAllHandler(isChecked)}
                            />
                            <TableList 
                                onEdit={()=>{
                                    getDataList({
                                        index:1,
                                        size:pageSize
                                    })
                                }}
                                onDel={()=>{
                                    getDataList({
                                        index:1,
                                        size:pageSize
                                    })
                                }}
                                data={dataList} 
                                selectedIds={selectedIds} 
                                onChange={(isChecked, id)=>{
                                    checkSingleHandler(isChecked, id)
                                }}
                        />
                        </Card>
                         {/* 分页 */}
                        <div style={{ textAlign: 'right', marginTop: '24px' }}>
                            <PaginationJD
                                current={page}
                                total={total}
                                pageSize={pageSize}
                                pageSizeOptions={PAGE_SIZE_OPTIONS}
                                onChange={page => {
                                setPage(page);
                                setRefresh(true);
                                goTop(false);
                                }}
                                onShowSizeChange={(current, pageSize) => {
                                setPageSize(pageSize);
                                reset();
                                }}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* 单个新增物料关系弹框 */}
            <Modal 
                title={selectedId ? t('materialCode.addMaterialProductRelationship'): t('materialCode.addMaterialProductRelationship')} 
                open={showModal}
                onOk={()=>{
                    form?.submit();
                }} 
                onCancel={()=>{
                    setIsShowModal(false);
                    form?.resetFields();
                    setSelectedId(null);
                }
            }>
                <Form 
                    colon={false}
                    form={form}
                    labelCol={{
                        span:5
                    }}
                    wrapperCol={{
                        span:19
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item label={t('materialCode.materialCode')} name="materialId">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('materialCode.materialName')} name="materialName">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('materialCode.jdProductCode')} name="mkuId">
                        <Input />
                    </Form.Item>
                    <Form.Item label="id" name="id" hidden>
                        <Input />
                    </Form.Item>
                    {/* <Form.Item label="京东商品名称">
                        <Input readOnly/>
                    </Form.Item> */}
                </Form>
            </Modal>
            
            {/* 批量新增物料关系弹框 */}
            <Modal 
                title={t('materialCode.batchImportMaterialProductRelationships')} 
                open={isBatchVisible} 
                footer={null}
                onCancel={()=>{
                    setIsBatchVisible(false)
                }}
            >
                <Form 
                    // onFinish={onFinish}
                    labelCol={{
                        span:4
                    }}
                    wrapperCol={{
                        span:20
                    }}
                >
                    <Form.Item label={t('materialCode.materialCode')}>
                        <Upload
                            accept={'.xls,.xlsx'}
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            withCredentials={true}
                            onChange={handleUpload}
                        >
                            <Button
                                className={styles.uploadBtn}
                                icon={<UploadOutlined disabled={loading} style={{ margin: '0 8px -3px 0',fontSize: '16px' }} />}
                            >
                            {t('configManageList.modal.uploadFile')}
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item label={t('materialCode.tip')}>
                    {t('materialCode.pleaseIn')}<a href={ getJdiOSFile('pmethctab1/批量导入物料商品关系.xlsx') }>{t('materialCode.inExcel')}</a>{t('materialCode.fillInExcel')}
                    </Form.Item>
                    </Form>
                {/* <Row>操作： 
                </Row>
                <Row>温馨提示:请在<a>Excel(点击下载)</a>内填写，方便数据导入识别准确无误</Row> */}
            </Modal>
        </div>
}

export default ()=>{
    return <RecoilRoot>
            <MaterialCode/>
         </RecoilRoot>
}

