import http from '../../axios';
export function getProcessList(params: object) {
  console.log(params);
  return new Promise((resolve, reject) => {
    http('get', '/jdig/approval/processlist', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getProcessDetail(params: object) {
  console.log(params, 'getProcessDetail');
  return new Promise((resolve, reject) => {
    http('get', '/jdig/approval/getProcess', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getBuyUserList(params: object) {
  console.log(params);
  return new Promise((resolve, reject) => {
    http('get', '/jdig/approval/account', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getUserList(params: object) {
  console.log(params);
  return new Promise((resolve, reject) => {
    http('get', '/jdig/approval/otheraccount', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function createProcess(params: object) {
  console.log(params);
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/approval/saveProcess', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function updateProcess(params: object) {
  console.log(params);
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/approval/saveProcess', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function deleteProcess(params: object) {
  console.log(params);
  return new Promise((resolve, reject) => {
    http('get', '/jdig/approval/batchDelApproval', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getBuyUserCount() {
  console.log('accountInform');
  return new Promise((resolve, reject) => {
    http('get', '/jdig/approval/accountInform', {}, false, true).then(
      res => {
        console.log(res, 'accountInform');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
