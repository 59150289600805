import { atom } from "recoil";

import { Order } from "../interface";

const remove = atom<Order | undefined>({
  key: "remove",
  default: undefined,
});

export default remove;
