/*
 * @Author: wuzhimin14 wuzhimin14@jd.com
 * @Date: 2023-11-15 14:26:33
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-10 16:08:29
 * @FilePath: /jdi-web-inter/src/components/FourCategorySelect/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import styles from './index.module.scss';
import { getAddressItemList } from '@/api/newApi/personalCenter/address';
import { useTranslation } from 'react-i18next';
const { Item } = Form;

interface ProvinceCityCascaderIndexProps {
  detailData:any;
  disabled?: boolean; //是否禁选
  formRef?: any;
  initialValue?: any;
  required?: boolean;
  param?: any; //额外参数
  handleChange?: (level: string, levelId: string) => void;
}

const ProvinceCityCascaderIndex: React.FC<ProvinceCityCascaderIndexProps> = (props: any) => {
  const { initialValue, param = {}, detailData, required, formRef, disabled, handleChange } = props;
  const [defaultRequired, setDefaultRequired] = useState(true);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [provinceList, setProvinceList] = useState<any[]>([]);
  const [cityList, setCityList] = useState<any[]>([]);
  const [countyList, setCountyList] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(detailData.countryId || '');
  const [selectedProvince, setSelectedProvince] = useState<any>(detailData.provinceId || '');
  const [selectedCity, setSelectedCity] = useState<any>(detailData.cityId || '');
  const [selectedCounty, setSelectedCounty] = useState<any>(detailData.countyId || '');

  const { t } = useTranslation();

  //
  /**
   * 获取级联地址列表
   * param parentId 上层选中id type 国省市区
  */
  const getAllList = async (parentId: number, type?:string) => {
    const res: any = await getAddressItemList({ parentId });
    if (res.success) {
      const newData = res?.value?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      if(type == '1'){
        setProvinceList(newData);
      }else if(type == '2'){
        setCityList(newData);
      }else if(type == '3'){
        setCountyList(newData);
      } else{
        setCountryList(newData);
      }
      return newData;
    } else {
      return [];
    }
  };
  
  useEffect(() => {
    getAllList(-1, '0');
    // setDefaultRequired(required);
  }, []);

  useEffect(()=>{
    setSelectedCountry(detailData.address.countryId)
    setSelectedProvince(detailData.address.provinceId)
    setSelectedCity(detailData.address.cityId)
    setSelectedCounty(detailData.address.countyId)
  }, [detailData])

  useEffect(() => {
    if(selectedCountry) getAllList(selectedCountry, '1');
  }, [selectedCountry]);
  useEffect(() => {
    if(selectedProvince) getAllList(selectedProvince, '2');
  }, [selectedProvince]);
  useEffect(() => {
    if(selectedCity) getAllList(selectedCity, '3');
  }, [selectedCity]);

  return (
    <div className={styles.index}>
      <Item label={t('myAddress.addModal.form.chooseAddress')} name="countryArea" rules={[{
        required:true,
        message:t('myAddress.addModal.form.chooseAddress')
      }]}>
        <Row gutter={24} >
          <Col span={6}>
            <Item name={'countryId'} className={styles.itemStyle}>
              <Select
                options={countryList}
                placeholder={t('myAddress.addModal.form.chooseCountry')}
                rules={[{ required: true, message: t('myAddress.addModal.form.chooseCountry') }]}
                onChange={(value: any) => {
                  setSelectedCountry(value)
                }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item name={'provinceId'} className={styles.itemStyle}>
              <Select
                name={'provinceId'}
                options={provinceList}
                placeholder={t('myAddress.addModal.form.chooseProvince')}
                rules={[{ required: true, message: t('myAddress.addModal.form.chooseProvince')}]}
                onChange={(value: any) => {
                  setSelectedProvince(value)
                }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item name={'cityId'} className={styles.itemStyle}>
              <Select
                options={cityList}
                placeholder={t('myAddress.addModal.form.chooseCity')}
                rules={[{ required: true, message: t('myAddress.addModal.form.chooseCity') }]}
                onChange={(value: any) => {
                  setSelectedCity(value)
                }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item name={'countyId'} className={styles.itemStyle}>
              <Select
                  options={countyList}
                  placeholder={t('myAddress.addModal.form.chooseCounty')}
                  rules={[{ required: true, message: t('myAddress.addModal.form.chooseCounty') }]}
                  onChange={(value: any) => {
                    formRef?.setFieldsValue({
                      countryArea:selectedCountry
                    })
                    formRef?.validateFields(['countryArea'])
                  }}
              />
            </Item>
          </Col>
        </Row>
      </Item>
    </div>
  );
};

export default ProvinceCityCascaderIndex;

