/*
 * @Author: your name
 * @Date: 2021-11-30 17:16:40
 * @LastEditTime: 2021-11-30 18:13:28
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/Carts/atoms/current.ts
 */
import { atom } from 'recoil';

import { Sku } from '../interface';

interface Current {
  method: 'delete' | 'move' | 'copy' | 'disabled';
  list: Array<Sku>;
}

const current = atom<Current | undefined>({
  key: 'current',
  default: undefined,
});

export default current;
