import PIC_ENMU from "@/pages/Login/enum";
import style from "@/pages/Login/index.module.scss";
import Registered from "@/pages/Login/components/newRegistered";
import React, {useEffect, useState} from "react";
import NewFooter from '../NewFooter';

const Foot = () => {
  const [X, setX] = useState(0);
  const [Y, setY] = useState(0);

  const Bottom = () => {
    const compMap = [
      { url: 'https://www.jd.com/', name: '京东商城' },
      { url: 'https://www.jdt.com.cn/', name: '京东科技' },
      { url: 'https://www.jdl.com/', name: '京东物流' },
      { url: 'https://www.jdcloud.com/cn/', name: '京东云' },
      { url: 'https://neuhub.jd.com/', name: '京东AI' },
      { url: 'https://b.jd.com/', name: '京东企业购' },
      { url: 'https://www.vipmro.net/', name: '京满仓' },
      { url: 'https://www.vipmro.com/', name: '工品优选' },
      { url: 'https://mro.jd.com/', name: '京东工品购' },
    ];
    const windMap = [
      { url: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000088', name: '京公网安备 11000002000088号' },
      { url: 'https://beian.miit.gov.cn/#/Integrated/index', name: '京ICP备11041704号' },
      { url: '', name: '(京)网械平台备字(2018)第00003号' },
      { url: 'https://h5.m.jd.com/pc/dev/3T3No18XR8k8rpLGLGhgbJ1StAFq/index.html', name: 'ICP' },
      { url: 'https://img13.360buyimg.com/imagetools/jfs/t1/92296/37/36240/2251746/63f36f37F57324093/498cbdaf1fccc67c.jpg', name: '营业执照' },
      { url: 'https://img10.360buyimg.com/imagetools/jfs/t1/184141/33/32976/2162190/63f36f24F53a30a42/e69c8735bbe7a30b.jpg', name: '出版物经营许可证' },
      { url: 'https://m-isp.jd.com/static/private_policy.html', name: '隐私协议' },
      { url: 'https://gw-mobile-isp.jd.com/protocol.html', name: '用户协议' },
    ];
    return (
      <div className={`${style.login_foot}`}>
        <div className={`${style.login_foot_body}`}>
          <div className={`${style.body_box}`}>
            <div className={`${style.box_left}`}>
              <div className={`${style.box_left_title}`}>
                <img src={PIC_ENMU.banner.banner_big_title} />
              </div>
              <ul className={`${style.box_left_comps}`}>
                {compMap.map((e, index) => {
                  return (
                    <li key={index}>
                      <a href={e.url} target="_blank" rel="noreferrer">
                        {e.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <ul className={`${style.box_left_control}`}>
                {windMap.map((e, index) => {
                  return (
                      <li key={index}>
                        {
                          e.url ?
                              <a href={e.url} target="_blank" rel="noreferrer">
                                {e.name}
                              </a>
                              : <span>{e.name}</span>
                        }
                      </li>
                  );
                })}
              </ul>
              <p className={`${style.box_left_copyright}`}>版权说明：Copyright © 2004-2023 京东JD.com 版权所有</p>
            </div>
            <div className={`${style.login_foot_QRcode}`}>
              <img src={PIC_ENMU.common.QRcode} />
              <p>下载工采APP</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const moveBox = () => {
    const box = document.querySelector('#JDGC_LOGIN_FOOT');
    box.addEventListener('mousemove', function(e) {
      const rect = box.getBoundingClientRect();

      const _x = e.pageX - rect.left;
      const _y = e.pageY - box.offsetTop;

      setX(_x);
      setY(_y);
    });
  };

  useEffect(() => {
    moveBox();
  }, []);

  return (
    <div id="JDGC_LOGIN_FOOT" style={PIC_ENMU.bottomBackground} className={`${style.bottom_background_box}`}>
      {/*<Registered />*/}
      {/*<Bottom />*/}
      {/*<div*/}
      {/*  className={`${style.mouse_hover_heightlight}`}*/}
      {/*  style={{*/}
      {/*    transform: `translateX(${X}px) translateY(${Y}px) scale(1) translateZ(0px)`,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <img src={PIC_ENMU.quick_register} />*/}
      {/*</div>*/}
      <NewFooter />
    </div>
  );
};

export default Foot;
