/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-02-22 15:36:53
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-01 16:52:53
 * @FilePath: /isp_International/src/api/newApi/approval.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from '../axios';

/**
 * 待审批列表数据查询
 * @param params
 */
export function queryProcessAccount(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/multi/approval/queryProcessAccount', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function initFieldSel(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('get', '/jdig/multi/approval/initFieldSel', params, false, true).then(
        res => {
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

export function initSymbol(params: any = {}) {
    return new Promise((resolve, reject) => {
        http('get', '/jdig/multi/approval/initSymbol', params, false, true).then(
        res => {
            resolve(res);
        },
        error => {
            console.log('网络异常~', error);
            reject(error);
        },
        );
    });
}

export function getOperatorVideo(params: any = {}) {
    return new Promise((resolve, reject) => {
        http('get', '/jdig/multi/approval/queryOperatorVideo', params, false, true).then(
        res => {
            resolve(res);
        },
        error => {
            console.log('网络异常~', error);
            reject(error);
        },
        );
    });
}

export function queryDetail(params: any = {}) {
    return new Promise((resolve, reject) => {
        http('get', '/jdig/multi/approval/detail', params, false, true).then(
        res => {
            resolve(res);
        },
        error => {
            console.log('网络异常~', error);
            reject(error);
        },
        );
    });
}

export function saveProcess(params: any, randomValue: string) {
    return new Promise((resolve, reject) => {
        http('jsonpost', `/jdig/multi/approval/${randomValue}/deployProcess`, params, false, true).then(
        res => {
            resolve(res);
        },
        error => {
            console.log('网络异常~', error);
            reject(error);
        },
        );
    });
}
export function initPageValue(params: any = {}) {
    return new Promise((resolve, reject) => {
        http('get', '/jdig/multi/approval/initPage', params, false, true).then(
        res => {
            resolve(res);
        },
        error => {
            console.log('网络异常~', error);
            reject(error);
        },
        );
    });
}

/***
 * 分页获取审批流数据
 */
export function queryProcess(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/multi/approval/queryProcess', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 * 删除单个/多个审批流
 */
export function delProcess(params: any) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/multi/approval/delProcess', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}










