import { atom } from 'recoil';

import Consignee from '../types/consignee';

const consignee = atom<Consignee | undefined>({
  key: 'consignee',
  default: undefined,
});

export default consignee;
