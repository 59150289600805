import { HashCode, transToTreeDat } from '../components/drawFlow/utils/index';
import { newAccountNode, newConditionNode } from '@/pages/Approval/Create/components/drawFlow/utils/NodeFactory';
import { CONDITION_DRAWER_TYPE, NODE_TYPE } from '@/pages/Approval/Create/constants';

// 循环遍历删除组下关联节点
export function deleteLoop(selfConfig, node, deleteArr) {
  console.log(selfConfig, node, 'selfConfig');
  const currentDeleteArr = selfConfig.filter(i => {
    if (i.isRow) {
      return i.groupId === node.id;
    } else {
      return i.groupPid === node.id;
    }
  });
  if (currentDeleteArr.length) {
    currentDeleteArr.forEach(i => {
      deleteArr.push(i);
      deleteLoop(selfConfig, i, deleteArr);
    });
  } else {
    return;
  }
}

//删除后重组数组
export function deleteRestructuringConditions(node, selfCopyConfig) {
  // 第一步 取出一组节点 按照最新节点的优先级，设置原先节点优先级
  const sameGroupList = selfCopyConfig.filter(copyNode => copyNode.groupId === node.groupId);
  if (sameGroupList.length > 0) {
    let tempPriority = node.priority;
    sameGroupList.forEach(item => {
      if (item.priority > tempPriority) {
        item.priority = tempPriority;
        tempPriority = tempPriority + 1;
      }
    });
    // 第三步 取出其余节点
    const otherGroupList = selfCopyConfig.filter(copyNode => copyNode.groupId !== node.groupId);
    // 第四步 取出最新节点的父亲节点
    let parentIndex = 0;
    selfCopyConfig.filter((copyNode, index) => {
      if (copyNode.id === node.groupPid) {
        parentIndex = index;
        return;
      }
    });
    // 第五步：组合成新的数组
    otherGroupList.splice(parentIndex, 0, ...sameGroupList);
    return otherGroupList;
  } else {
    return selfCopyConfig;
  }
}

export function repickDeleteArr(selfConfig, repickConfig) {
  selfConfig.forEach(i => {
    if (i.isRow && i.groupId === repickConfig.id) {
      i.groupId = repickConfig.groupId;
    } else if (!i.isRow && i.groupPid === repickConfig.id) {
      i.groupPid = repickConfig.groupId;
    }
  });
  return selfConfig;
}
// 删除节点
export function deleteNode(selfConfig, node) {
  selfConfig = selfConfig.map(i => i.id !== node.id && i).filter(Boolean);
  return selfConfig;
}
//单独删除col下node
export function deleteColNode(selfConfig, node) {
  const nodeArr = selfConfig.filter(i => i.groupId === node.groupId && !i.isRow);
  let deleteArr = [];
  let repickConfig;
  if (nodeArr.length > 1) {
    //递归删除所有关联子节点
    deleteArr = [node];
    deleteLoop(selfConfig, node, deleteArr);
    repickConfig = {
      id: node.id,
      groupId: node.groupId,
      priority: node.priority,
    };
  } else {
    //删除整个group
    const allCol = selfConfig.map(i => i.groupId === node.groupId && !i.isRow && i).filter(Boolean);
    deleteArr = allCol;
    allCol.forEach(i => {
      deleteLoop(selfConfig, i, deleteArr);
    });
    repickConfig = {
      id: node.groupId,
      groupId: node.groupPid,
      priority: node.priority,
    };
  }
  deleteArr.forEach(i => {
    selfConfig = deleteNode(selfConfig, i);
  });
  selfConfig = repickDeleteArr(selfConfig, repickConfig);

  // 重拍
  selfConfig = deleteRestructuringConditions(node, selfConfig);

  console.log('repickDeleteArr-->', selfConfig);
  return selfConfig;
}
// 获得操作类型的审批list
export function getCurrentApprovalList(stateApproval) {
  const selfConfig =
    stateApproval.processType === 1 ? stateApproval.noConditionNodeList : stateApproval.haveConditionNodeList;

  return selfConfig;
}

// 提交流程转化
export function transformResultData(selfConfig) {
  const list = JSON.parse(JSON.stringify(selfConfig));
  transToTreeDat(list);
  list.forEach(i => {
    if (i.isRow) {
      delete i.childNode;
    }
    if (i.pids.length > 0) {
      i.pids = i.pids.join(',');
    }
    // 如果是条件节点 且 条件节点有子元素
    if (i.nodeType === NODE_TYPE.nodeCondition && i.childNode) {
      i.hasChild = true;
    }
  });
  return list.filter(item => item.nodeType !== 4);
}

/**--------------------**/
// 重组对象
export function repickArr(repickConfig, stateApproval) {
  const tempNodeList = getCurrentApprovalList(stateApproval);
  const selfConfig = JSON.parse(JSON.stringify(tempNodeList));
  selfConfig.forEach(i => {
    if (i.isRow) {
      if (i.groupId === repickConfig.groupId) {
        i.groupId = repickConfig.id;
      }
    } else {
      if (i.groupPid === repickConfig.groupId) {
        i.groupPid = repickConfig.id;
      }
    }
  });
  return selfConfig;
}
/**
 * 获取row节点
 */
export function getRowNode(nextNode, stateApproval) {
  const node = newAccountNode(nextNode);
  const selfConfig = repickArr(node, stateApproval);
  selfConfig.push(node);
  return selfConfig;
}
/**
 * 获取col节点
 */
export function getColNode(nextNode, stateApproval) {
  const groupId = HashCode();
  const node = newConditionNode({ groupId, ...nextNode });
  const repickConfig = {
    groupId: node.groupPid,
    id: node.id,
  };
  const selfConfig = repickArr(repickConfig, stateApproval);
  selfConfig.push(node);
  return selfConfig;
}
/**
 * 根据isRow去判断row或者rol
 */
export function getNodeFactory(nextNode, stateApproval) {
  // console.log('根据isRow去判断row或者rol', nextNode);
  if (!nextNode.isRow) {
    return getColNode(nextNode, stateApproval);
  } else {
    // getRowNode(nextNode);
    return getRowNode(nextNode, stateApproval);
  }
}

/**
 * 重组条件
 */
export function restructuringConditions(node, selfCopyConfig, drawerType) {
  // 第一步 取出一组节点 按照最新节点的优先级，设置原先节点优先级
  let sameGroupList = selfCopyConfig.filter(copyNode => copyNode.groupId === node.groupId);
  // 修改的时候需要先剔除出去在整合
  if (drawerType === CONDITION_DRAWER_TYPE.modifyCondition) {
    sameGroupList = sameGroupList.filter(oneNode => oneNode.id !== node.id);
  }
  let tempPriority = node.priority;
  sameGroupList.forEach(item => {
    //特殊情况
    if (node.priority === 3 && item.priority === 3) {
      item.priority = 2;
    } else {
      if (item.priority === tempPriority) {
        tempPriority = tempPriority + 1;
        item.priority = tempPriority;
      }
    }
  });
  // 第二步，将最新节点插入相应位置
  sameGroupList.splice(node.priority - 1, 0, node);
  // 第三步 取出其余节点
  const otherGroupList = selfCopyConfig.filter(copyNode => copyNode.groupId !== node.groupId);
  // 第四步 取出最新节点的父亲节点
  let parentIndex = 0;
  selfCopyConfig.filter((copyNode, index) => {
    if (copyNode.id === node.groupPid) {
      parentIndex = index;
      return;
    }
  });
  // 第五步：组合成新的数组
  otherGroupList.splice(parentIndex, 0, ...sameGroupList);
  return otherGroupList;
}
//ele为指定跳转到该位置的DOM节点
export function heightToTop(ele) {
  const root = document.body;
  let height = 0;
  do {
    height += ele.offsetTop;
    ele = ele.offsetParent;
  } while (ele !== root);
  return height;
}
