import add from "./add";
import filter from "./filter";
import loading from "./loading";
import orderList from "./orderList";
import remove from "./remove";

const atoms = {
  add,
  filter,
  loading,
  orderList,
  remove,
};

export default atoms;
