/**
 * 脱敏方法
 */
export const desensitization = (type: 'phone' | 'email', value: string) => {
  if (!value) return '';
  switch (type) {
    case 'phone':
      return value.replace(value.slice(3, 7), '****');
    case 'email': {
      const keys = value.split('');
      for (let i = 1; i < keys.length; i++) {
        if (keys[i] === '@') break;
        keys[i] = '*';
      }
      return keys.join('');
    }
  }
};

/**
 * 获取浏览器缩放百分比
 */
export const getZoom = () => {
  let ratio = 0;
  const screen = window.screen as any;
  const ua = navigator.userAgent.toLowerCase();

  if (~ua.indexOf('msie')) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
    ratio = window.outerWidth / window.innerWidth;
  } else if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  }

  // if (ratio) {
  //   ratio = Math.round(ratio * 100);
  // }
  return ratio;
};


export const toPercent = (point)=>{
  let str = Number(point*100).toFixed(1);
  str+="%";
  return str;
}
