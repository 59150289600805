/*
 * @Author: duchongchong1
 * @Date: 2022-11-06 20:13:14
 * @LastEditors: duchongchong1
 * @LastEditTime: 2023-08-28 11:19:28
 * @FilePath: /isp_International/src/api/newApi/search.ts
 * @Description:
 */
/* eslint-disable no-undef */
// import { RootObject } from "config";
import http from '../axios';

/**
 * 搜索
 */
export function goSearch(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/search/search', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 
添加搜索历史
https://ce-iep.jd.com/jdig/search/addSearchHistory
 */
export function addSearchHistory(word: string) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/search/addSearchHistory', { word }, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getSearchHistoryList() {
  return http('get', '/jdig/search/searchHistory', {}, false, true);
}

/**
 * 删除搜索历史
 */
export function delHistory(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/search/delHistory', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
/**
 * 获取商卡参数
 */
export function getBusinessCardInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/search/getBusinessCardInfo', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

// 搜索--搜列
export function getSearchList(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/search/search', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

// 搜索--搜列商品
export function getSearchGoodList(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/search/searchWares', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 搜索--搜列筛选项
export function getSearchCombined(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/search/searchCombined', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 落地页---精选类目
export function getMarketDetail(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/market/detail', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 搜索--获取搜列面包屑类目
// export function getCateInfo(params: any) {
//   return new Promise((resolve, reject) => {
//     http('jsonpost', '/jdig/category/getCateInfo', params, false, true).then(
//       res => {
//         resolve(res);
//       },
//       error => {
//         console.log('网络异常~', error);
//         reject(error);
//       },
//     );
//   });
// }

//获取下一级类目
export function getNextCategory(params: any) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/category/nextCategory', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//获取类目名称
export function getCategoryName(params: any) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/category/categoryName', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 搜索-加入购物车
export function addProducts(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/addProducts', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
/**
 * 获取联想词
 */
// /jdig/search/attempt
export function getAcquireAssociativeWords(key: string) {
  return http('get', '/jdig/search/attempt', { key }, false, true);
}

/**
 * 搜索热词和暗纹
 * @returns
 */
// export function getSearchHotAndDark() {
//   return http('get', '/jdig/search/hotAndDark', {}, false, true);
// }

/**
 * 判断搜索条件是不是sku
 * @param sku 商品编码
 */
export function isSkuId(sku: string) {
  return http('get', '/jdig/search/isSkuSearch', { sku }, false, true);
}


// 搜索-获取mku列表库存数量
export function getRemainStockList(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/stock/remain/list', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}