import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
const { Option } = Select;
import styles from './index.module.scss';

interface IProps {
  content?: any;
  symbol?: any;
  fieldSel?: any;
  isCanDelete?: boolean;
  deleteSelectCondition?: (fieldId: string) => void;
  changeConditionItem?: (content: any) => void;
  fieldSelChange?: () => void;
}
const SelectCondition: React.FC<IProps> = (props: any) => {
  const { content, symbol, fieldSel, isCanDelete, deleteSelectCondition, fieldSelChange } = props;
  const [isShowMaxField, setIsShowMaxField] = useState(false);
  const [fieldValue, setFieldValue] = useState(content.fieldValue);
  const [maxFieldValue, setMaxFieldValue] = useState(content.maxFieldValue);
  const [hasEnum, setHasEnum] = useState(content.hasEnum);
  const [fieldEnumValues, setFieldEnumValues] = useState(content.fieldEnumValues);
  const [errorMessage, setErrorMessage] = useState('');
  const [isInputFieldError, setIsInputFieldError] = useState(false);

  const [betweenErrorMessage, setBetweenErrorMessage] = useState('');
  const [isInputMaxFieldError, setIsInputMaxFieldError] = useState(false);

  useEffect(() => {
    if (content.isError) {
      if (content?.operation === 'BETWEEN') {
        if (content.fieldValue === '') {
          setIsInputFieldError(true);
          setErrorMessage('请输入最小金额');
        }
        if (content.maxFieldValue === '') {
          setIsInputMaxFieldError(true);
          setBetweenErrorMessage('请输入最大金额');
        }
      } else {
        if (content.fieldValue === '') {
          setErrorMessage('请输入金额');
        }
      }
    }
  }, [content.isError]);

  const handleFieldSelChange = (value: string) => {
    setHasEnum(false);
    setFieldEnumValues([]);
    const currentFieldList = fieldSel.filter((field: any) => field.category == value);
    const currentField = currentFieldList[0];
    if (currentField.hasEnum) {
      content.fieldValue = currentField.fieldEnumValues[0];
      setFieldValue(currentField.fieldEnumValues[0]);
      content.hasEnum = true;
      console.log('handleFieldSelChange-->', currentField);
      setFieldEnumValues(currentField.fieldEnumValues);
      setTimeout(() => {
        setHasEnum(true);
      }, 1);
    } else {
      setHasEnum(false);
      setFieldEnumValues([]);
      content.hasEnum = false;
      content.fieldValue = '';
      setFieldValue('');
    }
    setMaxFieldValue('');
    content.maxFieldValue = '';
    content.fieldKey = value;
    content.fieldName = currentField.categoryName;
    fieldSelChange();
  };
  useEffect(() => {
    if (content?.operation === 'BETWEEN') {
      console.log('BETWEEN', content);
      setIsShowMaxField(true);
    } else {
      setIsShowMaxField(false);
    }
  }, [content.operation]);

  const handleSymbolChange = (value: string) => {
    const currentSymbol = symbol.filter((item: any) => item.operation == value);
    if (value === 'BETWEEN') {
      setIsShowMaxField(true);
    } else {
      setIsShowMaxField(false);
    }
    content.operation = value;
    content.operationName = currentSymbol[0].operationName;
    setFieldValue('');
    setMaxFieldValue('');
    content.fieldValue = '';
    content.maxFieldValue = '';
  };
  const handleDeleteSel = () => {
    console.log('删除id', content);
    deleteSelectCondition && deleteSelectCondition(content.fieldId);
  };
  const handleInputField = (event: any) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    setFieldValue(value);
    if (value.length > 0) {
      content.isError = false;
      const reg = /^([1-9][0-9]*)?$/;
      if (!reg.test(value)) {
        setIsInputFieldError(true);
        setErrorMessage('数值必须为正整数');
      } else {
        setIsInputFieldError(false);
        if (isShowMaxField && content.maxFieldValue !== '') {
          if (Number(value) >= Number(content.maxFieldValue)) {
            setIsInputFieldError(true);
            setErrorMessage('数值不可大于后序条件');
          } else {
            setIsInputFieldError(false);
            setErrorMessage('');
          }
        }
      }
    } else {
      setIsInputFieldError(true);
      if (isShowMaxField) {
        setErrorMessage('请输入最小金额');
      } else {
        setErrorMessage('请输入金额');
      }
    }
  };
  const handleBlurField = (event: any) => {
    content.fieldValue = event.target.value;
  };
  const handleInputMaxField = (event: any) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    content.maxFieldValue = value;
    setMaxFieldValue(value);

    const reg = /^([1-9][0-9]*)?$/;
    if (!reg.test(value)) {
      setIsInputMaxFieldError(true);
      setBetweenErrorMessage('数值必须为正整数');
    } else {
      setIsInputMaxFieldError(false);
      setBetweenErrorMessage('');
      if (Number(content.fieldValue) < Number(content.maxFieldValue)) {
        setIsInputFieldError(false);
        setErrorMessage('');
      }
    }
  };
  const handleBlurMaxField = (event: any) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    const reg = /^([1-9][0-9]*)?$/;

    if (value.length > 0) {
      if (!reg.test(value)) {
        setIsInputMaxFieldError(true);
        setBetweenErrorMessage('数值必须为正整数');
      } else {
        if (Number(value) <= Number(content.fieldValue)) {
          setIsInputMaxFieldError(true);
          setBetweenErrorMessage('数值不可小于前序条件');
        } else {
          setIsInputMaxFieldError(false);
          setBetweenErrorMessage('');
        }
        content.maxFieldValue = value;
        content.minFieldValue = content.fieldValue;
        setMaxFieldValue(value);
      }
    } else {
      setIsInputMaxFieldError(true);
      setBetweenErrorMessage('请输入最大金额');
    }
  };
  const handleEnumChange = (value: string) => {
    console.log('handleEnumChange', value);
    content.fieldValue = value;
    content.operation = 'EQ';
    content.operationName = '等于';
  };
  return (
    <div className={styles.selectCondition}>
      <div className={styles.bottomItem}>
        <div className={styles.fieldSelect}>
          <Select
            placeholder={'请选择条件'}
            defaultValue={content?.fieldKey}
            style={{ width: '192px' }}
            onChange={handleFieldSelChange}
          >
            {fieldSel?.map((item, index) => {
              return (
                <Option key={index} value={item.category} disabled={item.isSelect}>
                  {item.categoryName}
                </Option>
              );
            })}
          </Select>
        </div>
        {hasEnum ? (
          <Select
            placeholder={'请选择'}
            defaultValue={fieldValue}
            onChange={handleEnumChange}
            style={{ width: '524px', marginLeft: '8px', fontSize: '14px' }}
          >
            {fieldEnumValues?.map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        ) : (
          <>
            <div className={styles.fieldSelect}>
              <Select
                placeholder={'请选择'}
                onChange={handleSymbolChange}
                style={{ width: '192px', marginLeft: '8px', fontSize: '14px' }}
                defaultValue={content?.operation}
              >
                {symbol?.map((item, index) => {
                  return (
                    <Option key={index} value={item.operation}>
                      {item.operationName}
                    </Option>
                  );
                })}
              </Select>
            </div>
            {isShowMaxField ? (
              <div
                className={`${styles.between} ${isInputFieldError ? styles.error : ''} ${
                  isInputMaxFieldError ? styles.error : ''
                }`}
              >
                <input
                  placeholder={'请输入最小金额'}
                  value={fieldValue}
                  onChange={handleInputField}
                  onBlur={handleBlurField}
                  style={{ width: '151px', marginLeft: '8px' }}
                  maxLength={20}
                />
                <span>至</span>
                <input
                  placeholder={'请输入最大金额'}
                  value={maxFieldValue}
                  onChange={handleInputMaxField}
                  onBlur={handleBlurMaxField}
                  style={{ width: '151px', marginLeft: '8px' }}
                  maxLength={20}
                />
                {isInputMaxFieldError && <span className={styles.errorMaxTips}>{betweenErrorMessage}</span>}
                {isInputFieldError && <span className={styles.errorTips}>{errorMessage}</span>}
              </div>
            ) : (
              <div className={styles.singleInput}>
                <input
                  placeholder={'请输入金额'}
                  value={fieldValue}
                  onChange={handleInputField}
                  onBlur={handleBlurField}
                  style={{ marginLeft: '8px' }}
                  maxLength={20}
                  className={`${content?.isError ? styles.inputError : ''} ${
                    isInputFieldError ? styles.inputError : ''
                  }`}
                />
                {(content.isError || isInputFieldError) && (
                  <span className={styles.inputErrorTips}>{errorMessage}</span>
                )}
              </div>
            )}
          </>
        )}
        {isCanDelete && <span className={styles.itemDelete} onClick={handleDeleteSel}>
          <i className={`iconFont icon-platform_close`}></i>
        </span>}
      </div>
    </div>
  );
};

export default SelectCondition;
