import React, { useEffect, useState } from 'react';
import { message, Button, Card, Radio, Select, Modal } from 'antd';
import { freightCalculate, getApprovalByCondition } from '@/api/newApi/orderInfo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from '../../hooks';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { getPersonInfo } from '@api/newApi/carts';
import { withSuccess } from 'antd/lib/modal/confirm';

const Index = (props) => {
    const {isShow = false, callback} = props;
    const { t } = useTranslation();
    const setApproval = useSetRecoilState('approval'); // 审批流
    const approval = useRecoilValue('approval');
    const condition = useRecoilValue('condition');
    const approvalList = useRecoilValue('approvalList');
    return (
        <Modal 
        title="审批流设置"
        visible={isShow}
        onCancel={()=>{
            callback && callback(0)
            setApproval(undefined)
        }}
        onOk={()=>{
            callback && callback(1)
        }}
        >
            <div className={styles.approval}>
                <Select 
                    value={approval}
                    rules={[{
                        required: true,
                        message: '请选择审批流'
                    }]}
                    style={{
                        width:"100%"
                                
                    }}
                    onChange={(value) => {
                        setApproval(value)
                    }}
                >
                {approvalList && approvalList
                .map((item, i) => <Select.Option key={item.processKey}>{item.processName}</Select.Option>)
                }
                </Select>
            </div>
        </Modal>
    )
}

export default Index;
