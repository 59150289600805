/*
 * @Author: duchongchong1
 * @Date: 2022-11-14 19:53:49
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-07-17 21:13:33
 * @FilePath: /isp_International/src/pages/OrderDetailNew/components/PayInfo/index.tsx
 * @Description:  
 */
import React, { useState } from 'react';

import { formatMoney, cnyMoney } from '@/util';
import cookies from 'react-cookies';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const PayInfo: React.FC<{ orderDetails: any }> = ({ orderDetails }) => {
  const orderRes = orderDetails?.orderRes;
  const { t } = useTranslation();
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土

  return (
    <div className={styles.payInfo}>
      <p className={styles.row}>
        <span className={styles.left}>{t('orderDetail.items')}</span>
        <span className={styles.right}>
          {t('orderDetail.itemPieces')
            .replaceAll('{kind}', orderRes?.orderSkuKindNum)
            .replaceAll('{num}', orderRes?.orderSkuNum)}
        </span>
      </p>
      {/* 商品未税总金额 */}
      <p className={styles.row}>
        <span className={styles.left}>{ isLocalPro ? t('OrderInfo.shopTotalAmount') : t('orderDetail.totalOrderAmount')}</span>
        <span className={styles.right}>{orderRes?.symbol + cnyMoney(orderRes?.waresSaleTotalPrice, false)}</span>
      </p>
      {/* 服务费 */}
      {!isLocalPro && <p className={styles.row}>
        <span className={styles.left}>{t('orderDetail.shippingFee')}</span>
        <span className={styles.right}>{'+' + ' ' + orderRes?.symbol + cnyMoney(orderRes?.serviceFee, false)}</span>
      </p>}
      
      {/* 增值税总金额 */}
      <p className={styles.row}>
        <span className={styles.left}>{!isLocalPro ? t('orderDetail.taxFee') : t('orderDetail.totalVatAmount')}</span>
        <span className={styles.right}>{  '+' + ' ' +orderRes?.symbol +cnyMoney(orderRes?.orderTaxPrice, false)}</span>
      </p>
      {/* 商品含税总金额 */}
        <p className={styles.row}>
        <span className={styles.left}>{t('orderDetail.orderTotalPriceTax')}</span>
        <span className={styles.right}>{  '+' + ' ' +orderRes?.symbol +cnyMoney(orderRes?.totalPrice, false)}</span>
      </p>
       {/* 运费 */}
       <p className={styles.row}>
        <span className={styles.left}>{t('orderDetail.freightFee')}</span>
        <span className={styles.right}>{'+' + ' ' + orderRes?.symbol +cnyMoney(orderRes?.orderFreightPrice, false)}</span>
      </p>
      {/* 应付总金额 */}
      <p className={styles.priceBox}>
        <span className={styles.left}>{t('orderDetail.estTotal')}</span>
        <span className={styles.right}>
          <em className={styles.ft14}>{orderRes?.symbol}</em>
          <em className={styles.ft22}>{cnyMoney(orderRes?.totalPrice, false)}</em>
        </span>
      </p>
       
    </div>
  );
};
export default PayInfo;
