import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Modal } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import styles from '../../index.module.scss';
import iconArrowRight from '@/assets/images/orderList/icon_arrow_right.svg';
import iconInfo from '@/assets/images/orderList/icon_info.svg';
import iconAudit from '@/assets/images/orderList/icon_audit.svg';
import { cnyMoney } from '@/util';
import Goods from '../Goods';
import OrderActions from '../OrderActions';
import { InfoCircleOutlined } from '@ant-design/icons';

import {
  OrderStatus,
  orderStatus,
  AuditStatus,
  approvalStatus,
  paymentType,
  restore,
  CancelModal,
  ApproveModal,
  RejectModal,
} from '@/components/Order';
import { pick } from 'lodash';
import { getAfterSaleGoods, getServiceUrl } from '@/api/orderList/api';
import ApproveProcess from '@/components/ApproveProcess';
import { UserType } from '../../components/SearchForm';
import { useTranslation } from 'react-i18next';

interface ApprovalStatusMap {
  [key: number]: { color: string, tag: string };
}
interface Order {
  approvalStatus: number;
}

const approvalStatusMap: ApprovalStatusMap = {
  2: { color: 'green', tag: 'access' },
  3: { color: 'red', tag: 'reject' }
}

const OrderItem: React.FC<{
  order: any;
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  auditQueryType: string;
  onCancel: () => void;
  onApprove: () => void;
  onReject: () => void;
  onRestore: () => void;
  isConfirm: boolean;
  orderConfirm: () => void;
}> = ({
  order,
  isAudit,
  isDelete,
  isVsp,
  auditQueryType,
  onCancel,
  onApprove,
  onReject,
  onRestore,
  isConfirm,
  orderConfirm,
}) => {
  const { t, i18n } = useTranslation();
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [auditModalVisible, setAuditModalVisible] = useState<boolean>(false);
  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [afterSaleModalVisible, setAfterSaleModalVisible] = useState<boolean>(false);
  const [afterSaleGoodsList, setAfterSaleGoodsList] = useState<Array<object>>([]);
  const [selectedOrders, setSelectedOrders] = useState<Array<{ jdOrderId: string; processId: string; pin: string }>>(
    [],
  );

  const history = useHistory();

  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;
  const userPin: string = currentUser.pin;

  const orderConsignee = order.orderConsignee || order.consignee;

  const receiver = (
    <div>
      <div className={styles.bold}>{orderConsignee.name + ' ' + orderConsignee.mobile}</div>
      <div className={styles.bold} style={{ marginTop: '10px' }}>
        {`${orderConsignee.provinceName || ''} ${orderConsignee.cityName || ''} ${orderConsignee.countyName ||
          ''} ${orderConsignee.townName || ''}`}
      </div>
      <div style={{ marginTop: '5px' }}>详细地址-{orderConsignee.address}</div>
    </div>
  );

  const moreGoods = (
    <Link
      to={{
        pathname: '/order/detail',
        search: `?id=${order.jdOrderId}&orderPin=${order.pin}`,
      }}
      style={{ color: 'rgba(0, 0, 0, 0.85)' }}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        // if (isVsp) {
        //   e.preventDefault();
        // }
      }}
    >
      <Row className={[styles.center, styles.borderTop].join(' ')} style={{ padding: '10px' }}>
        查看更多商品
        <Icon icon={iconArrowRight} />
      </Row>
    </Link>
  );

  const cancelModal = (
    <CancelModal
      order={order}
      visible={cancelModalVisible}
      onCancel={() => setCancelModalVisible(false)}
      onOK={() => {
        setCancelModalVisible(false);
        onCancel();
      }}
    />
  );

  const afterSaleModal = (
    <Modal
      width={620}
      title="申请售后"
      visible={afterSaleModalVisible}
      footer={null}
      onCancel={() => setAfterSaleModalVisible(false)}
      className={styles.buyAccountModal}
    >
      <div style={{ color: '#666' }}>选择需要售后的商品</div>
      <div
        style={{
          overflowY: 'auto',
          maxHeight: '520px',
          marginTop: '20px',
        }}
      >
        {afterSaleGoodsList.map((g: any) => (
          <Row
            key={g.skuId}
            className={styles.borderBottom}
            style={{
              padding: '15px 0',
              fontSize: '12px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col className={[styles.center].join(' ')} style={{ alignItems: 'start', width: '230px' }}>
              <img src={`https://m.360buyimg.com/da/s150x150_${g.imgUrl}`} style={{ width: '40px', height: '40px' }} />
              <span className={styles.goodsName} style={{ marginLeft: '10px' }}>
                {g.name}
              </span>
            </Col>
            <Col className={[styles.center].join(' ')}>
              <span style={{ fontWeight: 'bold' }}>
                <span className={styles.blue}>{order.symbol + cnyMoney(g.price, false)}</span> x {g.num}
                {g?.extAttr?.x ?? '件'}
              </span>
            </Col>
            <Col>
              <Button
                type="primary"
                ghost
                disabled={!g.canApply}
                onClick={() => history.push(`/application?orderId=${order.jdOrderId}&skuId=${g.skuId}`)}
              >
                申请售后
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    </Modal>
  );
  const approveModal = (
    <ApproveModal
      orders={selectedOrders}
      visible={approveModalVisible}
      onCancel={() => setApproveModalVisible(false)}
      onOK={() => {
        setApproveModalVisible(false);
        onApprove();
      }}
    />
  );

  const rejectModal = (
    <RejectModal
      orders={selectedOrders}
      visible={rejectModalVisible}
      onCancel={() => setRejectModalVisible(false)}
      onOK={() => {
        setRejectModalVisible(false);
        onReject();
      }}
    />
  );
  const findAuditStatus = (order: any) => {
    const { approvalStatus } = order;
    switch (approvalStatus || 0) {


      case 1:
        return t("approvalList.auditStatus.auditing");
      case 2:
        return t("approvalList.auditStatus.approved");
      case 3:
        return t("approvalList.auditStatus.deny");
      case 4:
        return  t("approvalList.auditStatus.cancel");
      default:
        return  t("approvalList.auditStatus.deny");
    }
  };
  return (
    <Row>
      <Col className={styles.borderRight}>
        {order.orderSkus.slice(0, 5).map((g: any, index: number) => (
          <Goods key={index} goods={g} />
        ))}
      </Col>
      <Col className={[styles.title4, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}>
        <Row style={{ alignItems: 'center' }}>
          <span style={{ marginRight: '5px', wordBreak: 'break-all' ,textOverflow:'ellipsis',display:'-webkit-box',WebkitLineClamp:'3',WebkitBoxOrient:'vertical',overflowY:'hidden'}}>{orderConsignee.name}</span>
          {/* <Tooltip title={receiver}>
            <Icon icon={iconInfo} width="12px" height="12px" />
          </Tooltip> */}
        </Row>
      </Col>
      <Col className={[styles.title5, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}>
        <div className={[styles.price, styles.bold].join(' ')}>
          {order.symbol + cnyMoney(order.orderNeedMoney || order.needPayMoney, false)}
        </div>
        <div style={{ marginTop: '10px' }}>{paymentType(order)}</div>
      </Col>
      <Col
        className={[styles.title6, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}
        style={{ flexDirection: 'column' }}
      >
        {/* <Row
          style={{ wordBreak: 'break-all' }}
        <Row
          style={{ wordBreak: 'break-all', marginBottom: '8px' }}
          className={
            order.orderState == 3 || order.orderState == 2
              ? styles.orange
              : order.orderState == 4
              ? styles.green
              : styles.black
          }
        >
          <span>
            {t(`orderList.table_status.${order.orderState}`)}
            {order.orderState == 1 && (
              <InfoCircleOutlined title={t('orderList.tips.processing')} style={{ marginLeft: '4px' }} />
            )}
            {order.orderState == 2 && (
              <InfoCircleOutlined title={t('orderList.tips.wcc')} style={{ marginLeft: '4px' }} />
            )}
          </span>
        </Row> */}
        {/**订单审批 和订单管理页，订单状态不是未发起 */}

        <Row style={{ marginTop: '0px', marginBottom: '8px', alignItems: 'center' }}>
          <span
            // onClick={() => {
            //   // 母子帐号没有审批链路，不需要点开
            //   if (userType !== UserType.parentAccount && userType !== UserType.childAccount) {
            //     setAuditModalVisible(true);
            //   }
            // }}
            // style={{ cursor: 'pointer', color: '#1938ED' }}
            style={{
              cursor: 'default',
              color: approvalStatusMap[order.approvalStatus]?.color || '#1938ED',
              fontWeight: 'normal'
            }}
            // style={{ color: '#1938ED' }}
          >
            {findAuditStatus(order)}
          </span>
          {/* {// 订单列表：审批中或者有审批文件
          (order?.operateMap?.showLookFileBtn ?? false) && ( // 审批列表：showLookFileBtn
            <Icon icon={iconAudit} style={{ marginLeft: '5px', cursor: 'default' }} />
          )} */}
        </Row>

        <Row style={{ marginTop: '0px', alignItems: 'center', fontWeight: 'normal' }}>
          <span
            onClick={() => {
              // 母子帐号没有审批链路，不需要点开
              if (userType !== UserType.parentAccount && userType !== UserType.childAccount) {
                setAuditModalVisible(true);
              }
            }}
            className={`${styles.titleArrow}`}
            style={{ cursor: 'pointer', color: 'rgba(51,51,51,0.65)' }}
          >
            {t('approvalList.approvalDetail.title')}
            <i className={`${styles.crumbs_arrow}`}></i>
          </span>
        </Row>
      </Col>
      <Col
        className={[styles.title7, styles.goodsCell, styles.borderTop].join(' ')}
        style={{ flexDirection: 'column' }}
      >
        <OrderActions
          order={order}
          isAudit={isAudit}
          isDelete={isDelete}
          // isVsp={isVsp}
          isConfirm={isConfirm}
          orderConfirm={orderConfirm}
          auditQueryType={auditQueryType}
          onApplyAftersale={() => {
            getAfterSaleGoods({ orderId: order.jdOrderId }).then((res: any) => {
              setAfterSaleGoodsList(res?.value?.paginatedArrayList?.[0]?.skuList ?? []);
              setAfterSaleModalVisible(true);
            });
          }}
          onCancel={() => {
            setCancelModalVisible(true);
          }}
          onApprove={() => {
            setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
            setApproveModalVisible(true);
            log('jdisp', 'ApprovalList_Approve');
          }}
          onReject={() => {
            setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
            setRejectModalVisible(true);
            log('jdisp', 'ApprovalList_Reject');
          }}
          onRestore={() => {
            restore(order.jdOrderId, () => {
              onRestore();
            });
          }}
          onCountDown={() => onCancel()}
          onReceiptCountDown={() => onCancel()}
        />
      </Col>
      {cancelModal}
      {afterSaleModalVisible && afterSaleModal}
      {approveModal}
      {rejectModal}
      {/*查看审批进度*/}
      <ApproveProcess
        visible={auditModalVisible}
        order={order}
        //type={order.auditStatus == AuditStatus.auditing && userPin === order.pin ? 'approve' : 'seeFile'}
        type={'approve'}
        setAuditModalVisible={setAuditModalVisible}
        isShowApprove={true}
      />
    </Row>
  );
};

export default OrderItem;
