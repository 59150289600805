import React, { useCallback, useState, useEffect } from 'react';
import Dialog from 'rc-dialog';
import { Toast } from 'rc-toast';

import api from '../../api';
import { useRecoilValue, useSetRecoilState, useRecoilState } from '../../hooks';
import { Project } from '../../interface';

import styles from './index.module.scss';

const Create = () => {
  const setLoading = useSetRecoilState('loading');
  const setOrderList = useSetRecoilState('orderList');

  const [add, setAdd] = useRecoilState('add');

  const filter = useRecoilValue('filter');

  const [projectList, setProjectList] = useState<Array<Project>>();

  const [createProject, setCreateProject] = useState(false);

  const [purchName, setPurchName] = useState<string>();
  const [projectCode, setProjectCode] = useState<string | number>();
  const [projectName, setProjectName] = useState<string>();

  const handlePurchName = useCallback(event => setPurchName(event.currentTarget.value), []);
  const handleProjectCode = useCallback(event => setProjectCode(event.currentTarget.value), []);
  const handleProjectName = useCallback(event => setProjectName(event.currentTarget.value), []);

  const onClose = useCallback(() => {
    setAdd(false);
  }, []);

  const onSubmit = useCallback(() => {
    if (!purchName) {
      Toast.show({ msg: '请填写采购清单名称' });
      return;
    }
    if (createProject && !projectName) {
      Toast.show({ msg: '请填写项目名称' });
      return;
    }
    const params = createProject ? { projectName, purchName } : { projectCode, projectName, purchName };
    api('/cart/addPurchCart', params).then(res => {
      if (res) {
        Toast.show({ msg: '创建成功' });
        setAdd(false);
        setLoading(true);
        api('/cart/initPurchList', filter).then(res => {
          setLoading(false);
          setOrderList(res);
        });
      }
    });
  }, [createProject, purchName, projectCode, projectName]);

  const onSwitch = useCallback(() => {
    setCreateProject(!createProject);
  }, [createProject]);

  const className = [styles.submit, (!purchName || (createProject && !projectName)) && styles.disabled]
    .filter(Boolean)
    .join(' ');

  useEffect(() => {
    api('/cart/projectSel', {}).then(res => {
      const list = Object.keys(res)?.map(item => ({ projectCode: item, projectName: res[item] }));
      if (list.length > 0) {
        setProjectList(list);
        setProjectCode(list?.[0].projectCode);
        setProjectName(list?.[0].projectName);
      }
    });
  }, []);

  return (
    <Dialog closable={false} onClose={onClose} visible={add} prefixCls="carts-dialog" getContainer={false}>
      <div className={styles.m}>
        <div className={styles.mt}>
          <div className={styles.title}>新建采购清单</div>
          <div className={styles.close}></div>
        </div>
        <div className={styles.mc}>
          <div className={[styles.item, styles.name].join(' ')}>
            <div className={styles.label}>采购清单名称</div>
            <div className={styles.content}>
              <input type="text" placeholder="请填写采购清单名称" onChange={handlePurchName} />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>是否新建项目</div>
            <div className={styles.content}>
              <div
                className={[styles.switch, createProject && styles.on].filter(Boolean).join(' ')}
                onClick={onSwitch}
              ></div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>项目名称</div>
            <div className={styles.content}>
              {createProject ? (
                <input type="text" placeholder="请填写项目名称" onChange={handleProjectName} />
              ) : (
                <select onChange={handleProjectCode}>
                  {projectList?.map(item => (
                    <option key={item?.projectCode} value={item?.projectCode}>
                      {item?.projectName}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
        <div className={styles.mb}>
          <button type="submit" className={className} onClick={onSubmit}>
            确认创建
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default Create;
