/*
 * @Author: duchongchong1
 * @Date: 2023-08-31 19:55:45
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-07 18:33:27
 * @FilePath: /isp_International/src/pages/OrderInfo/components/Panel/index.tsx
 * @Description:  
 */
import React, { useEffect, useState } from 'react';
import { message, Button } from 'antd';
import { getApprovalByCondition, submitOrder } from '@/api/newApi/orderInfo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from '../../hooks';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';
import { getPersonInfo } from '@api/newApi/carts';
import Approval from '../Approval';
import { formatMoney } from '@/util';

interface Shipment {
  cartResp: {
    totalCount: number
    totalKindCount: number
  }
}

const Index = (props:any) => {
  const setApprovalList = useSetRecoilState('approvalList'); // 审批流程
  const consignee = useRecoilValue('consignee');
  const [others, setOthers] = useRecoilState('others');
  const orderRemarks = useRecoilValue('orderRemarks');
  const shippingtype = useRecoilValue('shippingtype');
  const shipment = useRecoilValue('shipment');
  const transfee = useRecoilValue('transfee');
  const condition = useRecoilValue('condition');
  const approval = useRecoilValue('approval');
  const taxfee = useRecoilValue('taxfee');
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isShowApprovalmoDal, setIsShowApprovalmoDal] = useState(false);

  const cartResp = (shipment as unknown as Shipment)?.cartResp

  useEffect(() => {
    getPersonInfo().then(res => {
      if (res?.value?.userType === 4) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    });
  }, [])
 

  const handleSubmit = async() => {
    if (!consignee) {
      message.error(t('OrderInfo.pleaseFillInformation'));
      return;
    }

    //采购单号配置项目下标
    const purchasOrderIndex = condition.findIndex((ele)=>ele?.id == -1);
    const submitCondition = JSON.parse(JSON.stringify(condition))  
    if(isLocalPro){
      submitCondition.splice(purchasOrderIndex, 1);
    }
    // 配送相关参数
    const params = {
      remark: orderRemarks,
      receiverId: consignee.id,
      tradeModel: others?.tradeModel,
      shippingType:shippingtype,
      tradeType:1,
      countyId:consignee?.countyId,
      configList: submitCondition,
      thirdOrderId: condition[purchasOrderIndex]?.value
    };

    if(isLocalPro){
      const mkuList = shipment?.productList ? shipment?.productList.map((ele)=>({sku:ele.sku, customsDeclaration:ele.isCustomsDeclaration})) : [];
      params.mkuList = mkuList; 
    }

    // 没选取审批流弹框选取 选了审批流直接提交
    if(!approval){
         //根据条件查询审批流
        const resData = await getApprovalByCondition({
          condition
        });

        if (resData.success) {
          setApprovalList(resData?.value);
          //查询到有审批流且审批流有多项就弹框选取审批流再提交
          if(resData?.value && resData?.value.length){
            if(resData?.value.length > 1){
              setIsShowApprovalmoDal(true);
              return 
            }else{
              params.processKey = resData?.value[0]?.processKey;
            }
           
          }
        }else{
          return message.error(resData.msg)
        }
    }else{
      params.processKey = approval;
    }
    if(isSubmitLoading){
      return;
    }
   
    setIsSubmitLoading(true);

    submitOrder(params).then((resp: any) => {
      const res = resp.value;
      setIsSubmitLoading(false);
      if (resp.success) {
        setOthers({
          ...others,
          paySuccess: true,
        });
      } else {
        setOthers({
          ...others,
          paySuccess: false,
        });
        message.error(resp.msg);
      }
    });
  };
  //多币种获取展示币种以及符号
  const curCartResp = shipment?.cartResp;
  const symbol = curCartResp?.totalPriceCurrencies?.showCurrency?.symbol;
  const totalPrice = isLocalPro ? curCartResp?.totalPriceCurrencies?.showCurrency?.price : curCartResp?.totalPriceCurrencies?.showCurrency?.salePrice;
  const includeTaxPrice = curCartResp?.totalPriceCurrencies?.showCurrency?.price + curCartResp?.totalPriceCurrencies?.showCurrency?.valueAddedTax; //商品含税总金额
  const shouldPayPrice = curCartResp?.totalPriceCurrencies?.showCurrency?.includeTaxPrice; //应付总金额
  return (
    <div className={styles.m}>
      <div className={styles.m_messagebox}>
        <div className={styles.box}>
          <div className={styles.mb}>
            
            <div>
              <div className={styles.feelabel}>{ t('OrderInfo.totalProduct') }  </div>
              <div className={styles.feePrice}>
                { t('OrderInfo.totalProductValue', { kind: cartResp?.totalKindCount, total: cartResp?.totalCount }) }
              </div>
            </div>
            
            <div>
              <div className={styles.mallPriceLabel}>{t('OrderInfo.shopTotalAmount')}  </div>
              <div className={styles.mallPrice}>{symbol}{totalPrice ? (isLocalPro ? formatMoney(totalPrice, false) :  totalPrice) : 0}</div>
            </div>

            <div>
              <div className={styles.feelabel}>{t('OrderInfo.taxFee')}   </div>
              <div className={styles.feePrice}>{symbol}{isLocalPro ? formatMoney(taxfee, false) : taxfee}</div>
            </div>

            <div>
              <div className={styles.label}>{t('OrderInfo.totalOrderAmount')}</div>
              <div className={styles.feePrice}>
                {symbol}
                {totalPrice ? (isLocalPro ? formatMoney(Number((+includeTaxPrice).toFixed(4)),false) : Number((+totalPrice + (+transfee) + (+taxfee)).toFixed(4))) : 0}
              </div>
            </div>

            <div>
              <div className={styles.feelabel} style={{"marginRight": 0}}>{t('OrderInfo.transeFee')}  </div>
              <div className={styles.feePrice} style={{"marginRight": '25px'}}>{symbol}{isLocalPro ? formatMoney(transfee, false) : transfee}</div>
            </div>
            {isLocalPro && <div>
              <div className={styles.feelabel} style={{"marginRight": 0}}>{t('orderDetail.estTotal')}  </div>
              <div className={styles.feePrice} style={{"marginRight": 0}}>{symbol}{isLocalPro ? formatMoney(shouldPayPrice, false) : shouldPayPrice}</div>
            </div>}

          </div>
          <div className={styles.mt}>
            <div className={styles.mt_content}>
              {t('OrderInfo.shippingTo')}
              {consignee?.detailAddress}
              {consignee?.areaAddress}
            </div>
          </div>
        </div>
        <div>
          <Button className={styles.submit} loading={isSubmitLoading} onClick={handleSubmit} disabled={disabled}>
            {t('OrderInfo.checkout')}
          </Button>
        </div>
      </div>
      <Approval isShow = {isShowApprovalmoDal} callback={(type:number)=>{
        if(type == 1){
          handleSubmit();
        }
        setIsShowApprovalmoDal(false)
      }}/>
    </div>
  );
};

export default Index;
