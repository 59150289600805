/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-04-18 10:39:12
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-18 16:32:11
 * @FilePath: /isp_International/src/pages/AddressAuthority/components/TableArea/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Pagination, message, Modal, Checkbox, Table } from 'antd';

import UserCenterMenu from '@/components/UserCenterMenu';
import CommonBasicHeader from '@/components/CommonBasicHeader';
import CpAddressList from '@/components/Address/List/index';
import Item from './Item';
import { PAGE_SIZE_OPTIONS } from '@/enum/config';
import { Input } from 'antd';
import SearchEmpty from '@/assets/images/accountSettings/icon_search_empty.png';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';



const AddressAuthorityIndex = (props)=>{
    const { dataSource, changeStatus } = props;
    const { t } = useTranslation();
    const onChange = (e, id)=>{
       changeStatus && changeStatus(e,id);
    }
    return (
        <div className={styles.index}>
            <div className={styles.header}>
                <Col className={styles.accountName}>{t('addressAuthority.accountName')}</Col>
                <Col className={styles.accountType}>{t('addressAuthority.accountType')}</Col>
                <Col className={styles.addressOpen}>{t('addressAuthority.addressAuth')}</Col>
            </div>
            <div className={styles.table}>
                { !!dataSource.length && dataSource.map((ele, idx)=><Item data={ele} key={idx} onChange={(e)=>onChange(e, ele)}></Item>) }
            </div>
        </div>
    )
}

export default AddressAuthorityIndex