/*
 * @Author: your name
 * @Date: 2021-11-01 16:02:56
 * @LastEditTime: 2021-12-07 14:00:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /pc-iop/src/pages/OtherCarts/index.tsx
 */
import React from 'react';
import { RecoilRoot } from 'recoil';
import 'whatwg-fetch';

import Add from './components/Add';
import Filter from './components/Filter';
import List from './components/List';
import Remove from './components/Remove';
import Tabs from '@/components/Tabs';

import styles from './index.module.scss';
import './rc.scss';

const Index = () => {
  return (
    <div className={styles.m}>
      <div className={styles.mt}>
        <Tabs />
      </div>
      <div className={styles.mc}>
        <Filter />
        <Add />
        <List />
        <Remove />
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Index />
    </RecoilRoot>
  );
};

export default App;
