import React, { ReactNode, ReactText } from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.scss';
interface Props {
  title: ReactNode | ReactText;
  children?: ReactNode | ReactText;
}
const CommonBasicHeader = ({ title, children }: Props) => {
  return (
    <Row justify="space-between" className={styles.header} align="middle">
      <Col>
        <div className={styles.headerTitle}>{title}</div>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

export default CommonBasicHeader;
