/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-30 11:55:30
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-09 21:20:12
 * @FilePath: /isp_pro/src/pages/SettleConfig/components/EnumModal/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Button, Input, Upload, message,Drawer } from 'antd';
import Empty from '@/components/common/Empty';
import React, { useState } from 'react';
import { deleteEnum, insertEnum } from '@/api/newApi/settleConfig';
import { DeleteOutlined, LoadingOutlined,UploadOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import Icon from '@/components/common/Icon';
import { apiJdigUrl } from '@/enum/config';
import { Decrypt } from '@/api/AES';
import { useTranslation } from 'react-i18next';
import { getJdiOSFile } from '@/util/index'

interface IAppProps {
  visible: boolean;
  modalType?: any;
  handleCancel: Function;
  data: Record<string, any>;
}
const EnumModal: React.FC<IAppProps> = (props: any) => {
  const { visible, data, handleCancel, modalType } = props;
  const [inputValue, setInputValue] = useState(''); //设置默认值
  const [searchValue, setSearchValue] = useState(''); //设置默认值
  const [loading, setLoadng] = useState<boolean>(false);
  const [inputOnChange,setInputOnChange] = useState(false);
  const { t } = useTranslation();
  const downloadUrl = getJdiOSFile('pmethctab1/wisp_enumeration.xlsx') //下载模版
  const maxSize = 10; //文件大小
  const accept = '.xlsx,.csv,.xls'; //接受文件类型
  const fileTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'application/vnd.ms-excel',
  ]; //接受文件类型
  const [enumList, setEnumList] = useState(() => {
    return data.enums || [];
  });
  const addEnumFun = async () => {
    if (!inputValue) {
      return;
    }
    const res: any = await insertEnum({ functionId: data.id, value: inputValue });
    if (res?.success) {
      setEnumList(res?.value || []);
      setInputValue('');
    }else{
    message.error(res?.msg);
    }
  };
  const deleteEnumFun = async (item: any) => {
    const res: any = await deleteEnum({ functionId: data.id, value: item?.value });
    if (res?.success) {
      setEnumList(res?.value || []);
    }
  };
  //上传文件
  const beforeUpload = (file: any) => {
    const isJpgOrPng = fileTypes.indexOf(file.type) > -1;
    if (!isJpgOrPng) {
      message.error(`${t('configManageList.modal.fileFormatError')}${accept}${t('configManageList.modal.file')}`);
    }
    const isLt10M = file.size / 1024 / 1024 < maxSize;
    if (!isLt10M) {
      message.error(`${t('configManageList.modal.fileSizeLimit')}${maxSize}MB!`);
    }
    return isJpgOrPng && isLt10M;
  };
  const handleUpload = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadng(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoadng(false);
      const response = info.file.response;
      if (response.success) {
        const value = JSON.parse(Decrypt(response.value));
        const newArr = [...(value?.list || [])];
        message.success(`${t('configManageList.modal.batchAdd')}${value?.successCount}个选项`);
        setEnumList(newArr || []);
      } else {
        message.error(response.msg || t('configManageList.modal.uploadFailed'));
      }
    }
  };
  const handleClose = () => {
    const arr = [...enumList];
    handleCancel(arr);
  };
  const handleInputValue = (e: any) => {
    const value = e.target.value.trim();
    if(value) {
      const arr = enumList.filter((v: any) => v.value.indexOf(value) > -1);
      setEnumList(arr);
    }else {
      setEnumList(data.enums);
    }
  }
  return (
    <Drawer
      title={`${t('configManageList.modal.configOption')}(${enumList.length}/600)`}
      open={visible}
      placement="right"
      className={styles.enumModal}
      width={800}
      onClose={handleClose}
      closeIcon={<i className='iconFont icon-platform_close' style={{color:'#8C8D95',marginTop:'10px', fontSize:"18px"}}/>}
      footer={''}
    >
      <div className={styles.container}>
        {modalType === 'editor' && (
          <div className={styles.headBox}>
            <div className={styles.headLeft}>
              <p>{t('configManageList.modal.singleConfig')}</p>
              <div className={styles.headLeftInput}>
                <input
                  placeholder={t('configManageList.modal.enterConfigOption')}
                  value={inputValue}
                  maxLength={100}
                  onChange={(e: any) => {
                    setInputValue(e.target.value.trim());
                  }}
                />

                <Button
                  onClick={addEnumFun}
                  type="primary"
                  disabled={!inputValue}
                >
                  {t('configManageList.modal.add')}
                </Button>
              </div>
            </div>
            <div className={styles.headRight}>
              <div className={styles.headRightTittle}>
                <span> {t('configManageList.modal.batchConfig')}</span>
                <span className={styles.titleHref}>
                {t('configManageList.modal.use')} <a href={downloadUrl}>{t('configManageList.modal.template')}</a>{t('configManageList.modal.upload')}
                </span>
              </div>
              <Upload
                action={`https://${apiJdigUrl}/jdig/settleConfig/batchImportItem?functionId=${data.id}`}
                accept={accept}
                showUploadList={false}
                beforeUpload={beforeUpload}
                withCredentials={true}
                onChange={handleUpload}
              >
                {loading ? (
                  <Button className={styles.uploadBtn} disabled icon={<LoadingOutlined />}>
                   {t('configManageList.modal.uploadFile')}
                  </Button>
                ) : (
                  <Button
                    className={styles.uploadBtn}
                    ghost
                    icon={<UploadOutlined style={{ margin: '0 8px -3px 0',fontSize: '16px' }} />}
                  >
                   {t('configManageList.modal.uploadFile')}
                  </Button>
                )}
              </Upload>
            </div>
          </div>
        )}
        <div style={{ position: 'relative' }}>
          {modalType === 'editor' && <div className={styles.hint}>{t('configManageList.modal.uploadedFile')}</div>}
          <Input
            size={'small'}
            placeholder={t('configManageList.modal.enterConfigOptionName')}
            value={searchValue}
            maxLength={100}
            prefix={<i className='iconFont icon-navbar_search' style={{color:'#999999', fontSize:"16px"}}/>}
            onPressEnter={(e: any) => handleInputValue(e)}
            onChange={(e: any) => {
              setSearchValue(e.target.value.trim());
              if(!inputOnChange) {
                handleInputValue(e);
              }
  
            }}
            onCompositionStart={(e: any) => {
              setInputOnChange(true)
            }}
            onCompositionEnd={(e: any) => {
              setInputOnChange(false);
              handleInputValue(e);

            }}
          />
          {/*<Icon icon={iconSearch} style={{ position: 'absolute', left: '10px', top: '32px', margin: '0 5px -3px 0' }} />*/}
        </div>

        {enumList.length > 0 && (
          <div className={styles.list} style={{height: modalType === 'editor' ? 'calc(100% - 20px)' : 'calc(100% + 50px)'}}>
            {enumList.map((item: any, index: any) => {
              return (
                <div key={index} className={styles.item}>
                  <p>{item.value}</p>
                  {modalType === 'editor' && (
                    <i className='iconFont icon-navbar_label_close' style={{color:'#BCC0C3', fontSize:"12px",cursor:'pointer'}} onClick={() => deleteEnumFun(item)}/>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {Number(enumList?.length) === 0 && <Empty content={t('configManageList.modal.noConfigOptions')} />}
      </div>
    </Drawer>
  );
};
export default EnumModal;
