/*
 * @Author: your name
 * @Date: 2021-11-30 17:16:40
 * @LastEditTime: 2021-11-30 18:20:32
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/OtherCarts/components/Remove/index.tsx
 */
import React, { useCallback } from 'react';
import Dialog from 'rc-dialog';
import { Toast } from 'rc-toast';

import api from '../../api';
import { useRecoilState, useSetRecoilState, useRecoilValue } from '../../hooks';
import { getClstag } from '@/util/index';

import styles from './index.module.scss';

const Remove = () => {
  const setLoading = useSetRecoilState('loading');
  const setOrderList = useSetRecoilState('orderList');

  const [remove, setRemove] = useRecoilState('remove');

  const filter = useRecoilValue('filter');

  const handleSubmit = useCallback(() => {
    api('/cart/delPurchCart', {
      purchCode: remove?.purchCode,
      delFlag: 0,
    }).then(success => {
      if (success) {
        setRemove(undefined);
        Toast.show({ msg: '删除成功' });
        setLoading(true);
        api('/cart/initPurchList', filter).then(res => {
          setLoading(false);
          setOrderList(res);
        });
      }
    });
  }, [remove]);

  const handleClose = useCallback(() => {
    setRemove(undefined);
  }, []);

  return (
    <Dialog
      title={
        <>
          <i />
          <em>删除采购单</em>
        </>
      }
      onClose={handleClose}
      visible={!!remove}
      prefixCls="carts-dialog"
      footer={
        <>
          <button onClick={handleClose} clstag={getClstag(11, 'Cart')}>
            再想想
          </button>
          <button className="primary" onClick={handleSubmit} clstag={getClstag(10, 'Cart')}>
            确认删除
          </button>
        </>
      }
      getContainer={false}
    >
      <div className={styles.m}>
        <div className={styles.mt}>
          确认删除 <b>{remove?.purchName}</b> 吗？
        </div>
      </div>
    </Dialog>
  );
};

export default Remove;
