/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-16 16:04:06
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-16 16:04:19
 * @FilePath: /isp_International/src/pages/MaterialCode/atoms/showModal.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { atom } from 'recoil';

const showModal = atom<boolean>({
  key: 'showModal',
  default: false,
});

export default showModal;
