/*
 * @Author: weiyanna
 * @Date: 2021-09-16 17:07:41
 * @LastEditTime: 2024-05-23 13:53:20
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_International/src/enum/config.ts
 */

const REACT_ENV = process.env.REACT_ENV;
// 预发接口
let apiUrl = 'api-buy-pre.jdindustry.com';
if(REACT_ENV == 'prod'){
  // 线上接口
  apiUrl = 'api-buy.jdindustry.com';
}

export const apiJdigUrl = apiUrl;
export const locationUrl = 'isp.jd.com';
export const apiLocationUrl = 'api-isp.jd.com';
export const jolUrl = 'https://jol-isp.jd.com';

export const domainCookieUrl = '.jdindustry.com';
// export const domainCookieUrl = '.jd.com';
console.log(REACT_ENV)
//还款状态
export const CYCLE_REPAY_STATUS: any = {
  20061: '未还款',
  20062: '已还款',
  20063: '已退款',
  5001: '正常',
  5002: '延期',
  5003: '逾期',
  5004: '已结清',
  5005: '延期已结清',
  5006: '逾期已结清',
  2500: '未结清',
  2501: '已结清',
  20027: '未还',
  20028: '部分已还',
  20029: '逾期',
  20030: '已结清',
};

//订单状态
export const ORDER_STATUS: any = {
  20067: '未妥投/未完成',
  20068: '已妥投/已完成',
  20219: '已退货',
};

//入账状态
export const ACCOUNT_STATUS: any = {
  20021: '已入账',
  20022: '未入账',
};

//账户状态
export const LOCK_STATUS: any = {
  1: '使用中',
  0: '已冻结',
};

//页面配置项
export const PAGE_SIZE_OPTIONS = ['30', '50', '100'];

//用户类型

export const USER_TYPE: any = {
  1: '母账号',
  2: '子账号',
  3: '平行账号',
  4: '管理员',
  5: '采购账号',
  6: '审批账号',
  7: '新金采管理员',
  8: '审查账号',
  9: '财务账号',
};
//支付方式
export const PAY_WAY: any = {
  1: '货到付款',
  2: '邮局付款',
  4: '在线余额',
  5: '对公转账',
  6: '银行转账',
  12: '月结',
  101: '京东金采',
  102: '京东金采',
  14: '快捷支付',
};
//配送方式
export const SHIPMENT_TYPE: any = {
  64: '自提',
  65: '京东配送',
  66: '第三方配送',
  67: 'sop配送',
  68: '转移交付',
};
//开票方式
export const INVOICE_PUT_TYPE: any = {
  1: '随货开票',
  2: '集中开票',
  4: '订单完成后开票',
  0: '订单预借',
};
//发票类型
export const INVOICE_TYPE: any = {
  1: '普通发票',
  2: '增值税专用发票',
  3: '电子普通发票',
};
//发票内容
export const INVOICE_CONTENT: any = {
  1: '明细',
  3: '电脑配件',
  19: '耗材',
  22: '办公用品',
  44: '京东E卡',
  45: '预付卡',
  46: '购物卡',
  47: '礼品卡',
  48: '预付卡销售和充值',
  58: '文具',
  100: '大类',
};
//地址类型
export const ADDRESS_CREATE_TYPE: any = [
  {
    key: '1',
    value: '公共地址',
  },
  {
    key: '2',
    value: '自建',
  },
];

//地址可见类型
export const ADDRESS_ALL_CTYPE: any = {
  1: '仅本账号可见',
  2: '全部账号可见',
  3: '部分账号可见',
};
//增票资质状态
export const APPROVE_STATUS: any = {
  0: '暂存',
  1: '待审核',
  2: '审核通过',
  3: '审核驳回',
  4: '中断',
};
//商品状态
export const SKU_IS_SPECIAL: any = {
  1000: '该商品不在您的专属商品池中，无法进行采购。请点击“申请入池”，获取该商品采购权限，或者联系客户经理申请',
  1001: '抱歉，该商品已下架，请选择其他商品',
  1002: '抱歉，由于相关政策，该商品目前限制购买，请选择其他商品。',
  1003: '抱歉，该商品在所选地区暂时无法配送。',
  1004: '该商品暂不可售，如需购买，烦请联系客户经理。',
  1005: '该商品暂不可售，如需购买，烦请联系客户经理。',
};

//服务提醒
export const SERVICE_TIPS: any = {
  QYYX:
    '企悦优选为用户打造统一、标准化的服务体验，将服务产品化，形成覆盖零售领域全链条服务、具备专业知识的新一代服务生态平台。服务产品内容包括：安装服务、清洗保养、检测维修等，覆盖家电、手机数码、电脑办公、居家生活、服饰等商品。此服务的费用分摊到主商品中，开主品发票。',
  QYYC:
    '企悦原厂是指由品牌厂商提供增值保障服务产品，同时品牌授权服务商进行服务产品履约维修，并且提供原厂配件、认证工程师等专业服务。产品内容包含：延长保修、只换不修、意外保护等，覆盖家电、手机数码、电脑办公等品类商品。此服务的费用分摊到主商品中，开主品发票。',
  QYBZ:
    '企悦保障是指凡在京东购买商品或服务的消费者，在保障期间内遇到无论是正常使用中的问题还是意外事故，即可享受增值保障服务。保障内容包括：延长保修、只换不修、意外保护、服务保障，覆盖家电、手机数码、电脑办公、汽车用品、服饰家居等商品。此服务的费用分摊到主商品中，开主品发票。',
};

//角色类型
export const ROLE_KIND: any = {
  '1': '标准角色',
  '2': '自定义角色',
};
//申请单SKU入池状态(列表)
export const APPLY_INPOOL_STATUS: any = {
  1: '待审核',
  2: '审核中',
  3: '部分失败',
  4: '失败',
  5: '成功',
  6: '已取消',
};

//申请单SKU入池状态
export const ENTRY_POOL_STATUS: any = {
  1: '成功',
  2: '待审核',
  3: '失败',
  //4: '驳回',
  5: '审核中',
  6: '取消入池',
};
