/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-04-15 18:04:25
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-25 09:23:27
 * @FilePath: /isp_International/src/pages/Approval/Create/constants/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// 页面操作类型 1新增 2修改 3详情
export const PAGE_TYPE = {
  addPage: 1,
  modifyPage: 2,
  detailPage: 3,
};

// 节点类型
export const NODE_TYPE = {
  nodePurchase: 1, // 采销员类型
  nodeApprove: 2, // 审批员类型
  nodeCondition: 3, // 审批条件类型
  nodeAddCondition: 4, // 添加审批条件类型
};

// 优先级
export const PriorityList = [
  // 优先级一
  {
    value: 1,
    content: '优先级一',
  },
  {
    value: 2,
    content: '优先级二',
  },
  {
    value: 3,
    content: '优先级三',
  },
  {
    value: 4,
    content: '优先级四',
  },
  {
    value: 5,
    content: '优先级五',
  },
  {
    value: 6,
    content: '优先级六',
  },
  {
    value: 7,
    content: '优先级七',
  },
  {
    value: 8,
    content: '优先级八',
  },
  {
    value: 9,
    content: '优先级九',
  },
  {
    value: 10,
    content: '优先级十',
  },
];

// 添加账号抽屉类型
export const ACCOUNT_DRAWER_TYPE = {
  addAccountNode: 1, // 增加节点
  modifyAccountInfo: 2, // 修改审批信息
};

//条件弹框类型
// modifyCondition 添加条件， 修改条件 addNode 添加条件节点
export const CONDITION_DRAWER_TYPE = {
  addConditionNode: 1, // 请选择新增类型 审批条件
  copyConditionNode: 2, //  添加条件（1/3）
  modifyCondition: 3, // 管理条件
};
// 条件个数的限制
export const CONDITION_LIMIT = 10;

// 审批流程类型
export const PROCESS_TYPE = {
  noCondition: 1,
  oldHaveCondition: 2,
  haveCondition: 3,
};

// 后端所需数据 账号类型 (5:采购账号，6：审批账号，8：审查账号)
export const ACCOUNT_TYPE = {
  purchaser: 5, //5:采购账号
  approver: 6, //6：审批账号
  examiner: 8, //8：审查账号
};
