/*
 * @Author: weiyanna
 * @Date: 2021-12-13 16:07:15
 * @LastEditTime: 2021-12-13 16:59:03
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/redux/reducer/SkuDetail.ts
 */
// 更新数据
const INITIAL_STATE = {
  checkedService: [], //选中的服务
};

const updateCheckedService = (state: any, action: any) => {
  return {
    ...state,
    checkedService: action.checkedService,
  };
};

export default function getSkuDetailData(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case 'UPDATE_CHECKEDSERVICE':
      return updateCheckedService(state, action);
    default:
      return state;
  }
}
