import React, { useEffect, useState } from 'react';
import { message, Button, Card, Radio, Select, Input } from 'antd';
import { freightCalculate } from '@/api/newApi/orderInfo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from '../../hooks';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { getPersonInfo } from '@api/newApi/carts';
import { withSuccess } from 'antd/lib/modal/confirm';

const Index = () => {
 const { t } = useTranslation();
 const [conditionList] = useRecoilState('conditionList');
 const setConditionList = useSetRecoilState('conditionList'); // 条件列表
 const [condition] = useRecoilState('condition'); // 设置条件
 const setCondition = useSetRecoilState('condition'); // 条件列表


 //表单项目=修改
 const changeHanlder = (ele, val) => {
    const selected = condition.findIndex((item)=>item.id === ele.id);
    const item = {
        id: ele.id,
        category: ele.category,
        value: val
    };
     if(selected == -1){
        condition.push(item)
     }else{
        condition[selected] = item;
     }
 }

 return (<>{ conditionList && !!conditionList.length &&
    <Card title='采购信息备注' bordered={false} className={styles.m}>
    <div className={styles.Condition}>
        {
         conditionList.map((ele, index)=><div key={index} className={styles.contain}>
                <div className={styles.title}>{ele.category}<span className={styles.star}>*</span></div>
                <div className={styles.inpwrapper}>
                    {
                            ele?.enums?.length ?
                            <Select 
                                style={{
                                    width:"100%"
                                }}
                                onChange={(val)=>{
                                    const item = ele.enums.filter((item)=>item.id == val)
                                    const newList:any = [...conditionList];
                                    const newEle = {...ele};
                                    newEle.text = val;
                                    const index = newList.findIndex((ele)=>ele.id == newEle.id)
                                    if(index > -1) {
                                        newList[index] = newEle;
                                    }
                                    setConditionList(newList)
                                    changeHanlder(ele, item[0].value)
                                }}
                            >
                            {ele?.enums
                            .map((item, i) => <Select.Option key={item.id}>{item.value}</Select.Option>)
                            }
                        </Select>: <Input  onChange={(e)=>{
                            changeHanlder(ele, e.target.value)
                            const newList:any = [...conditionList];
                            const newEle = {...ele};
                            newEle.text = e.target.value;
                            const index = newList.findIndex((ele)=>ele.id == newEle.id)
                            if(index > -1) {
                                newList[index] = newEle;
                            }
                            setConditionList(newList)
                        }}/>
                    }
                    {!ele.text && <div className={styles.error}>{t('OrderInfo.please')} {ele.category}</div>}
                </div> 
        </div>) 
        }
    </div>
   
 </Card>
    
 }</>)
}

export default Index;
