import React, { useCallback, useEffect } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toast } from 'rc-toast';

import api from '../../api';
import { useRecoilValue, useSetRecoilState, useRecoilState } from '../../hooks';
import { getClstag } from '@/util/index';

import styles from './index.module.scss';

const List = () => {
  const setRemove = useSetRecoilState('remove');

  const [loading, setLoading] = useRecoilState('loading');
  const [orderList, setOrderList] = useRecoilState('orderList');

  const filter = useRecoilValue('filter');

  const requestData = useCallback(() => {
    setLoading(true);
    api('/cart/initPurchList', filter).then(res => {
      setLoading(false);
      setOrderList(res);
    });
  }, [filter]);

  const handleBack = useCallback(id => {
    api('/cart/purchreset', {
      purchCodes: id,
    }).then(res => {
      if (res) {
        Toast.show({ msg: '还原成功' });
        requestData();
      }
    });
  }, []);

  const handleTop = useCallback(id => {
    api('/cart/purchListTop', {
      purchCode: id,
    }).then(res => {
      if (res) {
        Toast.show({ msg: '置顶成功' });
        requestData();
      }
    });
  }, []);

  useEffect(() => {
    requestData();
  }, [filter]);

  let content = (
    <ul className={styles.mc}>
      {orderList?.map(item => (
        <li key={item.purchCode} className={styles.item}>
          <div className={styles.code} clstag={getClstag(6)}>
            {item.purchCode}
            <CopyToClipboard
              text={item.purchCode}
              onCopy={() => {
                Toast.show({ msg: '已复制' });
              }}
            >
              <em>复制</em>
            </CopyToClipboard>
          </div>
          <div className={styles.name}>{item.purchName}</div>
          <div className={styles.pname}>{item.projectName}</div>
          <div className={styles.amount}>{item.skuNum}</div>
          <div className={styles.price}>¥ {item.totalPriceString}</div>
          <div className={styles.pin}>{item.createPin}</div>
          <div className={styles.status}>{item.status === 1 ? '有效' : <em>无效</em>}</div>
          <div className={styles.date}>{item.createTime && item.createTime.split(' ')[0]}</div>
          <div className={styles.operate}>
            <a href={`/cart/carts?purchCode=${item.purchCode}`} className={styles.link} clstag={getClstag(7)}>
              查看
            </a>
            {item.status === 1 ? (
              <div className={styles.top} onClick={() => handleTop(item.purchCode)} clstag={getClstag(8)}>
                置顶
              </div>
            ) : (
              <div className={styles.top} onClick={() => handleBack(item.purchCode)}>
                还原
              </div>
            )}
            <div
              className={styles.delete}
              onClick={() => {
                setRemove(item);
              }}
              clstag={getClstag(9)}
            >
              {item.status === 1 ? '删除' : '彻底删除'}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
  if (loading) {
    content = <div className={styles.message}>数据加载中...</div>;
  } else if (orderList?.length === 0) {
    content = <div className={styles.message}>未找到符合条件的采购单...</div>;
  }

  return (
    <div className={styles.m}>
      <ul className={styles.mt}>
        <li>采购清单编号</li>
        <li>采购单名称</li>
        <li>所属项目</li>
        <li>商品数量</li>
        <li>采购总额</li>
        <li>创建人</li>
        <li>状态</li>
        <li>创建时间</li>
        <li>操作</li>
      </ul>
      {content}
    </div>
  );
};

export default List;
