/*
 * @Author: gongshaoxu1
 * @Date: 2022-11-07 09:30:26
 * @LastEditTime: 2024-01-02 17:31:58
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_International/src/api/newApi/skudetail.ts
 */
import http from '@api/axios';
import cookies from 'react-cookies';
/***
 * POST 查询商品大字段接口
 */
export function getInitBigField2(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/product/queryBigField', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 * POST查询商品基本信息
 */
export function getInitBasicInfo2(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/product/queryBaseInfo', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 * POST 查询商品基本属性
 */
export function getInitProductAttr2(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/product/queryProductAttr', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 * POST 查询相似商品列表
 */
export function getInitSimilarProduct2(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/product/querySimilarProduct', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 * POST 查询面包屑
 */
export function getInitProductCategory2(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/product/queryProductCategory', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
/**
 * json post 获取商品详情
*/
export function getProDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/pro/info', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
/**
 * json post 获取相似品
*/
export function getProGroup(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/pro/group', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
/***
 * GET 添加采购清单
 */
export function addCarMku2(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/addProducts', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 * GET 立即购买
 */
export function addBuyMku2(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/trade/ispBuyNow', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 * GET 添加采购清单之前验证是否可售
 */
export function addCarBefore2(params: any) {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    http('get', '/mku/canBuy', params).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/***
 *  面包屑数据格式化
 */
export function breadCrumbDataFormat(data: Record<string, any>) {
  const stationType = cookies.load('stationType');
  const result = [];
  if (data?.firstCategoryCode) {
    result.push({
      id: data?.firstCategoryCode,
      name: data?.firstCategoryName,
    });
  }
  if (data?.secondCategoryCode) {
    result.push({
      id: data?.secondCategoryCode,
      name: data?.secondCategoryName,
    });
  }
  if (data?.thirdCategoryCode) {
    result.push({
      id: data?.thirdCategoryCode,
      name: data?.thirdCategoryName,
    });
  }
  if(stationType == '0'){
    if (data?.fourthCategoryCode) {
      result.push({
        id: data?.fourthCategoryCode,
        name: data?.fourthCategoryName,
      });
    }
  }
  return result;
}

/**
 * json post 获取单个mku货期
 */
export function getDeliveryDate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/stock/deliveryDate', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}