import { atom } from 'recoil';

import { Order } from '../interface';

const orderList = atom<Array<Order> | undefined>({
  key: 'orderListOtherCarts',
  default: undefined,
});

export default orderList;
