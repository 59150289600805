/*
 * @Author: duchongchong1
 * @Date: 2022-10-26 11:29:29
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-28 14:45:34
 * @FilePath: /isp_International/src/i18n/index.ts
 * @Description:
 */
import { getChangeEnv } from '@/api/newApi/header';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import zh from './locales/zh.json';
import vi from './locales/vi.json';
import hu from './locales/hu.json';
import ptBR from './locales/pt-BR.json';

import moment from 'moment';

const initI18n = (callback: () => void) => {
  const lastLanguage = localStorage.getItem('_language'); //上次设置语言
  const languageMap:any = {
    'zh-CN': 'zh',
    'en-US': 'en',
    'vi-VN': 'vi',
    'hu': 'hu',
    'pt-BR': 'pt-BR',
  };
  const userLanguage = navigator.language;// 用户浏览器默认语言
  
  //初始化
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
      {
        resources: {
          vi: { translation: vi },
          en: { translation: en },
          zh: { translation: zh },
          hu: { translation: hu },
          'pt-BR': { translation: ptBR },
        },
        lng: lastLanguage || languageMap[userLanguage] || 'zh', //设置默认值
        fallbackLng: 'en',
        preload: ['zh', 'en', 'vi', 'hu', 'pt-BR'],
        interpolation: {
          escapeValue: false,
        },
      },
      () => {
        // 暂时只有首页会有初次进入页面，网关中英文状态不对
        if (location.pathname === '/') {
          //巴西语 pt-BR 后端使用pt_br
          getChangeEnv({ env: i18n.language as 'en' | 'zh' | 'vi'| 'hu' | 'pt-BR' == 'pt-BR' ? 'pt_BR': i18n.language, reqStartTime: new Date().getTime() })
            .then((res) => {
                console.log(res?.value?.sendResStartTime,'sendResStartTime')
                console.log(new Date().getTime(),'restime')
              callback();
            })
            .catch(() => {
              callback();
            });
        } else {
          callback();
        }
      },
    );
};

export default initI18n;
