/*
 * @Author: duchongchong1
 * @Date: 2022-10-31 18:30:27
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-29 15:46:13
 * @FilePath: /isp_International/src/pages/NewHome/index.tsx
 * @Description:
 */
import React, { useEffect, useState } from 'react';
import NewSearch from '@/components/NewSearch';
import styles from './index.module.scss';
import './bullet.css';
const NewHome: React.FC = (props: any) => {
  // 浏览器的高度 默认设置为0；
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const resizeUpdate = (e: any) => {
    // 通过事件对象获取浏览器窗口的宽度
    const w = e.target.innerWidth;
    setWidth(w);
    // 通过事件对象获取浏览器窗口的高度
    const h = e.target.innerHeight;
    setHeight(h);
  };
  useEffect(() => {
    // 页面刚加载完成后获取浏览器窗口的大小
    const w = window.innerWidth;
    setWidth(w);
    const h = window.innerHeight;
    setHeight(h);
    // 页面变化时获取浏览器窗口的大小
    window.addEventListener('resize', resizeUpdate);

    return () => {
      // 组件销毁时移除监听事件
      window.removeEventListener('resize', resizeUpdate);
    };
  }, []);
  useEffect(() => {
    if (height - 186 > 0 || height < 874) {
      document.getElementById('home').parentNode.style.overflowY = 'hidden';
    } else {
      document.getElementById('home').parentNode.style.overflowY = 'auto';
    }
    return () => {
      return;
    };
  }, [height]);

  return (
    <div className={`${styles.app}`} id="home">
      <NewSearch {...props} width={width} height={height} style={{ height: height < 874 && 726 }} />
    </div>
  );
};

export default NewHome;
