import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from '../../index.module.scss';
import { Button, Input, message } from 'antd';
import { getverifyCode } from '@/api/updatePassword/api';

import { Step1PropsType } from '../../types';

export default (props: Step1PropsType) => {
  const { onChange, token } = props;
  const input1 = useRef();
  const input2 = useRef();
  const [VCUrl, setVCUrl] = useState<string>('');

  const nextStep = () => {
    try {
      const pin = input1?.current?.input?.value;
      const verifyCode = input2?.current?.input?.value;
      if (!pin || !verifyCode) {
        message.error('请填写账号名和验证码！');
        return;
      }
      const params = window.btoa(window.encodeURIComponent(JSON.stringify({ pin, verifyCode, token })));
      onChange && onChange({ params: params });
    } catch (e) {
      console.log(e);
    }
  };

  // get verification code
  const getVC = useCallback(async () => {
    try {
      if (!token) return;

      const res = await getverifyCode(token);
      if (res?.success) {
        // @ts-ignore
        setVCUrl(res?.value);
      }
    } catch (e) {
      console.log(e);
    }
  }, [token]);

  // when change verification code
  const changeVC = () => {
    getVC();
  };

  useEffect(() => {
    getVC();
  }, []);

  return (
    <div className={`${styles.step1} ${styles.step_common}`}>
      <div className={styles.step_common_form}>
        <div className={styles.step_common_form_box}>
          <div className={styles.step_common_form_box_title}>账号名:</div>
          <Input ref={input1} placeholder="请输入账号名" className={styles.step_common_form_box_input} />
        </div>
        <div className={styles.step_common_form_box}>
          <div className={styles.step_common_form_box_title}>验证码:</div>
          <Input
            ref={input2}
            placeholder="请输入验证码"
            className={`${styles.step_common_form_box_input} ${styles.step1_verification_code_input} `}
          />
          {VCUrl.length > 1 && (
            <div className={styles.step1_verification_code}>
              <img src={VCUrl} />
              <p onClick={changeVC}>换一张</p>
            </div>
          )}
        </div>
        <div className={styles.step_common_form_box}>
          <div className={styles.step_common_form_box_title}></div>
          <Button className={styles.step_common_form_box_button} onClick={nextStep}>下一步</Button>
        </div>
      </div>

    </div>
  );
}