import React,{ useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {useSelector} from "react-redux";
import { ExclamationCircleOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Menu,Dropdown,Button,Pagination, Form, message,Input,Modal,Checkbox } from 'antd';
import moment from "moment";
import {queryProcess, delProcess} from "@/api/newApi/approval";
import UserCenterMenu from '@/components/UserCenterMenu';
// import UploadFiles from "@/components/UploadV3/Modal";
// import FootSlogan from '@/components/FootSlogan';
import { apiLocationUrl } from '@/enum/config';
// import { downloadFailResult } from '@/api/processList';
import Select from './Select'
import Tooptip from '@/components/common/Tooltip';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
const ApprovalList: React.FC = () => {

  const history = useHistory();
  //文件上传
  const [uploadFileModal,setUploadFileModal] = useState<boolean>(false);
  const [fileUploadStatue,setFileUploadStatue] = useState<object>({
    statue:false,
    id:''
  });
  //输入框
  const [params,setParams] = useState({type:1,value:""});
  const [searchParams,setSearchParams] = useState({});
  const [refresh,setRefresh] = useState<boolean>(false);
  const [allChoice,setAllChoice] = useState<boolean>(false);
  const [choiceApprovalCount,setChoiceApprovalCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);//数据总个数
  const [approvalItems,setApprovalItems] = useState<object[]>([]);
  const [totalPage,setTotalPage] = useState<number>(0);
  const [form] = Form.useForm();
  //const [open,setOpen] = useState(false);
  //const [delCount,setDelCount] = useState(0);
  const [delApprovalList,setDelApprovalList] = useState([]);
  //const [singleDel,setsingleDel] = useState(false);
  const userData: any = useSelector(state => {
    return state?.User?.data[0];
  });
  const [processType,setProcessType] = useState('');
  const { t } = useTranslation();

  // console.log(userData);
  //分页数据获取
  const getData = (pageNumber: any,searchParams: any) => {
    queryProcess({cutPage:pageNumber,pageSize,...searchParams}).then((data: any)=>{
      const list = data?.value?.items ? data?.value?.items : [];
      const totalPage = data?.value?.totalPage ? data?.value?.totalPage : 0;
      const count = data?.value?.totalItem? data.value.totalItem:0;
      list.forEach((item: any)=>{
        item.checked = false;
      })
      setTotalPage(totalPage);//总页数
      setAllChoice(false);//全选
      setApprovalItems(list);//当前列表项
      setTotal(count);//总个数
    }).catch(err=>console.log(err))
  }

  //页面数据重新加载
  useEffect(()=>{
    getData(pageNumber,searchParams);
  },[refresh])

  //列表选择
  useEffect(() => {
    let count = 0
    approvalItems.forEach(element => {
      if(element.checked) count++
    });
    if(choiceApprovalCount !== count) setChoiceApprovalCount(count)
  }, [approvalItems])

  //全选切换
  const toggleChange = () => {
    const statue = allChoice
    setAllChoice(!allChoice);
    const oldApprovalItems = [...approvalItems]
    if(statue) {
      oldApprovalItems.forEach(element => {
        element.checked = false;
      });
    } else {
      oldApprovalItems.forEach(element => {
        element.checked = true;
      });
    }
    setApprovalItems(oldApprovalItems)
  }


  //判断是否全选
  const juedgeAllChoice = (list: any) => {
    for(let i=0;i<list.length;i++) {
      if(!list[i].checked) return false
    }
    return true
  }

  //单个表单的选择切换
  const toggleItemChecked = (e: any) => {
    const oldApprovalItems: any = [...approvalItems];
    oldApprovalItems[e].checked = !oldApprovalItems[e].checked;
    setApprovalItems(oldApprovalItems);
    if(juedgeAllChoice(oldApprovalItems)) setAllChoice(true);
    else setAllChoice(false);
  }

  //表单项的删除
  const delApprovalItem = async(delItem=delApprovalList) => {
    const approvalIds: any = delItem.join(",");
    //setOpen(false);
    delProcess({processKeys:approvalIds}).then((e: any)=>{
      if(e.success) message.success(t('process.list.delSuccess'));
      else message.error(t('process.list.DeleteTryAgainLater'));
      setPageNumber(1);
      setRefresh(!refresh);
    })
  }
  //删除确认
  const showConfirm = (singleDel: any, delItem:any) => {
    Modal.confirm({
      title: singleDel ? t('process.list.DeleteTheFlow'):`${t('process.list.del')}${choiceApprovalCount}${t('process.list.AnApprovalFlow')}`,
      icon: <ExclamationCircleOutlined />,
      content: t('process.list.ExerciseCaution'),
      okText: t('process.list.del'),
      onOk: () => {
        delApprovalItem && delApprovalItem(delItem)
      },
    })
  }
  // 批量下载审批流，只可以下载无条件审批流
  // const clickCanBatchApproval = (approvalIds:any) =>{
  //   // 如果全选 则代表下载全部
  //   canBatchApproval({keyList:pageSize === approvalIds?.length?[]:approvalIds}).then((res)=>{
  //     // console.log(res);
  //     if(res?.success){
  //       Modal.confirm({
  //         title: `确认导出审批流?`,
  //         icon: <ExclamationCircleOutlined />,
  //         content: `已选${approvalIds?.length}个审批流，其中无条件审批${res?.value?.canExport}个审批流可下载`,
  //         okText: '导出',
  //         cancelText:'取消',
  //         onOk: () => {
  //           downLoadApproval(approvalIds);
  //         }
  //       })
  //     } else{
  //       message.error('批量导出失败，请稍后再试！');
  //     }
  //   })
  // }
 // 执行下载动作
  const downLoadApproval = (approvalIds:any)=> {
    // 如果全选 则代表下载全部
    exportApproval({keyList:pageSize === approvalIds?.length?[]:approvalIds}).then((res)=>{
      // console.log(res);
      if(res?.success){
        message.success(t('process.list.DownloadDetails'));
      }else{
        message.error(t('process.list.BatchFailedTryAgainLater'));
      }
    })
  }
  //文件下载
  const downloadFail = (uuid: string) => {
    downloadFailResult({ uuid });
  };
  //批量新建
  const handleUploadResponse = (resp: any) => {
    console.log(resp)
    if (resp.success) {
      message.success(t('process.list.createBatchSuccess'));
      setRefresh(!refresh);
      setUploadFileModal(false);
    } else {
      setRefresh(!refresh);
      // setUploadFileModal(false);
      if (resp.value) {
        setFileUploadStatue({
          ...fileUploadStatue,
          statue:true,
          id:resp?.value?.uuid ?? ''
        })
      } else {
        message.error(resp.msg);
      }
    }
  };


  //页码改变
  const changePageData = (_page) => {
    setPageNumber(page=>{
      getData(_page,searchParams);
      return _page;
    });
  }

  //审批流创建状态 1:成功 2:失败
  const createApprovalSuccess = (statue=1) => {
    if(statue==1)
      message.success({
        content: t('process.list.createSuccess'),
        className: 'custom-class',
        style: {
          marginTop: '20vh',
        },
      });
    else
      message.error({
        content: t('process.list.FlowCreationFailedAgainLater'),
        className: 'custom-class',
        style: {
          marginTop: '20vh',
        },
      });
  };

  //审批流遍历与查看
  const approvalEdit = (type,id) => {
    window.open(`/approval?processKey=${id}&type=${type}`, userData.clientOpenWindow ? '_self' : '_blank');
  }

  //流程下拉框的选择
  const onGenderChange = (e) => {
    setParams({
      ...params,
      type:e
    })
  }

  //搜索按钮的提交
  const onFinish = () => {
    console.log(params);
    if(params.value.trim()==='') {
      //重新加载
      form.resetFields();
      setParams({
        ...params,
        type:1,
        value:""
      })
      setPageNumber(1);
      setSearchParams(e=>{
        getData(1,{});
        return {}
      })
      return;
    }
    let obj = {}
    if(params.type==1) {
      obj = {processName:params.value};
    } else if(params.type==2) {
      obj = {purchasePins:params.value};
    } else {
      obj = {examinePinLike:params.value};
    }
    setSearchParams(obj)
    setPageNumber(1);
    setRefresh(!refresh);
  }
  const onDropClick = ({key}) => {
    setProcessType(key);
    if(key!=0) {
      setSearchParams({
        ...searchParams,
        processType:Number(key)
      })
    }else {
      const params = {...searchParams};
      if('processType' in params)
        delete params.processType
      setSearchParams({
        ...params
      })
    }
    setPageNumber(1);
    setRefresh(!refresh);
  }
  /**审批类型 */
  const menu = (
    <Menu onClick={(e)=>{
      onDropClick({key:e.key})
    }}
    selectedKeys={[processType]}
    >
      <Menu.Item key='' >{t('process.list.conditoinlist.0')}</Menu.Item>
      <Menu.Item key='1'> {t('process.list.conditoinlist.1')}</Menu.Item>
      <Menu.Item key='2'>{t('process.list.conditoinlist.2')}</Menu.Item>
    </Menu>
  );
  const items: MenuProps['items'] = [
    {
      key: '0',
      label: t('process.list.conditoinlist.0'),
    },
    {
      key: '1',
      label:  t('process.list.conditoinlist.1'),
    },
    {
      key: '2',
      label:  t('process.list.conditoinlist.2'),
    },
  ];

  /**批量操作按钮 */
  const ApprovalButtons = (
    <div className={styles.operations}>
      <div className={styles.manage}>
        <div>{t('process.list.bread')}</div>
        <div>
          {/* <Button
            style={{ marginRight: '8px'}}
            onClick={()=>setUploadFileModal(true)}
          >
            批量新建
          </Button> */}

          <Button
            type='primary'
            onClick={()=> {
              window.open(`/approval?type=1`, userData?.clientOpenWindow ? '_self' : '_blank')
            } }
            // className={styles.create}
          >
            {t('process.list.addPrrove')}
          </Button>
        </div>
      </div>
    </div>

  )

  /**搜索按钮组 */
  const ApprovalSearch = (
    <div className={styles.search}>
      <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={styles.searchForm}
      >
        <Form.Item name="gender">
          <Select defaultValue={{ label: t('process.detail.card_info1.label.1'),value:1 }} options={[
            { label: t('process.detail.card_info1.label.1'),value:1 },
            { label:t('accountSettings.userType.buyer'),value:2 },
            // { label: '审查账号',value:3 }
            ]} onChange={onGenderChange}/>
        </Form.Item>
  {/*rules={[{ required: true ,message:params.type==1?'请输入审批流名称':params.type==2? '请输入采购账号':'请输入审查账号'}]}*/}
        <Form.Item name="note" >
          <Input
              value={params.value}
              onChange={(e)=>{
                setParams({
                  ...params,
                  value:e.target.value
                })
              }}
              placeholder={params.type==1? t('process.list.EnterApprovalFlowName'):params.type==2?  t('process.list.EnterPurchasingAccount'):t('process.list.EnterReviewingAccount')}
          />
        </Form.Item>

        <Form.Item>
          <div className={styles.btn_group}>
            <Button
              style={{marginRight: '16px'}}
              onClick={()=>{
                form.resetFields();
                setParams({
                  ...params,
                  type:1,
                  value:""
                })
                setPageNumber(1);
                setSearchParams(e=>{
                  getData(1,{});
                  return {}
                })

              }}
            >
              {t('process.list.reset')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t('process.list.search')}
            </Button>
          </div>

        </Form.Item>
      </Form>
    </div>
  )

  /**审批流列表 */
  const ApprovalItems = (
    <div className={styles.list}>
      <div className={styles.control}>
        <div>
          <Checkbox checked={allChoice} onChange={toggleChange} style={{position: 'relative',top: '2px'}}/>
          <span className={styles.sp}>{t('process.list.selectAll')}</span>
          <span className={styles.part_c}>{t('process.list.selected')}{choiceApprovalCount}{t('process.list.item')}</span>
        </div>
        <div>
          </div>
        <div>
          <Button
            type='primary'
            disabled={choiceApprovalCount==0 ? true: false}
            onClick={()=>{
              const res = []
              approvalItems.forEach(item=>{
                if(item.checked) res.push(item.processKey)
              })
              setDelApprovalList(res);
              showConfirm(false, res);
            }}

            >
            {t('process.list.batchDel')}
          </Button>

          {/* <Button
              type='primary'
              disabled={choiceApprovalCount==0 ? true: false}
              onClick={()=>{
                let res = []
                approvalItems.forEach(item=>{
                  if(item.checked) res.push(item.processKey)
                })
                // setDelApprovalList(res);
                clickCanBatchApproval(res);
              }}
              style={{marginLeft:'8px'}}
          >
            批量导出
          </Button> */}

        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.table_titles}>
            <div>{t('process.detail.card_info1.label.1')}</div>
            <div>
              {t('process.detail.card_info1.label.0')}
              <Dropdown overlayClassName={styles.drop} overlay={menu} trigger={['click']} placement="bottomCenter">
                {/* <a onClick={e => e.preventDefault()}> */}

                <i className='iconFont icon-navbar_sort' style={{fontSize:'12px',cursor:'pointer',marginLeft:'4px'}}/>
                {/* </a> */}
              </Dropdown>
            </div>
            <div>{t('process.list.form.option2.1')}</div>
            <div>{t('process.list.creator')}</div>
            <div>{t('process.list.operate')}</div>
        </div>

        <div className={styles.item}>
          {approvalItems.map((item: any,index)=>
            <ul key={item.processId}>
                <li>
                  <Checkbox onChange={()=>toggleItemChecked(index)} checked={item.checked} style={{margin: '0 16px'}}/>
                  <span>{item.processName}</span>
                </li>
                <li>{item.processType==1? t('process.list.conditoinlist.1'):t('process.list.conditoinlist.2')}</li>
                <li>
                  <Tooptip
                    placement={'top'}
                    title={item.purchasePins.join('；')}
                  >
                    <div style={{width:'fit-content'}}>{item.purchasePins.join('；')}</div>
                  </Tooptip>
                </li>
                <li>
                  <span>{item.applyPin}</span>
                  <span>{moment(item.created).format("YYYY-MM-DD HH:mm:ss")}</span>
                </li>
                <li>
                  <a href="#" rel="noreferrer" onClick={(e)=>{
                    e.preventDefault();
                    approvalEdit(3,item.processKey)
                  }}>{t('process.list.table_op1.0')}</a>
                  <a href="#" rel="noreferrer" onClick={(e)=>{
                    e.preventDefault();
                    approvalEdit(2,item.processKey)
                  }}>{t('process.list.table_op1.1')}</a>
                  <a
                    href="#"
                    onClick={(e)=>{
                      e.preventDefault();
                      //setDelCount(choiceApprovalCount);
                      setDelApprovalList([item.processKey])
                      //setsingleDel(true);
                      showConfirm(true,[item.processKey]);
                    }}>
                    {t('process.list.table_op1.2')}
                  </a>
                </li>
            </ul>
          )}
        </div>
      </div>
      {
        approvalItems.length>0 ?
        <Pagination
          className={styles.pagination}
          showQuickJumper
          current={pageNumber}
          total={total}
          pageSize={pageSize}
          showSizeChanger={false}
          showTotal={() => `${t('process.list.total')}${total}${t('process.list.items')}`}
          onChange={_page => {
            changePageData(_page)
          }}
        />
        :<div className={styles.empty}>{t('process.list.sorryNoData')}</div>
      }
    </div>
  )
  return (
      <div style={{marginTop: '10px',position: 'relative',width: '100%'}}>
        <div className="ispContainer">
          <UserCenterMenu />
          <div className={styles.content}>
            {ApprovalButtons}
            {ApprovalSearch}
            {ApprovalItems}
            {/* {uploadFileModal &&
              <UploadFiles
                visible={uploadFileModal}
                title={'批量新建审批流'}
                template={`https://${apiLocationUrl}/api/special/approval/process/man/downLoadApprovalImportTemp`}
                action={`https://${apiLocationUrl}/api/special/approval/process/man/importApproval`}
                tip={[
                  '1、同一采购账号允许设置多个审批流。',
                  '2、各节点账号最多支持1000个，多个账号之间请用中文逗号“，”分隔，不包含空格和回车。',
                  '3、“有条件”审批流类型需设置审批订单金额，下级节点审批订单金额必须大于上级节点审批订单金额，最后一级可以任意设置。',
                ]}
                maxCount={1}
                onCancel={() =>{
                  setFileUploadStatue({
                    ...fileUploadStatue,
                    statue:false
                  })
                  setUploadFileModal(false)
                } }
                onResponse={handleUploadResponse}
                onOK={() => ()=>{
                }}
                showError={false}
                delay={3000}
                fileUploadStatue={fileUploadStatue}
                setFileUploadStatue={setFileUploadStatue}
              /> } */}
          </div>
        </div>
        {/* <FootSlogan/> */}
      </div>
  );
};

export default ApprovalList;
