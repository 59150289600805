/*
 * @Author: weiyanna
 * @Date: 2021-09-14 16:53:00
 * @LastEditTime: 2022-08-05 16:31:44
 * @LastEditors: duchongchong1
 * @Description: In User Settings Edit
 * @FilePath: /isp_pro/src/components/BreadCrumb/index.tsx
 */
import React, { memo } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import iconArrowRight from '@/assets/images/common/icon_arrow_right.svg';
import Icon from '@/components/common/Icon';

export default memo((props: any) => {
  const { data } = props;
  return (
    <Breadcrumb separator={<Icon icon={iconArrowRight} width="20px" height="20px" />} className={styles.breadcrumb}>
      {data &&
        data.map((item: any, key: any) => {
          return (
            <Breadcrumb.Item key={key}>
              {item.url && item.url === '/' ? (
                <a href="/">{item.label}</a>
              ) : item.url ? (
                <Link to={item.url}>{item.label}</Link>
              ) : (
                <span>{item.label}</span>
              )}
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb>
  );
});
