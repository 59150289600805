import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TabInfo from './left/tabInfo';

// 订单流程
import OrderProgress from './right/order';
import ApprovalProgress from './right/approval';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';

interface IAppProps {
  orderDetails: any;
  orderDetailFn?: () => void;
}
const Progress: React.FC<IAppProps> = (props: any) => {
  const [type, setType] = useState<string>('order');
  const { orderDetails, orderDetailFn } = props;
  const store: any = useSelector(state => {
    return state;
  });

  const { t } = useTranslation();

  useEffect(() => {
    const userType = store?.User?.data[0]?.userType ?? '';
    if (userType === 6) {
      setType('approval');
    }
  }, []);
  const changeProgressType = type => {
    setType(type);
  };

  const parentOrderId = orderDetails?.orderRes?.parentOrderId

  return (
    <div className={styles.mainProgress}>
      <div className={styles.orderNumber}>
        <span className={`${styles['order-number_part']} ${styles['process-text_blob']}`}>
          { t('orderDetail.orderNo') }: { orderDetails?.orderRes?.orderNo }
        </span>
        <span className={styles['order-number_part']}>
          {
            parentOrderId && parentOrderId !== '0' &&
              `${t('orderDetail.parentOrderId')}: ${parentOrderId}`
          }
        </span>
      </div>

      <div className={styles['progress_info-containter']}>
        <div className={styles.left}>
          <TabInfo
            orderDetails={orderDetails}
            changeProgressType={changeProgressType}
            changeOrderDetail={orderDetailFn}
          ></TabInfo>
        </div>

        <div className={styles.right}>
          {type === 'order' ? (
            <OrderProgress orderDetails={orderDetails}></OrderProgress>
          ) : (
            <ApprovalProgress orderDetails={orderDetails}></ApprovalProgress>
          )}
        </div>
      </div>
    </div>
  );
};

export default Progress;
