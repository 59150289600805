import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Row, Checkbox, DatePicker, Modal, Radio, Space } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;
import Icon from '@/components/common/Icon';

import styles from './index.module.scss';
import iconSearch from '@/assets/images/orderList/icon_search.svg';
import iconPageLeft from '@/assets/images/orderList/icon_page_left.svg';
import iconPageLeftDis from '@/assets/images/orderList/icon_page_left_dis.svg';
import iconPageRight from '@/assets/images/orderList/icon_page_right.svg';
import iconPageRightDis from '@/assets/images/orderList/icon_page_right_dis.svg';
// 以下4行引入解决日期控件英文的问题
import moment from 'moment'
import en_NZ from 'antd/lib/date-picker/locale/en_US'
import 'moment/locale/en-nz'
moment.locale('en-nz')

import vi_VN from 'antd/lib/date-picker/locale/vi_VN';
import 'moment/locale/vi';
moment.locale('vi');

import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';


export enum UserType {
  unknonw = 0,
  parentAccount = 1,
  childAccount,
  parallelAccount,
  administrator,
  buyAccount,
  auditAccount,
  jincaiAccount,
  investigateAccount,
  financialAccount,
}

const SearchForm: React.FC<{
  isAudit: boolean;
  isDelete: boolean;
  paymentTypes: Array<object>;
  auditQueryType: string;
  onReset: () => void;
  onSearch: (params: any) => void;
}> = ({ isAudit = false, isDelete = false, paymentTypes, auditQueryType, onReset, onSearch }) => {
  const { t, i18n } = useTranslation();
  const [auditStatuses, setAuditStatuses] = useState<Array<object>>([]);
  const [auditStatuses0, setAuditStatuses0] = useState<Array<object>>([]);
  const [auditStatuses1, setAuditStatuses1] = useState<Array<object>>([]);

  const [orderTimeModalVisible, setOrderTimeModalVisible] = useState<boolean>(false);
  const [searchOrderTimeType, setSearchOrderTimeType] = useState<number>(1);
  const [searchOrderTime, setSearchOrderTime] = useState<Array<string>>([]);
  const [searchOrderTimeTypeCopy, setSearchOrderTimeTypeCopy] = useState<number>(1);
  const [searchOrderTimeCopy, setSearchOrderTimeCopy] = useState<Array<string>>([]);

  const [buyAccountModalVisible, setBuyAccountModalVisible] = useState<boolean>(false);
  const [buyAccountsPage, setBuyAccountsPage] = useState<number>(1);
  const [buyAccountsPageSize] = useState<number>(20);
  const [buyAccounts, setBuyAccounts] = useState<Array<string>>([]);
  const [buyAccountsTotal, setBuyAccountsTotal] = useState<number>(0);
  const [buyAccountsTotalPage, setBuyAccountsTotalPage] = useState<number>(0);
  const [buyAccountsRefresh, setBuyAccountsRefresh] = useState(false);
  const [buyAccountsKeyword, setBuyAccountsKeyword] = useState('');
  const [selectedBuyAccounts, setSelectedBuyAccounts] = useState<Array<string>>([]);
  const [selectedBuyAccountsCopy, setSelectedBuyAccountsCopy] = useState<Array<string>>([]);
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土

  const [form] = Form.useForm();

  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;

  // const getBuyAccounts = () => {
  //   getBuyAccountList({ page: buyAccountsPage, pageSize: buyAccountsPageSize, pin: buyAccountsKeyword }).then(
  //     (res: any) => {
  //       const data = res?.value ?? {};
  //       setBuyAccounts(data?.accounts ?? []);
  //       setBuyAccountsTotal(data?.totalCount ?? 0);
  //       setBuyAccountsTotalPage(Math.ceil((data?.totalCount ?? 0) / buyAccountsPageSize));
  //     },
  //   );
  // };
  // const getAuditConditions = () => {
  //   getAuditOrderListConditions({ queryType: 0 }).then((res: any) => {
  //     const approvalStatusSelect = res?.value?.approvalStatusSelect ?? {};
  //     const statuses = Object.keys(approvalStatusSelect)
  //       .map((t: any) => ({
  //         key: t,
  //         value: approvalStatusSelect[t],
  //       }))
  //       .filter((t: any) => t.value !== '全部');
  //     setAuditStatuses0(statuses);
  //     setAuditStatuses(statuses);
  //   });
  //   getAuditOrderListConditions({ queryType: 1 }).then((res: any) => {
  //     const approvalStatusSelect = res?.value?.approvalStatusSelect ?? {};
  //     const statuses = Object.keys(approvalStatusSelect)
  //       .map((t: any) => ({
  //         key: t,
  //         value: approvalStatusSelect[t],
  //       }))
  //       .filter((t: any) => t.value !== '全部');
  //     setAuditStatuses1(statuses);
  //   });
  // };

  const searchBuyAccount = (e: any) => {
    setBuyAccountsKeyword(e.target.value);
    setBuyAccountsPage(1);
    setBuyAccountsRefresh(true);
  };

  const prevPage = () => {
    if (buyAccountsPage > 1) {
      setBuyAccountsPage(buyAccountsPage - 1);
      setBuyAccountsRefresh(true);
    }
  };
  const nextPage = () => {
    if (buyAccountsPage < buyAccountsTotalPage) {
      setBuyAccountsPage(buyAccountsPage + 1);
      setBuyAccountsRefresh(true);
    }
  };

  // 获取用户操作权限
  const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });

  useEffect(() => {
    // getBuyAccounts();
    if (isAudit) {
      // getAuditConditions();
    }
  }, []);

  useEffect(() => {
    if (buyAccountsRefresh) {
      // getBuyAccounts();
      setBuyAccountsRefresh(false);
    }
  }, [buyAccountsRefresh]);

  useEffect(() => {
    form.setFieldsValue({ approvalStatus: undefined });
    if (auditQueryType === '0') {
      setAuditStatuses(auditStatuses0);
    } else {
      setAuditStatuses(auditStatuses1);
    }
  }, [auditQueryType]);

  const formItem1 = (
    <Form.Item
      key="formItem1"
      name={isAudit ? 'orderErpId' : 'orderNo'}
      label={t('orderList.searchForm.label.orderNo')}
      getValueFromEvent={e => e.target.value}
    >
      <Input placeholder={t('orderList.searchForm.label.please') + t('orderList.searchForm.label.orderNo')} />
    </Form.Item>
  );
  const formItem2 = (
    <Form.Item key="formItem2" name="skuName" label={t('orderList.searchForm.label.productName')}>
      <Input placeholder={t('orderList.searchForm.label.please') + t('orderList.searchForm.label.productName')} />
    </Form.Item>
  );

  const formItem5 = (
    <Form.Item key="formItem5" name="consignee" label={t('orderList.searchForm.label.receiver')}>
      <Input placeholder={t('orderList.searchForm.label.please') + t('orderList.searchForm.label.receiver')} />
    </Form.Item>
  );
  const formItem6 = (
    <Form.Item key="formItem6" label="订单时间">
      <Input
        placeholder="选择订单时间"
        style={{ width: '100%' }}
        onClick={() => {
          setSearchOrderTimeTypeCopy(searchOrderTimeType);
          setSearchOrderTimeCopy(searchOrderTime);
          setOrderTimeModalVisible(true);
        }}
        value={searchOrderTime.length > 1 ? searchOrderTime.join(' - ') : ''}
      />
    </Form.Item>
  );
  const formItem7 = (
    <Form.Item key="formItem7" label={t('orderList.searchForm.label.orderTime')}>
      <RangePicker
        allowClear={false}
        placeholder={[t('orderList.searchForm.label.st'), t('orderList.searchForm.label.et')]}
        style={{ width: '100%' }}
        value={
          searchOrderTime.length > 1
            ? [moment(searchOrderTime[0], 'YYYY-MM-DD'), moment(searchOrderTime[1], 'YYYY-MM-DD')]
            : undefined
        }
        locale={ i18n.language === 'en' ? en_NZ : i18n.language === 'vi' ? vi_VN : zh_CN }
        onChange={(_date, dateString) => {
          setSearchOrderTime(dateString);
        }}
      />
    </Form.Item>
  );
  const formItem8 = (
    <Form.Item key="formItem8" name="sku" label={t('orderList.searchForm.label.productID')}>
      <Input placeholder={t('orderList.searchForm.label.please') + t('orderList.searchForm.label.productID')} />
    </Form.Item>
  );
  const formItem9 = (
    <Form.Item key="formItem9" name="approvalStatus" label="审批状态">
      <Select placeholder="请选择审批状态" allowClear>
        {auditStatuses.map((t: any) => (
          <Option key={t.key} value={t.key}>
            {t.value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
  // const formItem10 = (
  //   <Form.Item key="formItem10" name="approvalStatus" label="是否报关">
  //       <Select placeholder="请选择是否报关" allowClear>
  //       <Option key={1} value={1}>
  //         是
  //         </Option>
  //         <Option key={1} value={1}>
  //         否
  //         </Option>
  //     </Select>
  //   </Form.Item>
  // );

  const formItem11 = (
    <Form.Item key="formItem11" name="thirdOrderId" label={t('orderList.searchForm.label.purchaseNo')}>
        <Input placeholder={t('orderList.searchForm.label.please') + t('orderList.searchForm.label.purchaseNo')} />
    </Form.Item>
  );

  const dummyDiv1 = <div key="formItem12" />;
  const dummyDiv2 = <div key="formItem13" />;

  const formItem12 = (
    <Form.Item key="formItem12" name="materialId" label={t('materialCode.materialCode')}>
        <Input placeholder={t('orderList.searchForm.label.please') + t('materialCode.materialCode')} />
    </Form.Item>
  );

  const showAuditAccountFilter = [
    UserType.parentAccount,
    UserType.administrator,
    UserType.investigateAccount,
    UserType.financialAccount,
    UserType.auditAccount,
  ].includes(userType);
  const formItems = isDelete
    ? [formItem1, formItem2, formItem5, formItem6]
    : isAudit
    ? auditStatuses.length > 0
      ? [formItem1, formItem7, formItem9]
      : [formItem1, formItem7]
    : isLocalPro ?[formItem1, formItem2, formItem11, formItem8, formItem5, formItem7]: [formItem1, formItem2, formItem8, formItem5, formItem7];
    if(userLimit?.limitData?.enableMaterial == 1){
      formItems.push(formItem12);
    }
  if (formItems.length % 3 === 0) {
    formItems.push(dummyDiv1);
    formItems.push(dummyDiv2);
  }
  if (formItems.length % 3 === 1) {
    formItems.push(dummyDiv1);
  }


  const orderTimeModal = (
    <Modal
      width={510}
      title="选择订单时间"
      open={orderTimeModalVisible}
      footer={[
        <Row key="buyAccountModalFooter" style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            key="cancelOrder"
            onClick={() => {
              setSearchOrderTimeType(searchOrderTimeTypeCopy);
              setSearchOrderTime(searchOrderTimeCopy);
              setOrderTimeModalVisible(false);
            }}
            style={{ fontSize: '18px', width: '160px', height: '46px' }}
          >
            选好了
          </Button>
        </Row>,
      ]}
      onCancel={() => setOrderTimeModalVisible(false)}
      className={styles.buyAccountModal}
    >
      <div>
        <Radio.Group value={searchOrderTimeTypeCopy} onChange={(e: any) => setSearchOrderTimeTypeCopy(e.target.value)}>
          <Radio value={1}>下单时间</Radio>
          <Radio value={2}>完成时间</Radio>
        </Radio.Group>
      </div>
      <div style={{ marginTop: '20px' }}>
        <RangePicker
          allowClear={false}
          value={
            searchOrderTimeCopy.length > 1
              ? [moment(searchOrderTimeCopy[0], 'YYYY-MM-DD'), moment(searchOrderTimeCopy[1], 'YYYY-MM-DD')]
              : null
          }
          onChange={(_date, dateString) => {
            setSearchOrderTimeCopy(dateString);
          }}
        />
      </div>
    </Modal>
  );

  const buyAccountModal = (
    <Modal
      width={510}
      title="选择采购账号"
      open={buyAccountModalVisible}
      footer={[
        <Row key="buyAccountModalFooter" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Row>
            <Icon
              icon={buyAccountsPage <= 1 ? iconPageLeftDis : iconPageLeft}
              width="20px"
              height="20px"
              style={{ marginRight: '15px' }}
              onClick={prevPage}
            />
            <span style={{ fontSize: '14px', color: '#666' }}>
              <span className={[styles.blue, styles.bold].join(' ')}>
                {buyAccountsPage > buyAccountsTotalPage ? buyAccountsTotalPage : buyAccountsPage}
              </span>
              /{buyAccountsTotalPage}
            </span>
            <Icon
              icon={buyAccountsPage >= buyAccountsTotalPage ? iconPageRightDis : iconPageRight}
              width="20px"
              height="20px"
              style={{ marginLeft: '15px' }}
              onClick={nextPage}
            />
          </Row>
          <Button
            type="primary"
            key="cancelOrder"
            onClick={() => {
              setSelectedBuyAccounts([...selectedBuyAccountsCopy]);
              setBuyAccountModalVisible(false);
            }}
            style={{ fontSize: '18px', width: '160px', height: '46px' }}
          >
            选好了
          </Button>
        </Row>,
      ]}
      onCancel={() => setBuyAccountModalVisible(false)}
      className={styles.buyAccountModal}
    >
      <Input allowClear placeholder="搜索采购账号" suffix={<Icon icon={iconSearch} />} onChange={searchBuyAccount} />
      <Checkbox.Group
        style={{ maxHeight: '335px', overflowY: 'auto' }}
        onChange={checkedValues => {
          setSelectedBuyAccountsCopy(checkedValues as string[]);
        }}
        value={selectedBuyAccountsCopy}
      >
        {buyAccounts.map(o => (
          <Row key={o} style={{ marginTop: '20px' }}>
            <Checkbox value={o}>{o}</Checkbox>
          </Row>
        ))}
      </Checkbox.Group>
    </Modal>
  );

  const onFinish = (values: any) => {
    const params = values;
    if (searchOrderTime.length > 1) {
      // params.timeType = searchOrderTimeType;
      params.startTime = searchOrderTime[0];
      params.endTime = searchOrderTime[1];
    }
    if (selectedBuyAccounts.length > 0) {
      params.pins = selectedBuyAccounts.join(',');
    }
    onSearch(params);
  };

  return (
    <>
      <Form form={form} colon={false} name="control-hooks" onFinish={onFinish} className={styles.searchForm}>
        <Space wrap size={0} style={{ width: '100%' }}>
          {formItems}
        </Space>
        <div style={{ textAlign: 'right' }}>
          <Button
            clstag={isAudit ? 'h|keycount|ordercenter|4' : 'h|keycount|myorder|9'}
            type="primary"
            htmlType="submit"
            style={{ marginRight: '20px'}}
            onClick={() => {
              log('jdisp', 'OrderList_Search');
            }}
          >
            {t('orderList.searchForm.form_btn.search')}
          </Button>

          <Button
            clstag={isAudit ? 'h|keycount|ordercenter|3' : 'h|keycount|myorder|8'}
            type="primary"
            ghost
            onClick={() => {
              form.resetFields();
              setSearchOrderTimeType(1);
              setSearchOrderTime([]);
              setSelectedBuyAccounts([]);
              onReset();
              log('jdisp', 'OrderList_Reset');
            }}
          >
            {t('orderList.searchForm.form_btn.reset')}
          </Button>
        </div>
      </Form>
      {orderTimeModal}
      {buyAccountModal}
    </>
  );
};

export default SearchForm;
