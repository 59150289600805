/* eslint-disable prefer-rest-params */

import { T } from 'ramda';

// 更新数据
const INITIAL_STATE = {};

const updateAllChecked = (state: any, action: any) => {
  return {
    ...state,
  };
};

export default function Application(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case 'UPDATE_USER_DATA':
      return updateAllChecked(state, action);
    default:
      return state;
  }
}
