import { atom } from 'recoil';

import { Sku } from '../interface';

const disableSkuList = atom<Array<Sku> | undefined>({
  key: 'disableSkuList',
  default: [],
});

export default disableSkuList;
