import { atom } from 'recoil';

import { Sku } from '../interface';

const selectedSkuList = atom<Array<Sku> | undefined>({
  key: 'selectedSkuList',
  default: undefined,
});

export default selectedSkuList;
