import { atom } from 'recoil';

import Others from '../types/others';

const others = atom<Others | undefined>({
  key: 'others',
  default: {
    paySuccess: false,
  },
});

export default others;
