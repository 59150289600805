import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Pagination, message, Modal, Checkbox, Table } from 'antd';

import UserCenterMenu from '@/components/UserCenterMenu';
import CommonBasicHeader from '@/components/CommonBasicHeader';
import CpAddressList from '@/components/Address/List/index';
import TableArea from './components/TableArea/index';
import { PAGE_SIZE_OPTIONS } from '@/enum/config';
import { Input } from 'antd';
import SearchEmpty from '@/assets/images/accountSettings/icon_search_empty.png';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { getAuthoritList, setAuthoritStatus } from '@/api/newApi/addressAuthority';



const AddressAuthorityIndex = ()=>{
    const { t } = useTranslation();
    const [accountList, setAccountList] = useState([]);

    useEffect(() => {
        // 获取列表数据
        getDataList();
    }, [])

    const getDataList = async()=>{
        const resData = await getAuthoritList();
        if(resData?.success){
            setAccountList(resData?.value)
        }
       
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="ispContainer">
        <UserCenterMenu />
        <div className={styles.content} style={{ width: '1030px' }}>
          <CommonBasicHeader title={t('addressAuthority.authManage')}>
          </CommonBasicHeader>
          <Card className={styles.orderList} style={{ padding: '15px 20px' }}>
                <TableArea dataSource={accountList} changeStatus={async (e, record)=>{
                    const resData = await setAuthoritStatus ({
                        id: record.id,
                        authorityStatus: record.authorityStatus ? 0 : 1
                    })
                    if(resData?.success){
                        message.success(t('addressAuthority.operSuccess'))
                        getDataList()
                    }   
                }}></TableArea>
          </Card>
        </div>
      </div>
    </div>
    )
}

export default AddressAuthorityIndex;



