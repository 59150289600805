/*
 * @Author: your name
 * @Date: 2021-11-30 17:16:40
 * @LastEditTime: 2021-11-30 18:11:14
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/Carts/atoms/selected.ts
 */
import { atom } from 'recoil';

const selected = atom<Array<string>>({
  key: 'selected',
  default: [],
});

export default selected;
