/*
 * @Author: duchongchong1
 * @Date: 2022-11-14 21:53:24
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-29 15:26:46
 * @FilePath: /isp_International/src/api/newApi/orderdetail.ts
 * @Description:  
 */
import http from '../axios';

/**
 * 订单详情
 *
 * @param params
 */
export function orderDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/order/getOrder', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 订单审批流程
 *
 * @param params
 */
export function approvalFlow(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/flow', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 打印审批流程
 *
 * @param params
 */
export function orderPrint(params: any = {}) {
  console.log('打印审批流程', params);
  return new Promise((resolve, reject) => {
    http('get', '/jdig/order/OrderPrint', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 更新订单状态
 *
 * @param params
 */
export function updateOrderStatus(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/updateOrderStatus', params, false, true).then(
      res => {
        console.log(res, 'orderList');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

// 订单状态-加入购物车
export function addProducts(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/addProducts', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

// 无货申请
export function replenishment(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '//jdig/pro/replenishment', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

