import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BaseLayout from '@/layout/BaseLayout';
import WorkBenchRoutes from './WorkBench';

import SelectList from '../pages/SelectList';
import SearchList from '../pages/SearchList';
import Home from '../pages/NewHome/index';
import Carts from '../pages/CartsNew/index';
import OtherCarts from '../pages/OtherCarts/index';
import Login from '../pages/Login';
import UpdatePassword from '../pages/UpdatePassword';
import SkuDetailV2 from '../pages/SkuDetailV2/index';
import OrderInfo from '../pages/OrderInfo/index';
import { useTranslation } from 'react-i18next';

const routes = [
  //搜索列表
  {
    path: `/page/list`,
    name: 'SearchList',
    exact: true,
    meta: {
      title: 'searchList', //搜索列表
    },
    component: SearchList,
  },
  {
    path: `/`,
    name: 'Home',
    exact: true,
    meta: {
      title: 'home', //首页
    },
    component: Home,
  },
  {
    path: `/select`,
    name: 'Select',
    exact: true,
    meta: {
      title: 'select', //精选商品-落地页
    },
    component: SelectList,
  },
  //购物车
  {
    path: `/cart/otherCart`,
    name: 'OtherCarts',
    exact: true,
    meta: {
      title: 'otherCart', //其他采购清单
    },
    component: OtherCarts,
  },
  {
    path: `/sku/:skuId`,
    name: 'SkuDetail',
    exact: true,
    meta: {
      title: 'skuDetail', //商品详情页
    },
    component: SkuDetailV2,
  },
  {
    path: `/cart/carts`,
    name: 'Carts',
    exact: true,
    meta: {
      title: 'cartList', //采购清单
    },
    component: Carts,
  },
  {
    path: `/order/goOrderInfo`,
    name: 'OrderInfo',
    exact: true,
    meta: {
      title: 'settlement', //结算页
    },
    component: OrderInfo,
  },
  ...WorkBenchRoutes,
  {
    path: '*',
    name: 'Home',
    exact: true,
    meta: {
      title: 'home', //首页
    },
    component: Home,
  },
];

const RouteWithSubRoutes = (route: any) => {
  const { t, i18n } = useTranslation();
  return (
    <Route
      exact
      path={route.path}
      render={props => {
        document.title = `${t('common.JDindustry')}-` + `${t(`common.${route?.meta?.title}`)}`;
        return <route.component {...props} {...route.params} routes={route.routes} />;
      }}
    />
  );
};

const BasicRoute = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/passport/login" component={Login} />
      <Route exact path="/forget" component={UpdatePassword} />
      <BaseLayout backgroundColor="#f0f0f0">
        <Switch>{routes && routes.map((route: any, i: any) => <RouteWithSubRoutes key={i} {...route} />)}</Switch>
      </BaseLayout>
    </Switch>
  </BrowserRouter>
);

export default BasicRoute;
