import { atom } from 'recoil';

interface Sku {
  imgUrl: string;
  mkuName: string;
  priceInfo?: number;
  skuId: number;
}

interface Compare {
  goods: Sku[];
  visible: boolean;
}

const compare = atom<Compare>({
  key: 'compare',
  default: {
    goods: [],
    visible: false,
  },
});

export default compare;
