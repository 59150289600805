import React from 'react';
import styles from '../DrawRow/index.module.scss';

import FlowNode from '@/pages/Approval/Create/components/drawFlow/DrawRow/FlowNode';
import AddNodeBtn from '@/pages/Approval/Create/components/drawFlow/AddNodeBtn';

import drawFlow from './FlowFactory';

export default {
  // eslint-disable-next-line react/react-in-jsx-scope
  nodeWrapRender(h, nodeConfig, processType, operateType) {
    const tep = [];
    if(nodeConfig !== null){
      tep.push(
          <div className={styles.nodeWrap} key={nodeConfig?.id}>
            <FlowNode nodeConfig={nodeConfig} ></FlowNode>
            <AddNodeBtn nodeConfig={nodeConfig} processType={processType}></AddNodeBtn>
          </div>,
      );
    }

    // 如果是跟节点
    if (nodeConfig?.isRoot) {
      return tep;
    }
    // 如果是子节点
    if (nodeConfig?.childNode) {
      const el = drawFlow.getFactory(h, nodeConfig?.childNode, processType, operateType);
      tep.push(el);
    }
    return tep;
  },
};
