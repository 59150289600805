/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-03-08 17:52:17
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-11 20:10:09
 * @FilePath: /isp_International/src/api/newApi/orderInfo.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable no-undef */
import http from '../axios';

// Init OrderInfo message
export function initOrderInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/trade/settlementPage', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

// Init Address list
export function initAddress(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/address/queyrfull', {
      pageSize: 10000,
      pageNo: 1,
      keyword: ''
    }, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

// Submit Order
export function submitOrder(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/trade/submit', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}


//运费模板计算
export function freightCalculate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/freight/calculate', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//根据条件获取审批流
export function getApprovalByCondition(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/trade/approval/list', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}


