/*
 * @Author: duchongchong1
 * @Date: 2023-03-23 17:35:58
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-09 14:43:29
 * @FilePath: /isp_pro/src/pages/Approval/Create/floor/title/index.tsx
 * @Description:  
 */
import React, { useEffect, useState } from 'react';
import { PAGE_TYPE } from '@/pages/Approval/Create/constants';
import BreadCrumb from '@/components/BreadCrumb';
import styles from '../../index.module.scss';
import { useTranslation } from 'react-i18next';
interface IProps {
  operateType?: number;
}
const FloorTitle: React.FC<IProps> = props => {
  const { operateType } = props;
  const { t } = useTranslation();
  const [title, setTitle] = useState(t('process.add.floorTitle.0'));

  useEffect(() => {
    switch (operateType) {
      case PAGE_TYPE.detailPage:
        setTitle(t('process.add.floorTitle.2'));
        break;
      case PAGE_TYPE.modifyPage:
        setTitle(t('process.add.floorTitle.1'));
        break;
      default:
        setTitle(t('process.add.floorTitle.0'));
        break;
    }
  }, [operateType]);
  const crumbData = [
    {
      label: t('process.add.crumbData.0'),
      url: '/userCenter',
    },
    {
      label: t('process.add.crumbData.1'),
      url: '/processList',
    },
    {
      label: title,
    },
  ];
  return (
    <>
      <BreadCrumb data={crumbData} />
      <div className={styles.title}>
        <div className={styles.left}>
          <span>{title}</span>
        </div>
      </div>
    </>
  );
};

export default FloorTitle;
