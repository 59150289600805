import { atom } from 'recoil';

import { totalInfo } from '../interface';

const totalInfos = atom<totalInfo | undefined>({
  key: 'totalInfos',
  default: undefined,
});

export default totalInfos;
