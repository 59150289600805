/*
 * @Author: your name
 * @Date: 2021-11-30 17:16:40
 * @LastEditTime: 2021-11-30 18:21:31
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/OtherCarts/hooks/useSetRecoilState.ts
 */
import { SetterOrUpdater, RecoilState, useSetRecoilState } from 'recoil';

import atoms from '../atoms';

type Atoms = typeof atoms;

const useRecoil = <K extends keyof Atoms, U = Atoms[K] extends RecoilState<infer V> ? V : never>(
  key: K,
): SetterOrUpdater<U> => {
  return useSetRecoilState<U>(atoms[key] as any);
};

export default useRecoil;
