/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2023-12-04 15:09:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-03-06 15:13:35
 * @FilePath: /isp_International/src/pages/OrderInfo/atoms/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import consignee from './consignee';
import consigneeList from './consigneeList';
import others from './others';
import shipment from './shipment';
import orderRemarks from './orderRemarks';
import transfee from './transfee';
import tradeType from './tradetype';
import shippingtype from './shippingtype';
import condition from './condition';
import conditionList from './conditionList';
import approval from './approval';
import approvalList from './approvalList';
import taxfee from './taxfee';

const atoms = {
  consignee,
  consigneeList,
  others,
  shipment,
  orderRemarks,
  transfee,
  tradeType,
  shippingtype,
  condition,
  conditionList,
  approval,
  approvalList,
  taxfee
};

export default atoms;
