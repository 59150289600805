import { atom } from 'recoil';

import Consignee from '../types/consignee';

const consigneeList = atom<Array<Consignee> | undefined>({
  key: 'consigneeList',
  default: undefined,
});

export default consigneeList;
