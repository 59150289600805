import { atom } from 'recoil';

import BigField from '../types/bigField';

const bigField = atom<BigField | undefined>({
  key: 'bigField',
  default: undefined,
});

export default bigField;
