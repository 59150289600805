import amount from './amount';
import compare from './compare';
import filter from './filter';
import main from './main';
import model from './model';
import others from './others';
import products from './products';
import service from './service';
import specifications from './specifications';
import bigField from './bigField';
import basicInfo from './basicInfo';
import productAttr from './productAttr';
import similarProduct from './similarProduct';
import productCategory from './productCategory';
import materialInfo from './materialInfo';

const atoms = {
  amount,
  compare,
  filter,
  main,
  model,
  others,
  products,
  service,
  specifications,
  bigField,
  basicInfo,
  productAttr,
  similarProduct,
  productCategory,
  materialInfo
};

export default atoms;
