import React from 'react';
// import { useSelector } from 'react-redux';
// import { SKU_IS_SPECIAL } from '@/enum/config';
import styles from '../../index.module.scss';
import img from '@/assets/images/skuDetail/caution.png';
import { useTranslation } from 'react-i18next';

// export interface IAppProps {
//   skuDetail: any;
//   applyToPoolState: any;
// }
// const Tips: React.FC<IAppProps> = (props: any) => {
const Tips = () => {
  const { t, i18n } = useTranslation();
  // const { skuDetail, applyToPoolState } = props;
  // const userInfo = useSelector(state => {
  //   return state?.User?.data[0];
  // });
  // const getTips = () => {
  //   try {
  //     let tips = '';
  //     if (Number(skuDetail?.skuFinalPrice?.price) > Number(skuDetail?.skuFinalPrice?.jdPrice) && userInfo?.hadJicai) {
  //       tips = `<p>商品信息需要联系对应客户经理确认后，方可完成下单采购。</p>`;
  //     } else if (Number(skuDetail?.baseInfo?.isSpecial) === 1000) {
  //       tips = `<p>该商品不在您的专属商品池中，无法进行采购！</p>`;
  //     } else if (SKU_IS_SPECIAL[Number(skuDetail?.baseInfo?.isSpecial)]) {
  //       tips = `<p>${SKU_IS_SPECIAL[Number(skuDetail?.baseInfo?.isSpecial)]}</p>`;
  //     }
  //     return tips;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  return (
    <div className={styles.tips}>
      <img width="12px" height="12px" src={img} />
      {/*<div dangerouslySetInnerHTML={{ __html: getTips() }} style={{ marginLeft: '4px' }}></div>*/}
      <div style={{ marginLeft: '4px', color: '#ED5502', fontSize: '12px' }}>
        {t('SkuDetailV2.notInPool')}
      </div>
    </div>
  );
};

export default Tips;
