import React, { useState } from 'react';
import { message } from 'antd';
import { createAddress } from '@/api/newApi/personalCenter/address';
import { useRecoilState } from '../../hooks';
import ADDRESS from '@/assets/images/orderInfo/address.png';
import styles from './index.module.scss';
import AddArea from '@/components/Address/Drawer';
import AddressSelector from './AddressSelector/index';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const [shipment] = useRecoilState('shipment'); // main message
  const [consigneeList, setConsigneeList] = useRecoilState('consigneeList');
  const [consignee, setConsignee] = useRecoilState('consignee');
  const [showAddressSelector, setShowAddressSelector] = useState(false); // 打开修改地址
  const [addressModelVisible, setAddressModelVisible] = useState(false); // 打开新增地址
  const { t } = useTranslation();
  const onSaveAddress = async (address: any, isDefault: any) => {
    const params = { ...address };
    params.defaultStatus = isDefault ? 1 : 0;

    const res = (await createAddress(params)) as any;

    if (res.success) {
      setConsignee({
        ...res?.value,
        id: res?.value?.id,
      });

      let newList;
      if (isDefault && consigneeList) {
        newList = [res?.value].concat(
          consigneeList.map(c => {
            return {
              ...c,
              defaultStatus: 0,
            };
          }),
        );
      } else {
        newList = [res?.value].concat(consigneeList);
      }

      setConsigneeList(newList);
      setAddressModelVisible(false);
    } else {
      message.error(res.msg);
    }
  };

  return (
    <div className={styles.m}>
      <div className={styles.mt}>
        <p>{t('OrderInfo.shippingContact')}</p>
      </div>

      <div style={{ display: 'flex' }}>
        {consignee ? (
          <div className={styles.mc}>
            <div
              className={styles.mc_background}
              style={{
                backgroundImage: `url(${ADDRESS})`,
              }}
            />
            <div className={styles.mc_box}>
              <div className={styles.mc_box_title}>
                <div className={styles.mc_box_title_receive}>{consignee?.receiveName}</div>
                {/*<div className={styles.mc_box_title_receive}>{consignee?.receiveName}(收)</div>*/}
                <div className={styles.mc_box_title_receive}>{consignee?.receivePhone}</div>
              </div>
              <div className={styles.mc_box_content} style={{ fontSize: '14px', color: 'rgba(0,3,20,0.65)' }} title={consignee?.detailAddress + consignee?.areaAddress}>
                {consignee?.sortRule !== 'childPriority' ? consignee?.areaAddress + ' ' + consignee?.detailAddress : consignee?.detailAddress + ' ' + consignee?.areaAddress}
              </div>
              <div
                onClick={() => {
                  setShowAddressSelector(true);
                  log('jdisp', 'Settlement_ChangeAddress');
                }}
                className={styles.mc_changeadd_text}
              >
                {t('OrderInfo.change')}
              </div>
              {consignee.defaultStatus == 1 && <div className={styles.mc_box_default}>{t('OrderInfo.default')}</div>}
            </div>
          </div>
        ) : null}
      </div>

      {!!shipment?.addressAuthority && <div
        className={styles.m_add}
        onClick={() => {
          setAddressModelVisible(true);
          log('jdisp', 'Settlement_NewAddress');
        }}
      >
        {t('OrderInfo.addShippingAddress')}
      </div>}

      {/* 更换地址 */}
      {consigneeList && consignee && (
        <AddressSelector
          showDrawer={showAddressSelector}
          consignee={consignee}
          consigneeList={consigneeList}
          setConsigneeList={setConsigneeList}
          setConsignee={setConsignee}
          // closeDrawer={item => {
          //   Object.keys(item).length > 0 && setConsignee(item);
          //   setShowAddressSelector(false);
          // }}
          closeDrawer={() => {
            setShowAddressSelector(false);
          }}
        />
      )}

      <AddArea
        address={{}}
        visible={addressModelVisible}
        onOk={(address: any) => {
          onSaveAddress(address, address.defaultStatus);
          log('jdisp', 'Settlement_save');
        }}
        onCancel={() => {
          setAddressModelVisible(false);
          log('jdisp', 'Settlement_cancel');
        }}
      />
    </div>
  );
};

export default Index;
