import { useRecoilState, SetterOrUpdater, RecoilState } from "recoil";

import atoms from "../atoms";

type Atoms = typeof atoms;

const useRecoil = <K extends keyof Atoms, U = Atoms[K] extends RecoilState<infer V> ? V : never>(
  key: K,
): [U, SetterOrUpdater<U>] => {
  return useRecoilState<U>(atoms[key] as any);
};

export default useRecoil;
