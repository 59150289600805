import { atom } from 'recoil';

import Specifications from '../types/specifications';

const specifications = atom<Specifications | undefined>({
  key: 'specifications',
  default: undefined,
});

export default specifications;
