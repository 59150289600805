/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-17 19:38:19
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-20 15:53:11
 * @FilePath: /isp_International/src/pages/MaterialCode/components/SearchForm/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss'
interface SearchFormProps {
    getDataList:any;
}
const SearchForm: React.FC<SearchFormProps> = (props) => {
    const {getDataList} = props;
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();

    const formItem1 = (
        <Form.Item key="formItem8" name="materialId" label={t('materialCode.materialCode')}>
        <Input placeholder={t('orderList.searchForm.label.please') + t('materialCode.materialCode')} />
        </Form.Item>
    );
    const formItem2 = (
        <Form.Item key="formItem8" name="mkuId" label={t('materialCode.jdProductCode')}>
        <Input placeholder={t('orderList.searchForm.label.please') + t('materialCode.jdProductCode')} />
        </Form.Item>
    );

   const formItems = [formItem1, formItem2]

   const onFinish = async(values:any)=>{
    getDataList && getDataList(values)
   }

    return <div className={styles.searchForm}>
      <Form form={form} colon={false} name="control-hooks" onFinish={onFinish} className={styles.searchForm}>
        <Space wrap size={0} style={{ width: '100%' }}>
          {formItems}
          <div style={{ textAlign: 'right' }}>
            <Button
                // clstag={isAudit ? 'h|keycount|ordercenter|4' : 'h|keycount|myorder|9'}
                type="primary"
                htmlType="submit"
                style={{ marginRight: '20px'}}
                onClick={() => {
                log('jdisp', 'OrderList_Search');
                }}
            >
                {t('orderList.searchForm.form_btn.search')}
            </Button>

            <Button
                // clstag={isAudit ? 'h|keycount|ordercenter|3' : 'h|keycount|myorder|8'}
                type="primary"
                ghost
                onClick={() => {
                form.resetFields();
                //   setSearchOrderTimeType(1);
                //   setSearchOrderTime([]);
                //   setSelectedBuyAccounts([]);
                //   onReset();
                }}
            >
                {t('orderList.searchForm.form_btn.reset')}
            </Button>
        </div>
        </Space>
        
      </Form>
    </div>
}

export default SearchForm