import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';
import Tooltip from '@/components/common/Tooltip';
import {
  dispatchApprovalDeleteModel,
  dispatchApprovalAccountModel,
  dispatchApprovalModifyNameModel, dispatchApprovalNodes,
} from '@/redux/action';
import { getUrlsQuery } from '@/util';
import { ACCOUNT_DRAWER_TYPE, PAGE_TYPE, PROCESS_TYPE } from '../../../constants/index';
import {getCurrentApprovalList} from "@/pages/Approval/Create/utils/oprateNode";
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  nodeConfig?: any;
  deleteCallBack?: (node: any) => void;
}
const FlowNode: React.FC<IProps> = (props: any) => {
  const stateApproval: any = useSelector((state: any) => {
    return state?.Approval;
  });

  const userInfo: any = useSelector((state: any) => {
    return state?.User?.data[0];
  });

  // 操作类型 1新增 2修改 3详情
  const operateType = getUrlsQuery('type') || 1;
  // console.log('操作类型 1新增 2修改 3详情', operateType);
  const { nodeName, content, nodeType, id, specifyApprover } = props.nodeConfig;
  const nodeTypeStyle = `nodeType${nodeType}`;
  const dispatch = useDispatch();

  const [isCanOperate, setIsCanOperate] = useState(true); // 页面操作类型

  const [isSecondNoConditionNode, setIsSecondNoConditionNode] = useState(false); // 无条件限制的 一级审批节点

  const { t } = useTranslation();

  // 是否显示指定操作
  const [isShowSpecify,setIsShowSpecify] = useState(true);

  const [isErrorNodeId, setIsErrorNodeId] = useState(false);

  const processType: any = useSelector((state: any) => {
    return state?.Approval?.processType;
  });

  const errorNodeId: any = useSelector((state: any) => {
    return state?.Approval?.errorNodeId;
  });

  const secondNoConditionId: any = useSelector((state: any) => {
    return state?.Approval?.secondNoConditionId;
  });

  useEffect(() => {
    if (errorNodeId !== '' && errorNodeId === id && content === '') {
      setIsErrorNodeId(true);
    } else {
      setIsErrorNodeId(false);
    }
  }, [errorNodeId, content]);

  useEffect(() => {
    if (operateType == PAGE_TYPE.detailPage) {
      setIsCanOperate(false);
    } else {
      setIsCanOperate(true);
    }
    // 无条件下且第一个元素不可以删除
    if (processType == PROCESS_TYPE.noCondition && id === secondNoConditionId) {
      setIsSecondNoConditionNode(true);
    } else {
      setIsSecondNoConditionNode(false);
    }
    // 审批人第一个节点不含有指定操作
    if(userInfo?.specifyApprover){
      if(id === secondNoConditionId){
        setIsShowSpecify(false);
      }else {
        if(operateType == PAGE_TYPE.detailPage && specifyApprover !== 1){
          setIsShowSpecify(false);
        } else {
          setIsShowSpecify(true);
        }
      }
    } else {
      setIsShowSpecify(false);
    }


  }, [operateType, processType, secondNoConditionId, userInfo]);
  // 删除节点
  const clickDeleteNode = (node: any) => {
    const params = {
      isShowDeleteModal: true,
      operateNode: node,
    };
    dispatch(dispatchApprovalDeleteModel(params));
  };
  // 增加节点
  const clickAddAccountNode = (node: any) => {
    const params = {
      accountDrawerType: ACCOUNT_DRAWER_TYPE.modifyAccountInfo,
      isShowAddAccountDrawer: true,
      operateNode: node,
    };
    dispatch(dispatchApprovalAccountModel(params));
  };
  // 修改名称
  const clickModifyName = (node: any) => {
    console.log('clickModifyName',node);
    const params = {
      isShowModifyNameModal: true,
      operateNode: node,
    };
    dispatch(dispatchApprovalModifyNameModel(params));
  };
  // 打开指定当前节点审批人
  const handleChangeSpecify = (checked: boolean, node: any) =>{
    // const operateNode = {...node, specifyApprover:checked?1:0 }
    // console.log('打开指定当前节点审批人---->', checked, operateNode);
    const tempNodeList = getCurrentApprovalList(stateApproval);
    tempNodeList.filter((tempNode: any) => {
      if (tempNode.id === node.id) {
        tempNode.specifyApprover = checked?1:0;
      }
    });
    // 更新节点
    dispatch(dispatchApprovalNodes({ nodeList: tempNodeList }));
  }
  return (
    <div className={`${styles.nodeWrapBox} ${styles[nodeTypeStyle]} ${isErrorNodeId ? styles.error : ''}`} id={id}>
      <div>
        {nodeType !== 1 && isCanOperate && !isSecondNoConditionNode && (
          <div
            className={styles.nodeDelete}
            onClick={event => {
              event.stopPropagation();
              clickDeleteNode(props.nodeConfig);
            }}
          ></div>
        )}
        <div className={`${nodeType !== 1 ? styles.arrows : ''}`}>
          <div className={`${styles.title}`}>
            <span className={styles.editTitle}>
              {nodeName}
              {isCanOperate && (
                  <i className={`iconFont icon-feedback`}
                     onClick={event => {
                          event.stopPropagation();
                          clickModifyName(props.nodeConfig);
                     }}>
                  </i>
              )}
            </span>
            {isCanOperate && (
              <a
                href='javascript:void(0)'
                className={styles.manageTitle}
                onClick={event => {
                  event.stopPropagation();
                  clickAddAccountNode(props.nodeConfig);
                }}
              >
                {t('process.add.manageCount')}
              </a>
            )}
          </div>
        </div>
        <div className={styles.content}>
          {content ? (
            <div className={`${styles.text} ${ (nodeType !== 1 && isShowSpecify  && content?.indexOf(',') > 1) ? '' : styles.empty_text}` }>{content}</div>
          ) : (
            <div className={`${styles.noText} ${content?.indexOf(',') <= 1 ? styles.empty_text :''}`}>{t('process.add.noAccount')}</div>
          )}
        </div>

        {
            // 操作类型为审批人  且不是第一个审批人  且审批人大于两个  才可以设置定向
            nodeType !== 1 && isShowSpecify  && content?.indexOf(',') > 1 && (
                <div className={styles.specify} >
                  <span>{`${isCanOperate?'': t("process.add.hasOpen")}${t("process.add.printApprovalPersonTip")}`}</span>
                  <Tooltip  style={{marginBottom: '-16px'}} placement="top" title={ t("process.add.openTip")}>
                    <i className={`iconFont icon-navbar_prompt`}></i>
                  </Tooltip>
                  {
                    isCanOperate && <Switch
                          size="small"
                          className={styles.switch}
                          defaultChecked={specifyApprover===1? true : false}
                          onChange={(checked, event)=>{handleChangeSpecify(checked,props.nodeConfig)}}></Switch>
                  }
                </div>
            )
        }
      </div>
    </div>
  );
};

export default FlowNode;
