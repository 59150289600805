/*
 * @Author: duchongchong1
 * @Date: 2022-11-10 20:19:05
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-21 09:56:15
 * @FilePath: /isp_International/src/pages/ApprovalList/components/Tab/index.tsx
 * @Description:
 */
import React, { useState, useEffect } from 'react';
import { Tabs, Badge, Alert, Row } from 'antd';
const { TabPane } = Tabs;
import styles from './index.module.scss';
import { OrderStatus } from '@/components/Order';
import { useTranslation } from 'react-i18next';

const Tab: React.FC<{
  isAudit: boolean;
  isDelete: boolean;
  status: OrderStatus;
  auditQueryType: string;
  showCompleteAudit: boolean;
  orderCount: any;
  // onOrderTabChange: (activeKey: OrderStatus) => void;
  onAuditTabChange: (activeKey: string) => void;
}> = ({
  isAudit,
  auditQueryType,
  showCompleteAudit,
  orderCount,
  // onOrderTabChange,
  onAuditTabChange,
}) => {
  const { t } = useTranslation();
  // const [isVspUser, setVspUser] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(auditQueryType);

  // const checkVspStatus = () => {
  //   getVspStatus({}).then((res: any) => {
  //     setVspUser(res?.value ?? false);
  //   });
  // };

  // useEffect(() => {
  //   checkVspStatus();
  // }, []);

  return (
    <Tabs
      defaultActiveKey={auditQueryType}
      activeKey={activeKey}
      style={{ padding: '0 20px', borderBottom: '1px solid #eee' }}
      onChange={activeKey => {
        setActiveKey(activeKey);
        onAuditTabChange(activeKey);
      }}
      className={styles.tab}
    >
      <TabPane
        tab={
          <div>
            {t('approvalList.status.0')}
            <Badge count={orderCount.waitingAudit}></Badge>
          </div>
        }
        key={'0'}
      />
      {showCompleteAudit && (
        <TabPane
          tab={
            <div>
              {t('approvalList.status.1')}
              <Badge count={0}></Badge>
            </div>
          }
          key={'1'}
        />
      )}
    </Tabs>
  );
};

export default Tab;
