/*
 * @Author: weiyanna
 * @Date: 2021-12-28 17:53:05
 * @LastEditTime: 2022-11-03 11:27:37
 * @LastEditors: duchongchong1
 * @Description: 采购清单 & 订单提交页 显示使用
 * @FilePath: /isp_International/src/components/ServiceStuff/index.tsx
 */
import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

interface Props {
  data?: Record<string, any>[];
}

const LIMIT = 2;

const Index = (props: Props) => {
  const [fold, setFold] = useState(LIMIT);
  const { t } = useTranslation();
  const { data } = props;

  if (!data) {
    return null;
  }
  const showToggle = data?.length > LIMIT;
  // type 1 附件 2 赠品 null 商品服务
  const renderItem = (record: any, index: any) => {
    return (
      <li key={index}>
        <span title={record?.skuName} className={styles.gt}>
          {Number(record?.skuType) === 1 ? t('common.appendix') : t('common.gift')} {record?.skuName}
        </span>
        <span className={styles.gn}> x{record.skuNum}</span>
      </li>
    );
  };
  return (
    <div className={styles.m}>
      <ul>{data?.slice(0, fold)?.map((item, index) => renderItem(item, index))}</ul>
      {showToggle && (
        <>
          {data?.length > fold ? (
            <button onClick={() => setFold(data.length)}>
              {t('common.expand')}
              <DownOutlined />
            </button>
          ) : (
            <button onClick={() => setFold(LIMIT)}>
              {t('common.collapse')}
              <UpOutlined />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Index;
