import { atom } from 'recoil';

import ProductCategory from '../types/productCategory';

const productCategory = atom<ProductCategory | undefined>({
  key: 'productCategory',
  default: undefined,
});

export default productCategory;
