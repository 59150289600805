import React, { useCallback } from 'react';
import { Modal, message, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useRecoilState, useSetRecoilState } from '../../hooks';
import { getImageDomain } from '@/pages/Carts/utils';
import { delProducts, getCartList } from '@/api/newApi/carts';

import styles from '@/pages/CartsNew/index.module.scss';

const Operate = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useRecoilState('current');
  const [skuList, setSkuList] = useRecoilState('skuList');
  const setSelected = useSetRecoilState('selected');
  const setSelectedSkuList = useSetRecoilState('selectedSkuList');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const setLoading = useSetRecoilState('loading');

  const refreshList = useCallback(() => {
    setLoading(true);
    getCartList().then(res => {
      if (res?.success) {
        setSkuList(res?.value?.cartWares);
        setTotalInfos({
          totalCheckCount: res?.value?.totalCheckCount || 0,
          totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
          totalCount: res?.value?.totalCount || 0,
          totalKindCount: res?.value?.totalKindCount || 0,
          totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
          totalPriceCurrencies:res?.value?.totalPriceCurrencies
        });
        setSelectedSkuList(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item));
        setSelected(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item.sku));
        setLoading(false);
      }
    });
  }, []);

  const handleOk = useCallback(() => {
    const skuIds = current?.list?.map(item => item.sku);
    delProducts(skuIds).then(res => {
      if (res?.success) {
        message.success(t('carts.deletedSuccessfully'));
        refreshList();
      } else {
        message.error(t('carts.deletedFailed'));
      }
    });
    setCurrent(undefined);
  }, [current]);

  const handleCancel = () => {
    setCurrent(undefined);
  };

  return (
    <Modal
      title={
        <div className={styles.deleteModal}>
          <InfoCircleOutlined style={{ color: 'orange' }} />
          <span style={{ marginLeft: '12px' }}>
            {current?.list?.length > 1
              ? t('carts.removeMultipleTips').replace('{T}', current?.list?.length)
              : t('carts.removeSingleTips')}
          </span>
        </div>
      }
      onCancel={handleCancel}
      open={current && current?.list?.length > 0}
      footer={
        <div>
          <Button
            type="primary"
            key="ok"
            style={{ fontSize: '14px', minWidth: '60px', height: '32px' }}
            onClick={() => {
              handleOk && handleOk();
            }}
          >
            {t('carts.confirm')}
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              handleCancel && handleCancel();
            }}
            style={{ fontSize: '14px', minWidth: '60px', height: '32px', marginRight: '10px' }}
          >
            {t('carts.cancel')}
          </Button>
        </div>
      }
    >
      <div className={`${styles.modalDelete}`}>
        {current?.list?.map((item, index) => {
          return (
            <div className={styles.product} key={index}>
              <img src={item.skuImg} alt={item.skuName} />
              <div className={styles.detail}>
                <div className={styles.title}>{item.skuName}</div>
                <div className={styles.code}>{t('OrderInfo.productId')}{item.sku}</div>
                <div className={styles.unit}>
                  {t('carts.unit')}
                  {item?.unit || '-'}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default Operate;
