/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-07-01 22:35:06
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-06 09:29:06
 * @FilePath: /isp_International/src/api/newApi/order.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from '../axios';

/**
 * 审批列表
 * @param params
 */
/**
 * 待审批列表数据查询
 * @param params
 */
export function getAuditOrderList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/waitApprovalList', params, false, true).then(
      res => {
        console.log(res, 'waitApprovalList');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getOrderList(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/orderList', params, false, true).then(
      res => {
        // console.log(res, 'getOrderList');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
export function getOrderCount() {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/order/getOrderStatus', {}, false, true).then(
      res => {
        // console.log(res, 'getOrderCount');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getOrderStatusList() {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/order/getOrderStatusList', {}, false, true).then(
      res => {
        // console.log(res, 'getOrderCount');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function cancelOrder(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/updateOrderStatus', params, false, true).then(
      res => {
        console.log(res, 'cancelOrder');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
//确认收货
export function confirmOrder(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/confirmReceipt', params, false, true).then(
      res => {
        console.log(res, 'confirmOrder');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//确认订单 服务费
export function orderConfirm(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/updateOrderStatus', params, false, true).then(
      res => {
        console.log(res, 'confirmOrder');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function batchConfirmOrder(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/batchUpdateOrderStatus', params, false, true).then(
      res => {
        console.log(res, 'batchConfirmOrder');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function printOrder(params: object) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/order/OrderPrint', params, false, true).then(
      res => {
        console.log(res, 'printOrder');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function exportOrderRequest(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/down/order/export', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function buyAgainAddProducts(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/cart/addProducts', params, false, true).then(
      res => {
        console.log(res, 'buyAgain');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//获取订单导出配置
export function getOrderSettingMap(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/order/configuration', params, false, true).then(
      res => {
        console.log(res, 'waitApprovalList');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//订单打印
export function orderPrintApi(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/down/order/print', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//查询物流轨迹
export function queryDeliveryApi(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/queryDelivery', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//按到货时间展示
export function querySortByDateApi(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/sortByDate', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//按到货时间展示
export function saveConfiguration(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/configuration/save', params, true, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}



