/*
 * @Author: your name
 * @Date: 2021-11-30 17:16:40
 * @LastEditTime: 2021-11-30 18:17:07
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/OtherCarts/components/Add/index.tsx
 */
import React from 'react';

import { useRecoilState } from '../../hooks';
import { getClstag } from '../../utils';

import Create from '../Create';

import styles from './index.module.scss';

const Add = () => {
  const [add, setAdd] = useRecoilState('add');

  return (
    <div className={styles.m}>
      <button className={styles.add} onClick={() => setAdd(true)} clstag={getClstag(5)}>
        新建采购清单
      </button>
      {add && <Create />}
    </div>
  );
};

export default Add;
