/*
 * @Author: your name
 * @Date: 2021-11-01 13:44:29
 * @LastEditTime: 2024-01-09 16:35:59
 * @LastEditTime: 2022-11-10 19:25:21
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_International/src/components/NavBar/sliter/sliter.tsx
 */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cookies from 'react-cookies';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';

export interface IAppProps {
  childData: any;
  oneId: any;
  onClose: () => void;
  location?: any;
}

const Sliter: React.FC<IAppProps> = props => {
  const { childData, oneId, location, onClose } = props;
  const [isLocalStation] = useState(cookies.load('stationType'));// 是否本地化站点
  const red_goods_data: any = useSelector((state: any) => {
    return state.SearchList;
  });
  const { selectParams } = red_goods_data;
  const dispatch = useDispatch();
  const history = useHistory();
  const openWin = (key: any, cataIdList: any, name: string) => {
    onClose();
    const path = {
      pathname: '/page/list',
      cat: key,
      cataIdList: cataIdList,
      query: name,
    };
    history.replace(path);
  };
  return (
    <div className={`${styled.sub_conent}`}>
      {childData.length > 0
        ? childData.map((twoData: any, index: any) => {
            return (
              <div
                className={`${styled.slider_item}`}
                key={twoData.catId}
              >
                <div className={`${styled.left}`}>
                  <a
                    title={twoData.catName}
                    onClick={() => {
                      openWin(twoData.catId, oneId + ',' + twoData.catId, twoData.catName);
                    }}
                  >
                    {twoData.catName}
                  </a>
                  <i className={`${styled.left_icon}`}></i>
                </div>
                {
                // isLocalStation == '0' ? (twoData.categoryDTOList.length > 0 &&
                //     <div className={styled.threecate}>{twoData.categoryDTOList.map((threeData: any) => {
                //       return (
                //         <div className={styled.cleard} key={threeData.catId}>
                //           <div className={`${styled.threecateleft}`} key={index}>
                //               <a
                //                 title={threeData.catName}
                //                 onClick={() => {
                //                   openWin(threeData.catId, oneId + ',' + twoData.catId + ',' + threeData.catId, threeData.catName);
                //                 }}
                //               >
                //                 {threeData.catName}
                //               </a>
                //               <i className={`${styled.left_icon}`}></i>
                //           </div>
                //           <div className={`${styled.right}`}>
                //               {threeData.categoryDTOList.length > 0 &&
                //                 threeData.categoryDTOList.map((data: any) => {
                //                   return (
                //                     <a
                //                       key={data.catId}
                //                       onClick={() => {
                //                         openWin(data.catId, oneId + ',' +twoData.catId + ',' + threeData.catId + ',' + data.catId, data.catName);
                //                       }}
                //                     >
                //                       {data.catName}
                //                     </a>
                //                   );
                //                 })}
                //           </div>
                //         </div>
                //        )
                //     })}</div>)
                //      : 
                     <div className={`${styled.right}`}>
                  {twoData.categoryDTOList.length > 0 &&
                    twoData.categoryDTOList.map((data: any) => {
                      return (
                        <a
                          key={data.catId}
                          onClick={() => {
                            openWin(data.catId, oneId + ',' + twoData.catId + ',' + data.catId, data.catName);
                          }}
                        >
                          {data.catName}
                        </a>
                      );
                    })}
                </div>
                }
              </div>
            );
          })
        : ''}
    </div>
  );
};
export default Sliter;
