import { atom } from 'recoil';

import { Filter } from '../interface';

const filter = atom<Filter | undefined>({
  key: 'filterOtherCarts',
  default: undefined,
});

export default filter;
