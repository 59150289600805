/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-03-26 15:45:22
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-09 16:47:05
 * @FilePath: /isp_International/src/pages/CartsNew/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useRef, useState } from 'react';
import { RecoilRoot } from 'recoil';
import { Affix } from 'antd';
import { useRecoilState, useSetRecoilState } from './hooks';

import { getCartList, getPersonInfo } from '@/api/newApi/carts';

import Goods from './components/Goods/index';
import PlaceOrder from './components/PlaceOrder/index';
import Empty from './components/Empty/index';
import Operate from './components/Operate/index';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';

const Index = () => {
  const cartNewRef = useRef();
  const [bottom, setBottom] = useState(0);
  const [skuList, setSkuList] = useRecoilState('skuList');
  const setSelected = useSetRecoilState('selected');
  const setSelectedSkuList = useSetRecoilState('selectedSkuList');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const setIsCanPlaceOrder = useSetRecoilState('isCanPlaceOrder');
  const [loading, setLoading] = useRecoilState('loading');
  // 列表加载状态
  const [pageLoad, setPageLoad] = useState(false);

  const store: any = useSelector(state => {
    return state;
  });

  useEffect(() => {
    setLoading(true);
    setPageLoad(true);
    getCartList().then(res => {
      if (res?.success && res?.value?.cartWares.length > 0) {
        setSkuList(res?.value?.cartWares);
        setTotalInfos({
          totalCheckCount: res?.value?.totalCheckCount || 0,
          totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
          totalCount: res?.value?.totalCount || 0,
          totalKindCount: res?.value?.totalKindCount || 0,
          totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
          totalPriceCurrencies:res?.value?.totalPriceCurrencies
        });
        setSelectedSkuList(res?.value?.cartWares?.filter((item: any) => item.checked && item?.saleState?.saleState === 1).map((item: any) => item));
        setSelected(res?.value?.cartWares?.filter((item: any) => item.checked && item?.saleState?.saleState === 1 ).map((item: any) => item.sku));
        setPageLoad(false);
        setLoading(false);
      } else {
        setPageLoad(false);
      }
    });

    if (store?.User?.data.length > 0) {
      // 只有角色5 采购员 可以下单
      const userType = store?.User?.data[0]?.userType ?? '';
      if (userType === 3 || userType === 5) {
        setIsCanPlaceOrder(true);
      } else {
        setIsCanPlaceOrder(false);
      }
    } else {
      getPersonInfo().then(res => {
        if (res?.value?.userType === 3 ||res?.value?.userType === 5) {
          setIsCanPlaceOrder(true);
        } else {
          setIsCanPlaceOrder(false);
        }
      });
    }
  }, []);
  return (
    <>
      <div className={styles.cartNew} ref={cartNewRef}>
        {!pageLoad && skuList?.length > 0 && (
          <React.Fragment>
            <Goods></Goods>
            <Affix offsetBottom={bottom}>
              <PlaceOrder></PlaceOrder>
            </Affix>
          </React.Fragment>
        )}
        {!pageLoad && skuList?.length === 0 && <Empty></Empty>}
      </div>
      <Operate></Operate>
    </>
  );
};
const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Index></Index>
    </RecoilRoot>
  );
};

export default App;
