import React, { useEffect, useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from '../../index.module.scss';
import { useRecoilState } from '@/pages/CartsNew/hooks';
import selectedSkuList from '@/pages/CartsNew/atoms/selectedSkuList';
import LazyLoad from 'react-lazyload';
import { getImageDomain } from '@/pages/Carts/utils';

const SlideProducts = (props: any) => {
  const [selectedSkuList, setSelectedSkuList] = useRecoilState('selectedSkuList');

  const productsRef = useRef<HTMLDivElement | null>(null);
  const [isShowPre, setShowPre] = React.useState(false);
  const [isShowNext, setShowNext] = React.useState(false);
  const [productsLen, setProductsLen] = React.useState(0);
  const [preNum, setPreNum] = React.useState(1);
  const [nextNum, setNextNum] = React.useState(1);
  const [showSlide, setShowSlide] = useRecoilState('showSlide');
  useEffect(() => {
    if (selectedSkuList?.length > 11) {
      setShowPre(true);
      setShowNext(true);
      const num = Math.ceil(selectedSkuList?.length / 11);
      setProductsLen(num);
    }
  }, [selectedSkuList]);

  const onHandlePre = () => {
    if (preNum >= 1 && preNum <= productsLen) {
      setPreNum(preNum - 1);
      setNextNum(nextNum + 1);
      productsRef.current?.scrollTo({ left: -880 * nextNum, behavior: 'smooth' });
    }
  };

  const onHandleNext = () => {
    if (preNum < productsLen) {
      setPreNum(preNum + 1);
      setNextNum(nextNum - 1);
      productsRef.current?.scrollTo({ left: 880 * preNum, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    document.addEventListener('click', el => {
      if (showSlide) {
        setShowSlide(false);
      }
    });
  }, []);

  return (
    <div className={`${styles.slideProducts} ${selectedSkuList?.length > 11 ? styles.flexWidth : styles.minWidth}`}>
      {// 前移动
      isShowPre && preNum !== 1 && (
        <div className={styles.pre} onClick={onHandlePre}>
          <LeftOutlined className={styles.outlined} />
        </div>
      )}

      <div className={styles.products} ref={productsRef}>
        {selectedSkuList?.map((item, index) => {
          return (
            <div className={styles.product} key={index}>
              <LazyLoad height={72}>
                <img src={item.skuImg} alt={item.skuName} />
              </LazyLoad>
              <span>
                {item.skuNum}
                {/* {item.unit} */}
              </span>
            </div>
          );
        })}
      </div>

      {// 后移动
      isShowNext && preNum !== productsLen && (
        <div className={styles.next} onClick={onHandleNext}>
          <RightOutlined className={styles.outlined} />
        </div>
      )}
    </div>
  );
};

export default SlideProducts;
