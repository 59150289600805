/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-13 16:28:16
 * @LastEditors: duchongchong1
 * @LastEditTime: 2022-11-18 15:41:02
 * @FilePath: /isp_International/src/pages/SelectList/components/Main/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import Empty from '@/components/common/Empty';
import styled from './index.module.scss';
import { getQueryVariable } from '@/util';
import GoodsList from '../GoodsList';
import { Space, Spin } from 'antd';

export interface IAppProps {
  changeGoodData: any;
  isEmpty: boolean;
  addCarBe: Function;
  loading?: boolean;
}

const Main: React.FC<IAppProps> = (props: any) => {
  const { changeGoodData, isEmpty, loading, addCarBe } = props;

  return (
    <div className={`${styled.larger_img}`}>
      {changeGoodData.length > 0 && (
        <>
          <GoodsList changeGoodData={changeGoodData} addCarBe={addCarBe} />
        </>
      )}
      {loading && (
        <div className={`${styled.loading}`}>
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      )}
      {!loading && isEmpty && <Empty content="暂无相关信息" type="noResult" />}
    </div>
  );
};
export default Main;
