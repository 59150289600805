import React, { useState, useEffect } from 'react';
import { Button, message, Row } from 'antd';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { OrderStatus, orderStatus, pay, confirm, canCancel, buyAgain as addCart } from '@/components/Order';
import { duration, getLogSkus } from '@/util';
import { UserType } from '../../components/SearchForm';
import Icon from '@/components/common/Icon';
import iconTime from '@/assets/images/orderList/icon_time.svg';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const OrderActions: React.FC<{
  order: any;
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  auditQueryType: string;
  onApplyAftersale: () => void;
  onCancel: () => void;
  refreshList: () => void;
  onApprove: () => void;
  onReject: () => void;
  onRestore: () => void;
  onCountDown: () => void;
  onReceiptCountDown?: () => void;
  isConfirm: boolean;
  orderConfirm: (orderNo: any) => void;
}> = ({
  order,
  isAudit,
  isDelete,
  isVsp,
  auditQueryType,
  onApplyAftersale,
  onCancel,
  refreshList,
  onApprove,
  onReject,
  onRestore,
  onCountDown,
  onReceiptCountDown,
  isConfirm,
  orderConfirm,
}) => {
  const { t } = useTranslation();
  const [countDown, setCountDown] = useState<number>(order.cancelTime || 0);
  const [receiptCountDown, setReceiptCountDown] = useState<number>(0);
  const status = orderStatus(order);
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;

  const userPin: string = currentUser.pin;

  const [isNewOnceMorBuy, setIsNewOnceMorBuy] = useState<boolean>(currentUser?.isNewOnceMorBuy ?? false);

  const history = useHistory();
  const isFrom = 0; //1 慧采 2 订单回收 3 订单审批 0 订单列表

  const buyAgain = () => {
    const skus = order.waresReqs.map((item: any) => {
      return {
        sku: item.sku,
        num: item.skuNum,
        price: item.skuPrice,
      };
    });
    // console.log(skus);
    addCart(skus, history);
  };
  const viewDetailButton = (order: any) => (
    <Link
      clstag="h|keycount|myorder|18"
      to={{
        pathname: '/order/detail',
        search: `?id=${order.orderNo}&orderPin=${isVsp ? userPin : order.pin}&isVsp=${+isVsp}&isFrom=${isFrom}`, //订单详情
      }}
      className={styles.link}
      style={{ color: 'rgba(0, 0, 0, 0.85)', marginBottom: '4px' }}
      onClick={() => {
        (window as any).log('isp_orderlist', 'detail', userPin, order.orderNo);
        log('jdisp', 'OrderList_Detail');
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('orderList.table_op.3')}
    </Link>
  );
  const buyOrderActions = () => {
    const isMyOrder = order.pin === userPin;
    const status = orderStatus(order);
    const buyAccount = [
      UserType.buyAccount,
      UserType.parentAccount,
      UserType.childAccount,
      UserType.parallelAccount,
    ].includes(userType);

    const showReceipt = status === OrderStatus.complete && order.receipt;
    const canDowloadReceipt = showReceipt && !order?.receipt?.date && !!order?.receipt?.url;
    return (
      <>
        {
          [90, 80].includes(order.orderStatus) &&
          order.deliveryStatus !== 1 &&
          (
          <Button
            type="link"
            className={[styles.blue, styles.link].join(' ')}
            style={{ marginBottom: '8px', marginTop: '0' }}
            onClick={() => {
              confirm(order.orderNo, refreshList, t);
            }}
          >
            {t('orderList.table_op.2')}
          </Button>
        )}
        {order.orderStatus == 2 && (
          <Button
            type="link"
            clstag="h|keycount|myorder|17"
            onClick={() => {
              orderConfirm(order.orderNo);
              log('jdisp', 'OrderList_Confirm');
            }}
            className={[styles.blue, styles.link].join(' ')}
            style={{ marginBottom: '8px' }}
          >
            {t('orderList.table_op.1')}
          </Button>
        )}
        {order.orderStatus <= 2 && (
          <Button
            clstag="h|keycount|myorder|17"
            type="text"
            onClick={() => {
              (window as any).log('isp_orderlist', 'cancel_order', userPin, order.orderNo);
              onCancel();
              log('jdisp', 'OrderList_Cancel');
            }}
            className={styles.link}
            style={{ marginBottom: '8px' }}
          >
            {t('orderList.table_op.4')}
          </Button>
        )}

        {viewDetailButton(order)}
        
        {(order.orderStatus < 2 || order.orderStatus >= 4) && (
          <Button
            clstag="h|keycount|myorder|19"
            type="text"
            onClick={() => {
              buyAgain();
              log('jdisp', 'OrderList_Reorder');
            }}
            className={styles.link}
            style={{ marginBottom: '8px' }}
          >
            {t('orderList.table_op.5')}
          </Button>
        )}
      </>
    );
  };

  return buyOrderActions();
};

export default OrderActions;
