import { atom } from 'recoil';

import BasicInfo from '../types/basicInfo';

const basicInfo = atom<BasicInfo | undefined>({
  key: 'basicInfo',
  default: undefined,
});

export default basicInfo;
