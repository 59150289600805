/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-17 11:21:09
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-17 17:39:22
 * @FilePath: /isp_International/src/api/newApi/taskCenter.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from '../axios';


//任务中心列表
export function getTaskList(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/task/center/page', params, false, true).then(
        res => {
          console.log(res, 'getTaskList');
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

//任务导入
export function taskImport(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/task/center/import', params, false, true).then(
        res => {
          console.log(res, 'getTaskList');
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}



