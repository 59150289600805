/* eslint-disable prefer-rest-params */
import { combineReducers } from 'redux';
// 更新数据
const INITIAL_STATE = {
  // params: {
  //   pageNo: 1, // 当前页
  //   pageSize: 60, // 每页的数量
  // },
  page: 1, // 当前页
  pageSize: 50, // 每页的数量
  toolNum: 0,
  data: [],
  brandList: [],
  priceRangeList: [],
  isShowJDPrice: true,
  userCatList: [],
  extAttrList: [],
  searchParam: {},
  loading: true,
  pageInfo: {},
  view: 1,
  checkedCount: 0,
  allMkuIds: [], //skuId
  canMcSearch: 0,
  expandQuery: {},
  selectParams: {},
  // 搜索内容
  queryValue: '',
};

const updateGoodsData = (state: any, action: any) => {
  return {
    ...state,
    pageInfo: action.pageInfo,
    data: action.data,
    brandList: action.brandList,
    isShowJDPrice: action.isShowJDPrice,
    priceRangeList: action.priceRangeList,
    userCatList: action.userCatList,
    // objNumericalFieldCollection: action.objNumericalFieldCollection,
    extAttrList: action.extAttrList, //判断是否走缓存
    searchParam: action.searchParam,
    toolNum: action.toolNum,
    params: action.params,
    page: action.page,
    selectParams: action.selectParams,
    pageSize: action.pageSize,
    loading: false,
    allMkuIds: action.allMkuIds,
    canMcSearch: action.canMcSearch,
    searchExtAttrList: action.extAttrList, // 电线电缆专用扩展属性
    expandQuery: action.expandQuery, // QP搜索词
  };
};
const updateAllChecked = (state: any, action: any) => {
  const arr = state.data.map((item: any) => {
    return {
      ...item,
      checked: action.isChecked,
    };
  });
  return {
    ...state,
    data: arr,
  };
};
const updateOneChecked = (state: any, action: any) => {
  const newGoodsList = [...state.data];
  newGoodsList.forEach(data => {
    if (data.mwareId == action.id) {
      data.checked = action.isChecked;
    }
  });
  return {
    ...state,
    data: newGoodsList,
  };
};
const updateOneNum = (state: any, action: any) => {
  const newGoodsList = [...state.data];
  newGoodsList.forEach(data => {
    if (data.mwareId == action.id) {
      data.mkuNum = action.num;
    }
  });
  return {
    ...state,
    data: newGoodsList,
  };
};
const updateView = (state: any, action: any) => {
  return {
    ...state,
    view: action.viewType,
  };
};
const updateCheckCount = (state: any, action: any) => {
  return {
    ...state,
    checkedCount: action.count,
  };
};
const updateAllCheckCount = (state: any, action: any) => {
  const newGoodsList = [...state.data];
  if (action.contrastList.length > 0) {
    action.contrastList.forEach((data: any) => {
      newGoodsList.forEach(item => {
        if (data.skuId == item.mwareId) {
          item.compareCheck = true;
        }
      });
    });
  } else {
    newGoodsList.forEach(item => {
      item.compareCheck = false;
    });
  }

  return {
    ...state,
    data: newGoodsList,
  };
};
const updateCheckContrast = (state: any, action: any) => {
  const newGoodsList = [...state.data];
  newGoodsList.forEach(data => {
    if (data.mwareId == action.id) {
      data.compareCheck = action.isChecked;
    }
  });
  return {
    ...state,
    data: newGoodsList,
  };
};
// 更新搜索内容
const updateQueryName = (state: typeof INITIAL_STATE, action: { queryName: string }) => {
  window.localStorage.setItem('iv', action.queryName);
  return {
    ...state,
    queryName: action.queryName,
  };
};
function getGoodsData(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case 'UPDATE_GOODSLIST':
      return updateGoodsData(state, action);
    case 'UPDATE_ALL':
      return updateAllChecked(state, action);
    case 'UPDATE_ONE':
      return updateOneChecked(state, action);
    case 'UPDATE_NUM':
      return updateOneNum(state, action);
    case 'UPDATE_VIEW':
      return updateView(state, action);
    case 'UPDATE_CHECKCOUNT':
      return updateCheckCount(state, action);
    case 'UPDATE_ALLCHECKCONTRAST':
      return updateAllCheckCount(state, action);
    case 'UPDATE_CHECKCONTRAST':
      return updateCheckContrast(state, action);
    case 'UPDATE_QUERY_NAME': // 搜索内容
      return updateQueryName(state, action);
    default:
      return state;
  }
}
const reducer = combineReducers({
  getGoodsData,
});

export default getGoodsData;
