const INIT_APPROVAL = {
    processId: 0,
    processKey: '',
    processName: '', // 审批名称
    processType: 1, // 审批类型
    applyPin: '', // 提交人
    contractNumber: '',
    source: 1,
    approvalNodeList: [], // 节点列表
    purchasePins: [], // 采购账号
    examinePins: [], // 审查账号
    created: null,
    modified: null,
    haveConditionNodeList: [
      {
        id: '1',
        groupId: null,
        pids: null,
        groupPid: null,
        nodeType: 1,
        nodeName: '采购账号',
        nodeRemark: null,
        content: '',
        approve: 'None',
        isRow: 1,
        isRoot: 1,
        priority: 1,
        conditionList: null,
      },
      {
        id: '2',
        groupId: '1',
        pids: '1',
        groupPid: '1',
        nodeType: 2,
        nodeName: '一级审批',
        nodeRemark: null,
        content: '',
        approve: null,
        isRow: 1,
        isRoot: 0,
        priority: 1,
        conditionList: null,
      },
    ], // 有条件审批
    noConditionNodeList: [
      {
        id: '3',
        groupId: null,
        pids: null,
        groupPid: null,
        nodeType: 1,
        nodeName: '采购账号',
        nodeRemark: null,
        content: '',
        approve: 'None',
        isRow: 1,
        isRoot: 1,
        priority: 1,
        conditionList: null,
      },
      {
        id: '4',
        groupId: '3',
        pids: '3',
        groupPid: '3',
        nodeType: 2,
        nodeName: '一级审批',
        nodeRemark: null,
        content: '',
        approve: null,
        isRow: 1,
        isRoot: 0,
        priority: 1,
        conditionList: null,
      },
    ], // 无条件审批
  
    operateNode: {}, // 操作节点 包括：删除节点元素、增加审批节点元素、增加条件节点元素
    isShowModifyNameModal: false, // 是否显示修改名称弹框
  
    isShowDeleteModal: false, // 是否展示删除弹层
  
    isShowAddAccountDrawer: false, // 是否展示添加人员弹层
    accountDrawerType: 1,
  
    isShowManageConditionDrawer: false, // 是否显示审批条件
    conditionDrawerType: 3, // modifyCondition 添加条件， 修改条件 addNode 添加条件节点
    errorNodeId: '', // 问题点的nodeID
  
    secondNoConditionId: '',
  };
  
  const updateApproval = (state: any, action: any) => {
    if (action?.data?.processType === 1) {
      return {
        ...state,
        ...action.data,
        noConditionNodeList: action?.data?.approvalNodeList,
      };
    } else {
      return {
        ...state,
        ...action.data,
        haveConditionNodeList: action?.data?.approvalNodeList,
      };
    }
  };
  /**
   * 修改参数
   * @param state
   * @param action
   */
  const updateApprovalType = (state: any, action: any) => {
    return {
      ...state,
      processType: action.data?.processType,
    };
  };
  /**
   *
   * @param state
   * @param action
   */
  const updateApprovalName = (state: any, action: any) => {
    return {
      ...state,
      processName: action.data?.processName,
    };
  };
  
  const updateExaminePins = (state: any, action: any) => {
    return {
      ...state,
      examinePins: action.data?.examinePins,
    };
  };
  const updateNodes = (state: any, action: any) => {
    console.log(state.processType);
    // 无条件限制
    if (state.processType === 1) {
      return {
        ...state,
        noConditionNodeList: action.data?.nodeList,
      };
    } else {
      // 有条件限制
      return {
        ...state,
        haveConditionNodeList: action.data?.nodeList,
      };
    }
  };
  
  // 修改是否显示删除弹框
  const updateIsShowDeleteModal = (state: any, action: any) => {
    return {
      ...state,
      isShowDeleteModal: action.data?.isShowDeleteModal,
      operateNode: action.data?.operateNode,
    };
  };
  // 修改是否展示提示修改名称弹框
  const updateIsShowModifyNameModal = (state: any, action: any) => {
    return {
      ...state,
      isShowModifyNameModal: action.data?.isShowModifyNameModal,
      operateNode: action.data?.operateNode,
    };
  };
  // 修改是否显示增加人员抽屉
  const updateIsShowAddAccountDrawer = (state: any, action: any) => {
    return {
      ...state,
      accountDrawerType: action.data?.accountDrawerType,
      isShowAddAccountDrawer: action.data?.isShowAddAccountDrawer,
      operateNode: action.data?.operateNode,
    };
  };
  // 修改是否显示增加审批条件抽屉
  const updateShowConditionDrawer = (state: any, action: any) => {
    return {
      ...state,
      conditionDrawerType: action.data?.conditionDrawerType,
      isShowManageConditionDrawer: action.data?.isShowManageConditionDrawer,
      operateNode: action.data?.operateNode,
    };
  };
  // 修改是否显示增加审批条件抽屉
  const updateErrorNodeId = (state: any, action: any) => {
    console.log('修改是否显示增加审批条件抽屉', action);
    return {
      ...state,
      errorNodeId: action.data?.errorNodeId,
    };
  };
  /**
   * 修改参数
   * @param state
   * @param action
   */
  const updateSecondNoConditionId = (state: any, action: any) => {
    return {
      ...state,
      secondNoConditionId: action.data,
    };
  };

  
  export default function approval(state = INIT_APPROVAL, action: any) {
    switch (action.type) {
      case 'UPDATE_APPROVAL_DATA':
        return updateApproval(state, action);
      case 'UPDATE_SHOW_DELETE_MODAL':
        return updateIsShowDeleteModal(state, action);
      case 'UPDATE_SHOW_MODIFY_NAME_MODAL':
        return updateIsShowModifyNameModal(state, action);
      case 'UPDATE_SHOW_ADD_ACCOUNT_DRAWER':
        return updateIsShowAddAccountDrawer(state, action);
      case 'UPDATE_SHOW_CONDITION_DRAWER':
        return updateShowConditionDrawer(state, action);
      case 'UPDATE_APPROVAL_TYPE':
        return updateApprovalType(state, action);
      case 'UPDATE_APPROVAL_NAME':
        return updateApprovalName(state, action);
      case 'UPDATE_APPROVAL_EXAMINE_PINS':
        return updateExaminePins(state, action);
      case 'UPDATE_APPROVAL_NODES':
        return updateNodes(state, action);
      case 'UPDATE_ERROR_NODE_ID':
        return updateErrorNodeId(state, action);
      case 'UPDATE_SECOND_NO_CONDITION_ID':
        return updateSecondNoConditionId(state, action);
      default:
        return state;
    }
  }
  