import React, { useState } from 'react';
import InputNumber from 'rc-input-number';
import { useDispatch } from 'react-redux';
import { dispatchOneChecked, dispatchOneNum } from '@/redux/action';
import InputNumberPanel from '@/components/InputNumberPanel';
import { Button, Select, Input ,Modal, message, Col } from 'antd';
import DefaultKongYe from '@/assets/images/searchList/kongye.png';
import styles from './index.module.scss';
import { replenishment } from '@/api/newApi/orderdetail';
import { useTranslation } from 'react-i18next';


export interface IAppProps {
  shopInfo: any;
  setIsVisible:()=>void;
}
const Replenishment: React.FC<IAppProps> = (props: any) => {
    const { isVisible, shopInfo, setIsVisible } = props;
    const [email, setEmail] = useState(''); //邮箱
    const[deliveryDate, setDeliveryDate] = useState('');//发货时间
    const[num, setNum] = useState(1);//数量
    const { t } = useTranslation();

    const deliveryOptions = [{value: '1', label: t('component.Replenishment.asap')},{value: '2', label:  t('component.Replenishment.within_a_week')},{value: '3', label:  t('component.Replenishment.within_a_month')},{value: '4', label:  t('component.Replenishment.within_a_quarter')}]

    const clearInfo = ()=>{
        setEmail('');
        setDeliveryDate('');
        setNum('');
    }
    return <div >
       <Modal 
        width={700} 
        title={<div>{t('component.Replenishment.request_restock')}<span className={styles.subtit}>{t('component.Replenishment.current_item_out_of_stock')}</span></div>} 
        open={isVisible} 
        className={styles.index}
        okText={t('component.Replenishment.applyImmediately')}
        cancelText={t('component.Replenishment.cancel')}
        onOk={
            async()=>{
                if(!num){
                    message.error(t('component.Replenishment.enter_requested_quantity'));
                    return;
                }
                if(!deliveryDate){
                    message.error(t('component.Replenishment.select_date'));
                    return;
                }
                if(!email){
                    message.error(t('component.Replenishment.enter_email'));
                    return;
                }
                const resData = await replenishment({
                    mkuId:shopInfo?.mkuId,
                    applyCnt:num,
                    expectedDeliveryDate:deliveryDate,
                    email
                })
                
                if(resData?.success){
                    clearInfo();
                    setIsVisible(false)
                    message.success(resData?.msg);
                }else{
                    message.error(resData?.msg);
                }
                }
            }
            onCancel={()=>{
                clearInfo();
                setIsVisible(false)
            }}
       >
       <div className={styles.product}>
          <div className={styles.pic}>
            <img src={shopInfo?.img || ''} alt="" />
          </div>
          <div className={styles.con}>
            <div className={styles.name}>{shopInfo?.productName}</div>
           {/* <div className={styles.model}>
                <span>产品型号</span>
                <span></span>
           </div> */}
           <div className={styles.name}>
                <span>{t('component.Replenishment.productId')}</span>
                <span style={{
                    marginLeft:10,
                }}>{shopInfo?.mkuId || ''}</span>
           </div>
          </div>
       </div>

       <div className={styles.line}>
            <span className={styles.label}>{t('component.Replenishment.requested_quantity')}</span> 
            <div className='form-item'>
                <InputNumberPanel min={1} max={999999999} value={num} changeCallBack={(params)=>{
                   setNum(params)
                }}></InputNumberPanel>
            </div>
       </div>
       <div className={styles.line}>
            <span className={styles.label}>{t('component.Replenishment.expected_shipping_date')}</span> 
            <div className='form-item'>
                <Select options={deliveryOptions} className={styles.deliveryDate} value={deliveryDate} onChange={(val)=>{
                    setDeliveryDate(val)
                }}/>
            </div>
       </div>
       <div className={styles.line}>
            <span className={styles.label}> {t('component.Replenishment.contact_email')}</span>
            <div className='form-item'>
                <Input value={email} onChange={(e)=>{
                    setEmail(e.target.value)
                }}></Input>
            </div>
       </div>       
       </Modal>
      
    </div>
}

export default Replenishment;