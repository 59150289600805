import { flattenPublicPromise } from '@/util/index';
import { getSearchList, getSearchGoodList, getSearchCombined } from '@/api/newApi/search';
import cookies from 'react-cookies';
import { shopCar } from '@/api/newApi/home';
import { message } from 'antd';
const isLocal = cookies.load('stationType') == '0';//是否本土跨境商品
export const dispatchAll = (checket: boolean) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_ALL', isChecked: checket });
  };
};

//用户信息
export const dispatchUser = (data: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_USER_DATA', data });
  };
};
//地址信息
export const dispatchLocation = (data: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_LOCATION_DATA', data });
  };
};
//客服地址
export const dispatchCustomer = (url: string) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_CUSTOMER', url });
  };
};
//请求采购清单数量
export const dispatchShopCar = () => {
  return async (dispatch: Function, getState: any) => {
    const shopCarNum: any = await shopCar();
    if (shopCarNum.success) {
      dispatch({
        type: 'UPDATE_SHOP_CAR',
        num: shopCarNum.value ? shopCarNum.value : 0,
      });
    }
  };
};

//不用请求接口，改变采购清单数量
export const dispatchShopCarNum = (num: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_SHOP_CAR', num: num });
  };
};
//用户菜单
export const dispatchInitMenus = (data: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_USER_INIT_MENUS', data });
  };
};
// 用户权限map
export const dispatchPublicPromise = (data: any) => {
  const value = data?.value;
  const fpp = flattenPublicPromise(value);

  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_USER_PUBLIC_PROMISE', fpp });
  };
};
// 当前登录用户使用权限类型
export const dispatchPromiseWhite = (data: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_USER_PROMISE_WHITE', data });
  };
};
// search list
export const dispatchAnnounceData = (params: any, isFirst: boolean, selectParams?: any) => {
  return async (dispatch: Function, getState: any) => {
    // 商品列表（chatGPT翻译搜列比较慢，所以产品建议搜列筛选框和商品拆成两个接口）
    // 获取商品
    const res: any = await getSearchGoodList({
      ...params,
    });
    // // 获取筛选项
    // const res_1: any = await getSearchCombined({
    //   ...params,
    // });
    
    // const result: any = await getSearchList({
    //   ...params,
    // });

    if (res?.success) {
      dispatch({
        type: 'UPDATE_GOODSLIST',
        pageInfo: res.value && res.value.page,
        data: res.value ? (res.value.wares == null ? [] : res.value.wares) : [],
        brandList: [],
        priceRangeList:  [],
        extAttrList: [],
        // toolNum: isLocal ? (res.value.page.maxResultCount || 0) : (res_1.value && res_1.value.page ? res_1.value.page.maxResultCount : 0),
        toolNum:res?.value?.page?.maxResultCount || 0,
        page: params.pageNo ? params.pageNo : 1,
        pageSize: params.pageSize ? params.pageSize : 50,
        params,
        selectParams: selectParams,
        isCache: !isFirst,
      });
    } else if(!res?.success){
       message.error(res?.message);
    }
  };
};
export const dispatchOneChecked = (checket: boolean, id: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_ONE', isChecked: checket, id: id });
  };
};

export const dispatchOneNum = (num: any, id: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_NUM', num: num, id: id });
  };
};
export const dispatchViewType = (viewType: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_VIEW', viewType });
  };
};
export const dispatchCheckCount = (count: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_CHECKCOUNT', count });
  };
};
export const dispatchAllCheckContrast = (contrastList: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_ALLCHECKCONTRAST', contrastList });
  };
};
export const dispatchCheckContrast = (checked: any, id: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_CHECKCONTRAST', isChecked: checked, id: id });
  };
};
//商详，选中服务
export const dispatchCheckService = (checkedService: any) => {
  return async (dispatch: Function, getState: any) => {
    dispatch({ type: 'UPDATE_CHECKEDSERVICE', checkedService: checkedService });
  };
};

// 搜索内容
export const setSearchContent = (text: string) => {
  return async (dispatch: Function) => {
    text && dispatch({ type: 'UPDATE_QUERY_NAME', queryName: text });
  };
};

/***********审批流相关**********/

export const dispatchApprovalData = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_APPROVAL_DATA', data });
  };
};

export const dispatchApprovalDeleteModel = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_SHOW_DELETE_MODAL', data });
  };
};
export const dispatchApprovalModifyNameModel = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_SHOW_MODIFY_NAME_MODAL', data });
  };
};
//
export const dispatchApprovalAccountModel = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_SHOW_ADD_ACCOUNT_DRAWER', data });
  };
};

export const dispatchApprovalConditionDrawer = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_SHOW_CONDITION_DRAWER', data });
  };
};

export const dispatchApprovalType = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_APPROVAL_TYPE', data });
  };
};

export const dispatchApprovalName = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_APPROVAL_NAME', data });
  };
};

// 修改审查员
export const dispatchApprovalExaminePins = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_APPROVAL_EXAMINE_PINS', data });
  };
};

// 修改节点
export const dispatchApprovalNodes = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_APPROVAL_NODES', data });
  };
};
// 修改错误ID
export const dispatchApprovalErrorNodeId = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_ERROR_NODE_ID', data });
  };
};

// 修改无条件第二个节点ID
export const dispatchSecondNoConditionId = (data: any) => {
  // 定义一个异步的高阶函数，用于分发action
  return async (dispatch: Function) => {
    // 调用dispatch函数，分发一个带有type和data的action对象
    dispatch({ type: 'UPDATE_SECOND_NO_CONDITION_ID', data });
  };
};

// 更新客户权限
export const dispatchUserLimit = (data: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_USER_LIMIT', data });
  };
};
