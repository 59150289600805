import React from 'react';
import { generateFilter } from 'colorize-filter';

const Icon: React.FC<{
  icon: string;
  width?: string;
  height?: string;
  color?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ icon, width = '16px', height = '16px', color, style, ...otherProps }) => {
  const margeStyle: any = { ...style, width, height };
  if (color) {
    margeStyle.filter = generateFilter(color);
  }
  return <img src={icon} style={margeStyle} {...otherProps} />;
};

export default Icon;
