
/*
 * @Author: duchongchong1
 * @Date: 2022-11-10 19:57:59
 * @LastEditors: duchongchong1
 * @LastEditTime: 2023-08-28 11:46:12
 * @FilePath: /isp_International/src/api/newApi/approvalList.ts
 * @Description:
 */
import http from '../axios';

/**
 * 待审批列表数据查询
 * @param params
 */
export function getAuditOrderList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/waitApprovalList', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 审批删除文件
export function delApprovalFile(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/delApprovalFile', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// 审批列表批量驳回和批量通过
export function getApprovalAction(params: object) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/approvalAction', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
/**
 * 查看审批文件
 */
export function viewFile(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/viewFile', params, false, true).then(
      res => {
        // console.log(res, 'waitApprovalList');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function downLoadApprovalFile(params: any = {}) {
  console.log('downLoadApprovalFile', params);
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/downLoadApprovalFile', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getFlow(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/orderApproval/flow', params, false, true).then(
      res => {
        // console.log(res, 'waitApprovalList');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getOrderList(params: object) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/order/orderList', params, false, true).then(
      res => {
        // console.log(res, 'orderList');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
// orderApproval/initApprovalQuery
// orderApproval/waitApprovalList   待审批列表数据查询
