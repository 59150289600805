import React, { useState } from 'react';
import { Button, Checkbox, message } from 'antd';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import styles from './index.module.scss';
import iconCopy from '@/assets/images/orderList/icon_copy.svg';
import { ReactComponent as IconPrint } from '@/assets/images/orderList/icon_print1.svg';
import { ReactComponent as IconDelete2 } from '@/assets/images/orderList/icon_delete2.svg';
import iconInfo from '@/assets/images/orderList/icon_info.svg';
import icon_tips from '@/assets/images/orderList/icon_tips.png';
import Joy from '@/assets/images/joy.svg';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { OrderStatus, orderStatus, print, orderTime } from '@/components/Order';

import moment from 'moment';

import { useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UserType } from '../../components/SearchForm';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '@/util';

const OrderTitle: React.FC<{
  order: any;
  type?: 'parent' | 'child';
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  auditQueryType: string;
  checked: boolean;
  onCheckOrder: (e: CheckboxChangeEvent, order: any) => void;
  onRemoveOrder: React.MouseEventHandler<any>;
  isConfirm: boolean;
  parentIndeterminate:boolean;
  orderConfirm: () => void;
  printHandler: (orderNo:string) => void;
}> = ({
  order,
  type,
  isAudit,
  isDelete,
  isVsp,
  checked,
  auditQueryType,
  onCheckOrder,
  onRemoveOrder,
  isConfirm,
  orderConfirm,
  parentIndeterminate,
  printHandler
}) => {
  const { t } = useTranslation();

  const status = orderStatus(order);
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;

  const userPin: string = currentUser.pin;

  return (
    <>
    <div
      className={styles.borderTop}
      style={{
        backgroundColor: type === 'child' ? '#fff' : 'rgba(0,3,20,0.03)',
        padding: '9px 18px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(0,3,20,0.45)' }}>
        <div style={{ width: '14px' }}>
        {type !== 'child' ?
          (<Checkbox clstag="h|keycount|myorder|15" checked={checked} indeterminate={parentIndeterminate}  onChange={e => onCheckOrder(e, order)}/>):
          (<Checkbox clstag="h|keycount|myorder|15" checked={checked}  onChange={e => onCheckOrder(e, order)}/>)
          }
        </div>
        <div style={{ marginLeft: '15px' }}>{moment(orderTime(order)).format('YYYY-MM-DD HH:mm:ss')}</div>
        <div style={{ marginLeft: '20px' }}>
          {t('orderList.searchForm.label.orderNo')}：{order.orderNo}
        </div>

        {type !== 'child' && <div style={{ marginLeft: '20px' }}>{t('orderList.orderTotalPrice')}: {formatMoney(order?.totalPrice, false)}</div>}
        <CopyToClipboard
          text={order.orderNo} //注意 parent为true时候没改
          onCopy={() => {
            message.success(t('orderList.tips.copyed'));
          }}
        >
          <span style={{ marginLeft: '10px', cursor: 'pointer', color: '#1938ED' }}>{t('orderList.table_op.0')}</span>
        </CopyToClipboard>
      </div>
      {type !== 'parent' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconPrint
            className={styles.link}
            style={{
              marginLeft: '10px',
              cursor: 'pointer',
              width: '16px',
              height: '16px',
              color: '#999',
              fill: 'currentcolor',
            }}
            onClick={() => {
              printHandler(order.orderNo)
            }}
          />
        </div>
      )}
    </div>

     {/*父级头部，拆单子提醒 */}
     {type === 'parent' && (
      <div className={styles.reminder}>
        <img src={icon_tips} alt="" onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = Joy;
        }}/>
        <span className={styles.order_number}>{ t('orderList.orderStatus')}：{ t('orderList.tips.hasSplitOrder').replace('XX', order?.childOrderNum) }</span>
        <span className={styles.split_tip}>
        
          {t('orderList.tips.splitOrderTip')}
        </span>
      </div>
    )}
   </>
  );
};

export default OrderTitle;
