import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Checkbox, Modal } from 'antd';
import styles from './index.module.scss';
// import { getUserList } from '@/api/processList';
import { getUserList } from '@/api/newApi/personalCenter/processList';
import SimplePagination from '@/components/common/SimplePagination';
import Icon from '@/components/common/Icon';
import iconSearch from '@/assets/images/orderList/icon_search.svg';
import Empty from '@/components/common/Empty';
import { useTranslation } from 'react-i18next';

const SelectAccountModal: React.FC<{
  visible: boolean;
  pins: string[];
  onOK: (pins: string[]) => void;
  onClose: () => void;
}> = ({ visible, pins, onOK, onClose }) => {
  const { t } = useTranslation();
  const [buyAccountsPage, setBuyAccountsPage] = useState<number>(1);
  const [buyAccountsPageSize] = useState<number>(20);
  const [buyAccountsKeyword, setBuyAccountsKeyword] = useState('');
  const [position, setPosition] = useState('');

  const [buyAccounts, setBuyAccounts] = useState<Array<string>>([]);
  const [buyAccountsTotal, setBuyAccountsTotal] = useState<number>(0);
  const [buyAccountsTotalPage, setBuyAccountsTotalPage] = useState<number>(0);
  const [buyAccountsRefresh, setBuyAccountsRefresh] = useState(false);
  const [selectedBuyAccounts, setSelectedBuyAccounts] = useState<Array<string>>(pins);

  const getBuyAccounts = () => {
    getUserList({
      accountType: 2,
      pageindex: buyAccountsPage,
      pageSize: buyAccountsPageSize,
      accountName: buyAccountsKeyword,
      position,
    }).then((res: any) => {
      const data = res?.value ?? {};
      setBuyAccounts(data?.items ?? []);
      setBuyAccountsTotal(data?.total ?? 0);
      setBuyAccountsTotalPage(data?.pageTotal ?? 0);
    });
  };

  const prevPage = () => {
    if (buyAccountsPage > 1) {
      setBuyAccountsPage(buyAccountsPage - 1);
      setBuyAccountsRefresh(true);
    }
  };
  const nextPage = () => {
    if (buyAccountsPage < buyAccountsTotalPage) {
      setBuyAccountsPage(buyAccountsPage + 1);
      setBuyAccountsRefresh(true);
    }
  };

  useEffect(() => {
    getBuyAccounts();
  }, []);

  useEffect(() => {
    if (buyAccountsRefresh) {
      getBuyAccounts();
      setBuyAccountsRefresh(false);
    }
  }, [buyAccountsRefresh]);

  const searchBuyAccount = () => {
    // setSelectedBuyAccounts([]);
    setBuyAccountsPage(1);
    setBuyAccountsRefresh(true);
  };

  return (
    <Modal
      destroyOnClose={true}
      width={620}
      title={t('process.detail.card_info3.table_op.0')}
      open={visible}
      footer={[
        <Row
          key="buyAccountModalFooter"
          style={{ alignItems: 'center', justifyContent: 'space-between', margin: '10px 0 20px' }}
        >
          <Row align="middle">
            <span style={{ fontSize: '12px', color: '#999' }}>
              {t('process.detail.card_info3.table_cell3.selected_pre')}{' '}
              <span className={[styles.bold, styles.blue].join(' ')}>{selectedBuyAccounts.length}</span>{' '}
              {t('process.detail.card_info3.table_cell3.selected_suf')}
            </span>
            <SimplePagination
              page={buyAccountsPage}
              total={buyAccountsTotal}
              totalPage={buyAccountsTotalPage}
              onPrev={prevPage}
              onNext={nextPage}
            />
          </Row>
          <Button
            type="primary"
            key="cancelOrder"
            onClick={() => {
              onOK(selectedBuyAccounts);
            }}
            style={{ fontSize: '14px', width: 'auto', height: '32px' }}
          >
            {t('process.detail.card_info3.table_cell3.btn.0')}
          </Button>
        </Row>,
      ]}
      onCancel={onClose}
      className={styles.buyAccountModal}
    >
      <Row align="middle" style={{ paddingBottom: '20px', borderBottom: '1px solid #eee' }}>
        <Input
          allowClear
          placeholder={t('process.detail.card_info3.table_cell3.placeholder.0')}
          prefix={<Icon icon={iconSearch} />}
          style={{ width: '200px', height: '32px', overflow: 'hidden', padding: '0 11px' }}
          onChange={e => setBuyAccountsKeyword(e.target.value)}
        />
        <Input
          allowClear
          placeholder={t('process.detail.card_info3.table_cell3.placeholder.1')}
          prefix={
            <span
              style={{
                fontSize: '12px',
                color: '#999',
                borderRight: '1px solid #e1e1e1',
                paddingRight: '8px',
                marginRight: '5px',
                lineHeight: '12px',
                marginTop: '2px',
              }}
            >
              {t('process.detail.card_info3.table_cell3.label.0')}
            </span>
          }
          style={{ width: '200px', height: '32px', overflow: 'hidden', padding: '0 11px', marginLeft: '30px' }}
          onChange={e => setPosition(e.target.value)}
        />
        <Button type="primary" style={{ marginLeft: '50px' }} onClick={searchBuyAccount}>
          {t('process.detail.card_info3.table_cell3.btn.1')}
        </Button>
      </Row>
      {buyAccounts.length > 0 ? (
        <div style={{ maxHeight: '335px', overflowY: 'auto' }}>
          {buyAccounts.map((o: any, index: number) => (
            <Row key={index} align="middle" justify="space-between" style={{ marginTop: '20px', width: '560px' }}>
              <Col span={9}>
                <Checkbox
                  checked={selectedBuyAccounts.includes(o.pin)}
                  onChange={e => {
                    if (e.target.checked) {
                      if (selectedBuyAccounts.length < 1000) {
                        setSelectedBuyAccounts([...selectedBuyAccounts, o.pin]);
                      }
                    } else {
                      setSelectedBuyAccounts(selectedBuyAccounts.filter(a => a !== o.pin));
                    }
                  }}
                >
                  {o.pin}
                </Checkbox>
              </Col>
              <Col span={8}>{o.bindingEmail}</Col>
              <Col span={3}>{o.position}</Col>
              <Col span={3}>
                <span className={[styles.state, o.lockStatus === 1 ? styles.s : styles.e].join(' ')}>
                  {o.lockStatus === 1
                    ? t('process.detail.card_info3.table_cell3.status.0')
                    : t('process.detail.card_info3.table_cell3.status.1')}
                </span>
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <Empty height="190xp" />
      )}
    </Modal>
  );
};

export default SelectAccountModal;
